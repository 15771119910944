/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConfigurazioniUtenteDTO,
  Problem,
} from '../models/index';
import {
    ConfigurazioniUtenteDTOFromJSON,
    ConfigurazioniUtenteDTOToJSON,
    ProblemFromJSON,
    ProblemToJSON,
} from '../models/index';

export interface ConfigurazioniByUserRequest {
    idUtente: string;
}

export interface CreatedConfigRequest {
    configurazioniUtenteDTO: ConfigurazioniUtenteDTO;
}

/**
 * 
 */
export class ConfigurazioniRestApi extends runtime.BaseAPI {

    /**
     * E\' necessario l\'id dell\'utente
     * cerca le configurazioni di un utente
     */
    async configurazioniByUserRaw(requestParameters: ConfigurazioniByUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfigurazioniUtenteDTO>> {
        if (requestParameters['idUtente'] == null) {
            throw new runtime.RequiredError(
                'idUtente',
                'Required parameter "idUtente" was null or undefined when calling configurazioniByUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/configurazioni/{idUtente}`.replace(`{${"idUtente"}}`, encodeURIComponent(String(requestParameters['idUtente']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigurazioniUtenteDTOFromJSON(jsonValue));
    }

    /**
     * E\' necessario l\'id dell\'utente
     * cerca le configurazioni di un utente
     */
    async configurazioniByUser(requestParameters: ConfigurazioniByUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfigurazioniUtenteDTO> {
        const response = await this.configurazioniByUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ha bisogno di un body valido.
     * crea un nuovo obiettivo e lo assegna ad ogni utente iscritto
     */
    async createdConfigRaw(requestParameters: CreatedConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfigurazioniUtenteDTO>> {
        if (requestParameters['configurazioniUtenteDTO'] == null) {
            throw new runtime.RequiredError(
                'configurazioniUtenteDTO',
                'Required parameter "configurazioniUtenteDTO" was null or undefined when calling createdConfig().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/configurazioni`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigurazioniUtenteDTOToJSON(requestParameters['configurazioniUtenteDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigurazioniUtenteDTOFromJSON(jsonValue));
    }

    /**
     * Ha bisogno di un body valido.
     * crea un nuovo obiettivo e lo assegna ad ogni utente iscritto
     */
    async createdConfig(requestParameters: CreatedConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfigurazioniUtenteDTO> {
        const response = await this.createdConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
