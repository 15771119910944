/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ObiettivoDTO,
  Pageable,
  Problem,
  ProgressoObiettivoDTO,
} from '../models/index';
import {
    ObiettivoDTOFromJSON,
    ObiettivoDTOToJSON,
    PageableFromJSON,
    PageableToJSON,
    ProblemFromJSON,
    ProblemToJSON,
    ProgressoObiettivoDTOFromJSON,
    ProgressoObiettivoDTOToJSON,
} from '../models/index';

export interface CreatedObiettivoRequest {
    obiettivoDTO: ObiettivoDTO;
}

export interface FindByIdUserRequest {
    id: string;
}

export interface GetByIdObiettivoUtenteRequest {
    id: string;
}

export interface GetObiettiviRequest {
    pageable: Pageable;
}

export interface GetProgressoObiettiviInCorsoRequest {
    id: string;
}

/**
 * 
 */
export class ObiettivoRestApi extends runtime.BaseAPI {

    /**
     * Ha bisogno di un body valido.
     * crea un nuovo obiettivo e lo assegna ad ogni utente iscritto
     */
    async createdObiettivoRaw(requestParameters: CreatedObiettivoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ObiettivoDTO>> {
        if (requestParameters['obiettivoDTO'] == null) {
            throw new runtime.RequiredError(
                'obiettivoDTO',
                'Required parameter "obiettivoDTO" was null or undefined when calling createdObiettivo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/obiettivi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ObiettivoDTOToJSON(requestParameters['obiettivoDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObiettivoDTOFromJSON(jsonValue));
    }

    /**
     * Ha bisogno di un body valido.
     * crea un nuovo obiettivo e lo assegna ad ogni utente iscritto
     */
    async createdObiettivo(requestParameters: CreatedObiettivoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ObiettivoDTO> {
        const response = await this.createdObiettivoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di una carta sul db
     */
    async findByIdUserRaw(requestParameters: FindByIdUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgressoObiettivoDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findByIdUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/obiettivi-utente/all-obiettivi/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgressoObiettivoDTOFromJSON(jsonValue));
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di una carta sul db
     */
    async findByIdUser(requestParameters: FindByIdUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgressoObiettivoDTO> {
        const response = await this.findByIdUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ricerca degli obiettivi attivi swell\'utente
     */
    async getByIdObiettivoUtenteRaw(requestParameters: GetByIdObiettivoUtenteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgressoObiettivoDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getByIdObiettivoUtente().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/obiettivi-utente/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgressoObiettivoDTOFromJSON(jsonValue));
    }

    /**
     * Ricerca degli obiettivi attivi swell\'utente
     */
    async getByIdObiettivoUtente(requestParameters: GetByIdObiettivoUtenteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgressoObiettivoDTO> {
        const response = await this.getByIdObiettivoUtenteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * non ha bisogno di input
     * cerca tutti gli obiettivi paginati
     */
    async getObiettiviRaw(requestParameters: GetObiettiviRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ObiettivoDTO>> {
        if (requestParameters['pageable'] == null) {
            throw new runtime.RequiredError(
                'pageable',
                'Required parameter "pageable" was null or undefined when calling getObiettivi().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/obiettivi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObiettivoDTOFromJSON(jsonValue));
    }

    /**
     * non ha bisogno di input
     * cerca tutti gli obiettivi paginati
     */
    async getObiettivi(requestParameters: GetObiettiviRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ObiettivoDTO> {
        const response = await this.getObiettiviRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * non ha bisogno di input
     * cerca tutti gli obiettivi successivi
     */
    async getObiettiviSuccessiviRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ObiettivoDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/obiettivi/successivi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObiettivoDTOFromJSON(jsonValue));
    }

    /**
     * non ha bisogno di input
     * cerca tutti gli obiettivi successivi
     */
    async getObiettiviSuccessivi(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ObiettivoDTO> {
        const response = await this.getObiettiviSuccessiviRaw(initOverrides);
        return await response.value();
    }

    /**
     * non ha bisogno di input
     * cerca tutti gli obiettivi che sono attivi e hanno il flag badge sbloccato su true
     */
    async getObiettiviWithBadgeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ObiettivoDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/obiettivi/badge-sbloccato`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObiettivoDTOFromJSON(jsonValue));
    }

    /**
     * non ha bisogno di input
     * cerca tutti gli obiettivi che sono attivi e hanno il flag badge sbloccato su true
     */
    async getObiettiviWithBadge(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ObiettivoDTO> {
        const response = await this.getObiettiviWithBadgeRaw(initOverrides);
        return await response.value();
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di una carta sul db
     */
    async getProgressoObiettiviInCorsoRaw(requestParameters: GetProgressoObiettiviInCorsoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgressoObiettivoDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getProgressoObiettiviInCorso().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/obiettivi-utente/progress/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgressoObiettivoDTOFromJSON(jsonValue));
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di una carta sul db
     */
    async getProgressoObiettiviInCorso(requestParameters: GetProgressoObiettiviInCorsoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgressoObiettivoDTO> {
        const response = await this.getProgressoObiettiviInCorsoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
