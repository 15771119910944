/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UUIDFilter } from './UUIDFilter';
import {
    UUIDFilterFromJSON,
    UUIDFilterFromJSONTyped,
    UUIDFilterToJSON,
} from './UUIDFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';

/**
 * 
 * @export
 * @interface UtenteCriteria
 */
export interface UtenteCriteria {
    /**
     * 
     * @type {UUIDFilter}
     * @memberof UtenteCriteria
     */
    id?: UUIDFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof UtenteCriteria
     */
    username?: StringFilter;
}

/**
 * Check if a given object implements the UtenteCriteria interface.
 */
export function instanceOfUtenteCriteria(value: object): value is UtenteCriteria {
    return true;
}

export function UtenteCriteriaFromJSON(json: any): UtenteCriteria {
    return UtenteCriteriaFromJSONTyped(json, false);
}

export function UtenteCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UtenteCriteria {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : UUIDFilterFromJSON(json['id']),
        'username': json['username'] == null ? undefined : StringFilterFromJSON(json['username']),
    };
}

export function UtenteCriteriaToJSON(value?: UtenteCriteria | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': UUIDFilterToJSON(value['id']),
        'username': StringFilterToJSON(value['username']),
    };
}

