/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ObiettivoDTO } from './ObiettivoDTO';
import {
    ObiettivoDTOFromJSON,
    ObiettivoDTOFromJSONTyped,
    ObiettivoDTOToJSON,
} from './ObiettivoDTO';
import type { Badge } from './Badge';
import {
    BadgeFromJSON,
    BadgeFromJSONTyped,
    BadgeToJSON,
} from './Badge';

/**
 * 
 * @export
 * @interface ProgressoObiettivoDTO
 */
export interface ProgressoObiettivoDTO {
    /**
     * 
     * @type {string}
     * @memberof ProgressoObiettivoDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgressoObiettivoDTO
     */
    utenteId?: string;
    /**
     * 
     * @type {ObiettivoDTO}
     * @memberof ProgressoObiettivoDTO
     */
    obiettivo?: ObiettivoDTO;
    /**
     * 
     * @type {number}
     * @memberof ProgressoObiettivoDTO
     */
    progresso?: number;
    /**
     * 
     * @type {Date}
     * @memberof ProgressoObiettivoDTO
     */
    dataCompletamento?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ProgressoObiettivoDTO
     */
    completato?: boolean;
    /**
     * 
     * @type {Badge}
     * @memberof ProgressoObiettivoDTO
     */
    badgeOttenuto?: Badge;
    /**
     * 
     * @type {Date}
     * @memberof ProgressoObiettivoDTO
     */
    ultimaModifica?: Date;
}

/**
 * Check if a given object implements the ProgressoObiettivoDTO interface.
 */
export function instanceOfProgressoObiettivoDTO(value: object): value is ProgressoObiettivoDTO {
    return true;
}

export function ProgressoObiettivoDTOFromJSON(json: any): ProgressoObiettivoDTO {
    return ProgressoObiettivoDTOFromJSONTyped(json, false);
}

export function ProgressoObiettivoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgressoObiettivoDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'utenteId': json['utenteId'] == null ? undefined : json['utenteId'],
        'obiettivo': json['obiettivo'] == null ? undefined : ObiettivoDTOFromJSON(json['obiettivo']),
        'progresso': json['progresso'] == null ? undefined : json['progresso'],
        'dataCompletamento': json['dataCompletamento'] == null ? undefined : (new Date(json['dataCompletamento'])),
        'completato': json['completato'] == null ? undefined : json['completato'],
        'badgeOttenuto': json['badgeOttenuto'] == null ? undefined : BadgeFromJSON(json['badgeOttenuto']),
        'ultimaModifica': json['ultimaModifica'] == null ? undefined : (new Date(json['ultimaModifica'])),
    };
}

export function ProgressoObiettivoDTOToJSON(value?: ProgressoObiettivoDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'utenteId': value['utenteId'],
        'obiettivo': ObiettivoDTOToJSON(value['obiettivo']),
        'progresso': value['progresso'],
        'dataCompletamento': value['dataCompletamento'] == null ? undefined : ((value['dataCompletamento']).toISOString()),
        'completato': value['completato'],
        'badgeOttenuto': BadgeToJSON(value['badgeOttenuto']),
        'ultimaModifica': value['ultimaModifica'] == null ? undefined : ((value['ultimaModifica']).toISOString()),
    };
}

