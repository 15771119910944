import React from 'react';
import styles from './UserCollection.module.css';
import { Link } from "react-router-dom";

interface CardComponentProps {
    id: string;
    nomeCollezione: string;
    tipoCollection: string;
    nomeUtente: string;
    imgCopertina?: string | null; // Accetta anche imgCopertina
}

const UserCollection: React.FC<CardComponentProps> = ({
                                                          id,
                                                          nomeCollezione,
                                                          tipoCollection,
                                                          nomeUtente,
                                                          imgCopertina
                                                      }) => {
    const backgroundImageStyle = imgCopertina
        ?  {
            backgroundImage: `url('data:image/jpeg;base64,${imgCopertina}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'left',
            filter: 'brightness(0.9)',
        }
        : {
            backgroundColor: '#fff',
        };

    return (
        <Link to={`/collections-user-detail/${id}`} className={styles.cardLink}>
            <div className={styles.card} style={backgroundImageStyle}>
                <div className={styles.cardOverlay}>
                    <h3 className={styles.cardTitle}>{nomeCollezione}</h3>
                    <h4 className={styles.cardSubtitle}>
                        <em>
                            <div className={styles.chip}>By {nomeUtente}</div>
                        </em>
                    </h4>
                    <div className={styles.cardType}>{tipoCollection}</div>
                </div>
            </div>
        </Link>
    );
};

export default UserCollection;
