/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Obiettivo } from './Obiettivo';
import {
    ObiettivoFromJSON,
    ObiettivoFromJSONTyped,
    ObiettivoToJSON,
} from './Obiettivo';

/**
 * 
 * @export
 * @interface Badge
 */
export interface Badge {
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    id?: string;
    /**
     * 
     * @type {Obiettivo}
     * @memberof Badge
     */
    obiettivo?: Obiettivo;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    nomeBadge?: string;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    descrizione?: string;
}

/**
 * Check if a given object implements the Badge interface.
 */
export function instanceOfBadge(value: object): value is Badge {
    return true;
}

export function BadgeFromJSON(json: any): Badge {
    return BadgeFromJSONTyped(json, false);
}

export function BadgeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Badge {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'obiettivo': json['obiettivo'] == null ? undefined : ObiettivoFromJSON(json['obiettivo']),
        'nomeBadge': json['nomeBadge'] == null ? undefined : json['nomeBadge'],
        'descrizione': json['descrizione'] == null ? undefined : json['descrizione'],
    };
}

export function BadgeToJSON(value?: Badge | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'obiettivo': ObiettivoToJSON(value['obiettivo']),
        'nomeBadge': value['nomeBadge'],
        'descrizione': value['descrizione'],
    };
}

