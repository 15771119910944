import {CollezioneInsertDTO, CollezioneInsertDTOTipoCardEnum, CollezioneUpdateDTO} from "../../api";
import {AppDispatch} from "../store";
import {addCollection, deleteCollection, fetchCollezioni, updateCollection} from "./CollezioniSlice";
import {toast} from "react-toastify";
import {styled} from "@mui/material/styles";
import {Button, Card} from "@mui/material";


export const handleCreateCollection = async (dispatch: AppDispatch, nomeCollezione: string, isPrivata: boolean, tipoCollezione: CollezioneInsertDTOTipoCardEnum | undefined, userId: string | undefined) => {
    const insertDTO: CollezioneInsertDTO = {
        nome: nomeCollezione,
        isPrivate: isPrivata,
        tipoCard: tipoCollezione!,
        utenteId: userId!
    };

    try {
        const resultAction = await dispatch(addCollection(insertDTO));
        if (addCollection.fulfilled.match(resultAction)) {
            toast.success("Collezione Creata con successo!!");
        } else {
            toast.error('Errore nel salvataggio del commento: ' + resultAction.error.message);
        }
    } catch (error) {
        toast.error('Errore nel salvataggio del commento.');
    }
};

export const handleUpdateCollection = async (dispatch: AppDispatch, idCollection: string | undefined, nomeCollezione: string, isPrivata: boolean) => {
    const updateDTO: CollezioneUpdateDTO = {
        id: idCollection,
        nome: nomeCollezione,
        isPrivate: isPrivata,
    };

    try {
        const resultAction = await dispatch(updateCollection(updateDTO));
        if (updateCollection.fulfilled.match(resultAction)) {
            toast.success("Collezione Modificata con successo!!");
        } else {
            toast.error('Errore nel salvataggio del commento: ' + resultAction.error.message);
        }
    } catch (error) {
        toast.error('Errore nel salvataggio del commento.');
    }
}

export const handleDeleteCollection = async(dispatch: AppDispatch, idCollection: string | undefined) =>{
        if (!idCollection) {
            toast.error("ID collezione mancante.");
            return;
        }
    try {
        await dispatch(deleteCollection(idCollection));
    } catch (error) {
        toast.error("Errore durante l'eliminazione della collezione.");
    }

}

export const handleOpenCreateDialogCollection = (setSelectedCollectionId: any, setOpenCreateDialog: any) => {
    setSelectedCollectionId(undefined);
    setOpenCreateDialog(true);
};

export const handleCloseCreateDialogCollection = (setOpenCreateDialog:any) => {
    setOpenCreateDialog(false);
};

export const handleOpenEditDialogCollection = (id: string, setSelectedCollectionId: any, setSelectedNomeCollection: any, setOpenEditDialog: any, collections: any) => {
    const collectionToEdit = collections.find((collezione: any) => collezione.id === id);
    setSelectedCollectionId(id);
    setSelectedNomeCollection(collectionToEdit?.nome);
    setOpenEditDialog(true);
};

export const handleEditClickCollection = (id: string, setSelectedCollectionId: any, setSelectedNomeCollection: any, setOpenEditDialog: any, collections: any) => {
    const collectionToEdit = collections.find((collezione: any) => collezione.id === id);
    if (collectionToEdit) {
        setSelectedCollectionId(id);
        setSelectedNomeCollection(collectionToEdit?.nome);
        setOpenEditDialog(true);
    } else {
        console.error('Collezione non trovata');
    }
};


export const handleOpenDeleteDialogCollection = (id: string, collections: any,setSelectedCollectionId: any, setSelectedNomeCollection: any, setOpenDeleteDialog: any) => {
    const collectionToDelete = collections.find((collezione: any) => collezione.id === id);
    setSelectedCollectionId(id);
    setSelectedNomeCollection(collectionToDelete?.nome);
    setOpenDeleteDialog(true);
};

export const handleDeleteClickCollection = (id: string, collections: any,setSelectedCollectionId: any, setSelectedNomeCollection: any, setOpenDeleteDialog: any) => {
    handleOpenDeleteDialogCollection(id, collections, setSelectedCollectionId, setSelectedNomeCollection, setOpenDeleteDialog);
};

export const handleCloseEditDialogCollection = (setOpenEditDialog: any, setSelectedCollectionId: any, dispatch: any, idUser: any, tipoCard: any) => {
    setOpenEditDialog(false);
    setSelectedCollectionId(undefined); // resetta l'ID
    // Ricarica le collezioni dopo la modifica
    dispatch(fetchCollezioni({ idUtente: idUser, tipoCard: tipoCard }));
};


export const handleCloseDeleteDialogCollection = (setOpenDeleteDialog: any, setSelectedCollectionId: any, setSelectedNomeCollection: any) => {
    setOpenDeleteDialog(false);
    setSelectedCollectionId(undefined);
    setSelectedNomeCollection(undefined);
};
export const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: "50px",
    borderRadius: '35px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
}));

export const CustomButton = styled(Button)(({ theme }) => ({
    textAlign: 'center',
    borderRadius: '50px',
    padding: '12px 24px',
    backgroundColor: 'green',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 'bold',
    color: 'white',
    fontSize: '1.2rem',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    '&:hover': {
        backgroundColor: '#0056a3',
        transform: 'translateY(-2px)',
    },
    '&:active': {
        transform: 'translateY(0)',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    },
}));
export const handleSelectCollection = (id: string, setSelectedCollectionId: any) => {
    setSelectedCollectionId(id);
};
