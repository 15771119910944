/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BooleanFilter
 */
export interface BooleanFilter {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanFilter
     */
    equals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanFilter
     */
    notEquals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof BooleanFilter
     */
    _in?: Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof BooleanFilter
     */
    notIn?: Array<boolean>;
}

/**
 * Check if a given object implements the BooleanFilter interface.
 */
export function instanceOfBooleanFilter(value: object): value is BooleanFilter {
    return true;
}

export function BooleanFilterFromJSON(json: any): BooleanFilter {
    return BooleanFilterFromJSONTyped(json, false);
}

export function BooleanFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): BooleanFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'equals': json['equals'] == null ? undefined : json['equals'],
        'notEquals': json['notEquals'] == null ? undefined : json['notEquals'],
        'specified': json['specified'] == null ? undefined : json['specified'],
        '_in': json['in'] == null ? undefined : json['in'],
        'notIn': json['notIn'] == null ? undefined : json['notIn'],
    };
}

export function BooleanFilterToJSON(value?: BooleanFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'equals': value['equals'],
        'notEquals': value['notEquals'],
        'specified': value['specified'],
        'in': value['_in'],
        'notIn': value['notIn'],
    };
}

