import {styled} from "@mui/system";
import {Button, Card, CardContent, Dialog, Grid, IconButton, Select, TextField, Typography} from "@mui/material";

export const SearchInput = styled('div')({
    width: '100%',
    padding: '10px 15px',
    marginBottom: '20px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    display: 'flex',
    alignItems: 'center',
});

export const Root = styled('div')({
    padding: '20px',
});

export const AchievementGrid = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});

export const AchievementCard = styled(Card)({
    width: '100%',
    height: 'auto',
    transition: 'transform 0.2s ease',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '18px',
    '&:hover': {
        transform: 'scale(1.05)',
    },
});


export const UnlockedCard = styled(AchievementCard)({
    backgroundColor: '#e0f7fa',
});

export const LockedCard = styled(AchievementCard)({
    backgroundColor: '#fbe9e7',
});

export const CardContentStyled = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '8px',
    width: '100%',
});

export const Title = styled(Typography)({
    fontWeight: 'bold',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
    textAlign: 'left',
    paddingLeft: '10px',
    marginBottom: '5px',
});

export const Description = styled(Typography)({
    textAlign: 'left',
    fontFamily: 'Open Sans, sans-serif',
    paddingLeft: '10px',
    fontSize: '0.75rem',
    color: '#555',
    fontStyle: 'italic',
});

export const DataCompletamento = styled(Typography)({
    marginTop: '5px',
    fontSize: '0.75rem',
    color: '#777',
    textAlign: 'right',
    flex: 1,
});

export const ProgressBarContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
});

export const CustomButton = styled(Button)`
    border-radius: 100px;
    padding: 12px 20px;
    background-color: chocolate;
    font-family: "Montserrat", sans-serif !Important;
    font-weight: bold;
    font-size: 0.7rem;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #005cb2;
        transform: translateY(-2px); 
    }
`;

export const CancelButton = styled(Button)`
    border-radius: 100px;
    padding: 12px 20px;
    background-color: crimson;
    font-family: "Montserrat", sans-serif !Important;
    font-weight: bold;
    font-size: 0.7rem;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #d32f2f; 
        transform: translateY(-2px);
    }
`;

export const CustomTextField = styled(TextField)`
    margin-top: 20px !important; 
    margin-bottom: 16px !important;
    font-family: "Montserrat", sans-serif !Important;

    & .MuiOutlinedInput-root {
        border-radius: 28px !important;
        font-size: 1.1rem;
        padding: 14px !important;
    }
`;


export const CustomSelect = styled(Select)`
    margin-top: 20px !important;
    margin-bottom: 16px !important;
    font-family: "Montserrat", sans-serif !Important;

    & .MuiOutlinedInput-root {
        border-radius: 28px !important;
        font-size: 1.1rem;
        padding: 14px !important;
    }
`;

export const StyledDialog = styled(Dialog)`
    & .MuiPaper-root {
        border-radius: 30px !important;
    }
`;
export const StyledDialogEditCollection = styled(Dialog)`
    & .MuiPaper-root {
        background: linear-gradient(135deg, #eceff1 0%, #cfd8dc 100%);
        border-radius: 20px;
        padding: 24px;
        max-width: 600px;
        transition: transform 0.3s ease-in-out;
    }
`;
export const Header = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 24px;
`;

export const TitleEdit = styled(Typography)`
    text-align: center;
    font-size: 25px;
    color: #1976d2;
`;

export const StyledIconButton = styled(IconButton)`
    color: #616161;
    position: absolute;
    right: 0;
    transition: color 0.3s;
    &:hover {
        color: #424242;
    }
`;

export const CustomButtonEdit = styled(Button)`
    border-radius: 50px;
    padding: 12px 24px;
    background-color: #388e3c;
    font-weight: bold;
    color: white;
    &:hover {
        background-color: #2e7d32;
    }
`;

export const CustomTextFieldEdit = styled(TextField)`
    margin-bottom: 16px !important;
    & .MuiOutlinedInput-root {
        border-radius: 24px;
    }
`;

export const CustomSelectEdit = styled(Select)`
    margin-bottom: 16px !important;
    & .MuiOutlinedInput-root {
        border-radius: 24px;
    }
`;
