/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TipoCardFilter
 */
export interface TipoCardFilter {
    /**
     * 
     * @type {string}
     * @memberof TipoCardFilter
     */
    equals?: TipoCardFilterEqualsEnum;
    /**
     * 
     * @type {string}
     * @memberof TipoCardFilter
     */
    notEquals?: TipoCardFilterNotEqualsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TipoCardFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof TipoCardFilter
     */
    _in?: Array<TipoCardFilterInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TipoCardFilter
     */
    notIn?: Array<TipoCardFilterNotInEnum>;
}


/**
 * @export
 */
export const TipoCardFilterEqualsEnum = {
    MagicTheGathering: 'MAGIC_THE_GATHERING',
    Pokemon: 'POKEMON',
    YugiOh: 'YUGI_OH',
    OnePiece: 'ONE_PIECE',
    Dragonball: 'DRAGONBALL',
    Lorcana: 'LORCANA',
    Digimon: 'DIGIMON',
    UnionArena: 'UNION_ARENA',
    Starwars: 'STARWARS'
} as const;
export type TipoCardFilterEqualsEnum = typeof TipoCardFilterEqualsEnum[keyof typeof TipoCardFilterEqualsEnum];

/**
 * @export
 */
export const TipoCardFilterNotEqualsEnum = {
    MagicTheGathering: 'MAGIC_THE_GATHERING',
    Pokemon: 'POKEMON',
    YugiOh: 'YUGI_OH',
    OnePiece: 'ONE_PIECE',
    Dragonball: 'DRAGONBALL',
    Lorcana: 'LORCANA',
    Digimon: 'DIGIMON',
    UnionArena: 'UNION_ARENA',
    Starwars: 'STARWARS'
} as const;
export type TipoCardFilterNotEqualsEnum = typeof TipoCardFilterNotEqualsEnum[keyof typeof TipoCardFilterNotEqualsEnum];

/**
 * @export
 */
export const TipoCardFilterInEnum = {
    MagicTheGathering: 'MAGIC_THE_GATHERING',
    Pokemon: 'POKEMON',
    YugiOh: 'YUGI_OH',
    OnePiece: 'ONE_PIECE',
    Dragonball: 'DRAGONBALL',
    Lorcana: 'LORCANA',
    Digimon: 'DIGIMON',
    UnionArena: 'UNION_ARENA',
    Starwars: 'STARWARS'
} as const;
export type TipoCardFilterInEnum = typeof TipoCardFilterInEnum[keyof typeof TipoCardFilterInEnum];

/**
 * @export
 */
export const TipoCardFilterNotInEnum = {
    MagicTheGathering: 'MAGIC_THE_GATHERING',
    Pokemon: 'POKEMON',
    YugiOh: 'YUGI_OH',
    OnePiece: 'ONE_PIECE',
    Dragonball: 'DRAGONBALL',
    Lorcana: 'LORCANA',
    Digimon: 'DIGIMON',
    UnionArena: 'UNION_ARENA',
    Starwars: 'STARWARS'
} as const;
export type TipoCardFilterNotInEnum = typeof TipoCardFilterNotInEnum[keyof typeof TipoCardFilterNotInEnum];


/**
 * Check if a given object implements the TipoCardFilter interface.
 */
export function instanceOfTipoCardFilter(value: object): value is TipoCardFilter {
    return true;
}

export function TipoCardFilterFromJSON(json: any): TipoCardFilter {
    return TipoCardFilterFromJSONTyped(json, false);
}

export function TipoCardFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): TipoCardFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'equals': json['equals'] == null ? undefined : json['equals'],
        'notEquals': json['notEquals'] == null ? undefined : json['notEquals'],
        'specified': json['specified'] == null ? undefined : json['specified'],
        '_in': json['in'] == null ? undefined : json['in'],
        'notIn': json['notIn'] == null ? undefined : json['notIn'],
    };
}

export function TipoCardFilterToJSON(value?: TipoCardFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'equals': value['equals'],
        'notEquals': value['notEquals'],
        'specified': value['specified'],
        'in': value['_in'],
        'notIn': value['notIn'],
    };
}

