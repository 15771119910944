import React, {useState} from 'react';
import {Grid, IconButton, InputBase, LinearProgress, Typography} from '@mui/material';
import {Search} from '@mui/icons-material';
import {formatDate} from '../../../utility/utils';
import {
    AchievementGrid,
    CardContentStyled,
    DataCompletamento,
    Description,
    LockedCard,
    ProgressBarContainer,
    Root,
    SearchInput,
    Title,
    UnlockedCard
} from "../../../utility/utility-css";

interface ListaObiettiviProps {
    achievements: any[];
}

    const ListaObiettivi: React.FC<ListaObiettiviProps> = ({ achievements }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredAchievements = achievements.filter((achievement) =>
        achievement.obiettivo.nome.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const calcolaProgresso = (progresso: number, target: number): number => {
        if (!progresso || !target) {
            return 0;
        }
        return Math.min((progresso / target) * 100, 100);
    };

    const getProgressBarColor = (progresso: number, target: number): string => {
        return calcolaProgresso(progresso, target) === 100 ? '#4caf50' : '#6495ED';
    };

    return (
        <Root>
            <SearchInput>
                <IconButton aria-label="search">
                    <Search />
                </IconButton>
                <InputBase
                    placeholder="Cerca Obiettivi..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fullWidth
                />
            </SearchInput>
            <AchievementGrid container>
                {filteredAchievements.map((achievement, index) => (
                    <Grid item key={index} xs={12}>
                        {achievement.completato ? (
                            <UnlockedCard>
                                <CardContentStyled>
                                    {achievement.obiettivo ? (
                                        <>
                                            <Title variant="h6">{achievement.obiettivo.nome}</Title>
                                            <Description>{achievement.obiettivo.descrizione}</Description>
                                            <DataCompletamento>Completato: {formatDate(achievement.dataCompletamento)}</DataCompletamento>

                                            {/* Progress Bar */}
                                            <ProgressBarContainer>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={calcolaProgresso(
                                                        achievement.progresso,
                                                        achievement.obiettivo.target
                                                    )}
                                                    sx={{
                                                        width: '80%',
                                                        backgroundColor: '#e0e0e0',
                                                        '& .MuiLinearProgress-bar': {
                                                            backgroundColor: getProgressBarColor(
                                                                achievement.progresso,
                                                                achievement.obiettivo.target
                                                            ),
                                                        },
                                                    }}
                                                />
                                                <Typography
                                                    variant="body2"
                                                    sx={{ marginTop: 1, textAlign: 'right' }}
                                                >
                                                    {calcolaProgresso(
                                                        achievement.progresso,
                                                        achievement.obiettivo.target
                                                    ).toFixed(0)}%
                                                </Typography>
                                            </ProgressBarContainer>
                                        </>
                                    ) : (
                                        <Typography variant="h6" color="error">
                                            Obiettivo non disponibile
                                        </Typography>
                                    )}
                                </CardContentStyled>
                            </UnlockedCard>
                        ) : (
                            <LockedCard>
                                <CardContentStyled>
                                    <Title variant="h6">{achievement.obiettivo.nome}</Title>
                                    <Description>{achievement.obiettivo.descrizione}</Description>

                                    <ProgressBarContainer>
                                        <LinearProgress
                                            variant="determinate"
                                            value={calcolaProgresso(
                                                achievement.progresso,
                                                achievement.obiettivo.target
                                            )}
                                            sx={{
                                                width: '80%',
                                                backgroundColor: '#e0e0e0',
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: getProgressBarColor(
                                                        achievement.progresso,
                                                        achievement.obiettivo.target
                                                    ),
                                                },
                                            }}
                                        />
                                        <Typography
                                            variant="body2"
                                            sx={{ marginTop: 1, textAlign: 'right' }}
                                        >
                                            {calcolaProgresso(
                                                achievement.progresso,
                                                achievement.obiettivo.target
                                            ).toFixed(0)}%
                                        </Typography>
                                    </ProgressBarContainer>
                                </CardContentStyled>
                            </LockedCard>
                        )}
                    </Grid>
                ))}
            </AchievementGrid>
        </Root>
    );
}

export default ListaObiettivi;
