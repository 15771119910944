/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TipoCardFilter } from './TipoCardFilter';
import {
    TipoCardFilterFromJSON,
    TipoCardFilterFromJSONTyped,
    TipoCardFilterToJSON,
} from './TipoCardFilter';
import type { UUIDFilter } from './UUIDFilter';
import {
    UUIDFilterFromJSON,
    UUIDFilterFromJSONTyped,
    UUIDFilterToJSON,
} from './UUIDFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';

/**
 * 
 * @export
 * @interface LorcanaCriteria
 */
export interface LorcanaCriteria {
    /**
     * 
     * @type {UUIDFilter}
     * @memberof LorcanaCriteria
     */
    id?: UUIDFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    artist?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    rarity?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    imageUrl?: StringFilter;
    /**
     * 
     * @type {TipoCardFilter}
     * @memberof LorcanaCriteria
     */
    tipoCard?: TipoCardFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    idCartaPokemon?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    sourceName?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    idCard?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    numero?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    ink?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    caratteristiche?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    strength?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    willpower?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    lore?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    flavour?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof LorcanaCriteria
     */
    franchiseName?: StringFilter;
}

/**
 * Check if a given object implements the LorcanaCriteria interface.
 */
export function instanceOfLorcanaCriteria(value: object): value is LorcanaCriteria {
    return true;
}

export function LorcanaCriteriaFromJSON(json: any): LorcanaCriteria {
    return LorcanaCriteriaFromJSONTyped(json, false);
}

export function LorcanaCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): LorcanaCriteria {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : UUIDFilterFromJSON(json['id']),
        'name': json['name'] == null ? undefined : StringFilterFromJSON(json['name']),
        'artist': json['artist'] == null ? undefined : StringFilterFromJSON(json['artist']),
        'rarity': json['rarity'] == null ? undefined : StringFilterFromJSON(json['rarity']),
        'imageUrl': json['imageUrl'] == null ? undefined : StringFilterFromJSON(json['imageUrl']),
        'tipoCard': json['tipoCard'] == null ? undefined : TipoCardFilterFromJSON(json['tipoCard']),
        'idCartaPokemon': json['idCartaPokemon'] == null ? undefined : StringFilterFromJSON(json['idCartaPokemon']),
        'sourceName': json['sourceName'] == null ? undefined : StringFilterFromJSON(json['sourceName']),
        'idCard': json['idCard'] == null ? undefined : StringFilterFromJSON(json['idCard']),
        'numero': json['numero'] == null ? undefined : StringFilterFromJSON(json['numero']),
        'ink': json['ink'] == null ? undefined : StringFilterFromJSON(json['ink']),
        'caratteristiche': json['caratteristiche'] == null ? undefined : StringFilterFromJSON(json['caratteristiche']),
        'strength': json['strength'] == null ? undefined : StringFilterFromJSON(json['strength']),
        'willpower': json['willpower'] == null ? undefined : StringFilterFromJSON(json['willpower']),
        'lore': json['lore'] == null ? undefined : StringFilterFromJSON(json['lore']),
        'flavour': json['flavour'] == null ? undefined : StringFilterFromJSON(json['flavour']),
        'franchiseName': json['franchiseName'] == null ? undefined : StringFilterFromJSON(json['franchiseName']),
    };
}

export function LorcanaCriteriaToJSON(value?: LorcanaCriteria | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': UUIDFilterToJSON(value['id']),
        'name': StringFilterToJSON(value['name']),
        'artist': StringFilterToJSON(value['artist']),
        'rarity': StringFilterToJSON(value['rarity']),
        'imageUrl': StringFilterToJSON(value['imageUrl']),
        'tipoCard': TipoCardFilterToJSON(value['tipoCard']),
        'idCartaPokemon': StringFilterToJSON(value['idCartaPokemon']),
        'sourceName': StringFilterToJSON(value['sourceName']),
        'idCard': StringFilterToJSON(value['idCard']),
        'numero': StringFilterToJSON(value['numero']),
        'ink': StringFilterToJSON(value['ink']),
        'caratteristiche': StringFilterToJSON(value['caratteristiche']),
        'strength': StringFilterToJSON(value['strength']),
        'willpower': StringFilterToJSON(value['willpower']),
        'lore': StringFilterToJSON(value['lore']),
        'flavour': StringFilterToJSON(value['flavour']),
        'franchiseName': StringFilterToJSON(value['franchiseName']),
    };
}

