import React, { useState } from 'react';
import { MenuItem, Select, InputLabel, FormControl, Box, SelectChangeEvent } from '@mui/material';

interface SelectTipoCollezioneProps {
    onSelectionChange: (tipoCollezione: string) => void;
}

const SelectTipoCollezione: React.FC<SelectTipoCollezioneProps> = ({ onSelectionChange }) => {
    const [selectedTipo, setSelectedTipo] = useState<string>("");

    const tipiCollezione = ["One Piece", "DragonBall Fusion", "Lorcana"];

    const handleSelectionChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value as string;
        setSelectedTipo(value);
        console.log(value)
        onSelectionChange(value);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, padding: 2 }}>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="tipoCollezione-label">Tipo Collezione</InputLabel>
                <Select
                    labelId="tipoCollezione-label"
                    id="tipoCollezione"
                    value={selectedTipo}
                    onChange={handleSelectionChange}
                    label="Tipo Collezione"
                >
                    <MenuItem value="">
                        <em>-- Seleziona un tipo --</em>
                    </MenuItem>
                    {tipiCollezione.map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>
                            {tipo}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default SelectTipoCollezione;
