/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RarityOpDTO
 */
export interface RarityOpDTO {
    /**
     * 
     * @type {number}
     * @memberof RarityOpDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof RarityOpDTO
     */
    idRarity?: number;
    /**
     * 
     * @type {string}
     * @memberof RarityOpDTO
     */
    nome?: string;
}

/**
 * Check if a given object implements the RarityOpDTO interface.
 */
export function instanceOfRarityOpDTO(value: object): value is RarityOpDTO {
    return true;
}

export function RarityOpDTOFromJSON(json: any): RarityOpDTO {
    return RarityOpDTOFromJSONTyped(json, false);
}

export function RarityOpDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RarityOpDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'idRarity': json['idRarity'] == null ? undefined : json['idRarity'],
        'nome': json['nome'] == null ? undefined : json['nome'],
    };
}

export function RarityOpDTOToJSON(value?: RarityOpDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'idRarity': value['idRarity'],
        'nome': value['nome'],
    };
}

