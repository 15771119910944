/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TendenzaDTO
 */
export interface TendenzaDTO {
    /**
     * 
     * @type {string}
     * @memberof TendenzaDTO
     */
    data?: string;
    /**
     * 
     * @type {number}
     * @memberof TendenzaDTO
     */
    prezzoMinimo?: number;
    /**
     * 
     * @type {number}
     * @memberof TendenzaDTO
     */
    prezzoMassimo?: number;
    /**
     * 
     * @type {number}
     * @memberof TendenzaDTO
     */
    prezzoApertura?: number;
    /**
     * 
     * @type {number}
     * @memberof TendenzaDTO
     */
    prezzoChiusura?: number;
    /**
     * 
     * @type {number}
     * @memberof TendenzaDTO
     */
    normale?: number;
    /**
     * 
     * @type {number}
     * @memberof TendenzaDTO
     */
    foil?: number;
    /**
     * 
     * @type {number}
     * @memberof TendenzaDTO
     */
    holoFoil?: number;
    /**
     * 
     * @type {number}
     * @memberof TendenzaDTO
     */
    coldFoil?: number;
}

/**
 * Check if a given object implements the TendenzaDTO interface.
 */
export function instanceOfTendenzaDTO(value: object): value is TendenzaDTO {
    return true;
}

export function TendenzaDTOFromJSON(json: any): TendenzaDTO {
    return TendenzaDTOFromJSONTyped(json, false);
}

export function TendenzaDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TendenzaDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : json['data'],
        'prezzoMinimo': json['prezzoMinimo'] == null ? undefined : json['prezzoMinimo'],
        'prezzoMassimo': json['prezzoMassimo'] == null ? undefined : json['prezzoMassimo'],
        'prezzoApertura': json['prezzoApertura'] == null ? undefined : json['prezzoApertura'],
        'prezzoChiusura': json['prezzoChiusura'] == null ? undefined : json['prezzoChiusura'],
        'normale': json['normale'] == null ? undefined : json['normale'],
        'foil': json['foil'] == null ? undefined : json['foil'],
        'holoFoil': json['holoFoil'] == null ? undefined : json['holoFoil'],
        'coldFoil': json['coldFoil'] == null ? undefined : json['coldFoil'],
    };
}

export function TendenzaDTOToJSON(value?: TendenzaDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
        'prezzoMinimo': value['prezzoMinimo'],
        'prezzoMassimo': value['prezzoMassimo'],
        'prezzoApertura': value['prezzoApertura'],
        'prezzoChiusura': value['prezzoChiusura'],
        'normale': value['normale'],
        'foil': value['foil'],
        'holoFoil': value['holoFoil'],
        'coldFoil': value['coldFoil'],
    };
}

