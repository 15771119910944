export interface DettagliCartaProps {
    open: boolean;
    onClose: () => void;
    carta: any;
    tipo: any;
}
export interface Field {
    label: string;
    key: string;
    optional: boolean;
}

export const specificFields: Record<string, Field[]> = {
    'one_piece': [
        { label: 'Attributo', key: 'attacco', optional: true },
        { label: 'Espansione', key: 'sourceName', optional: false },
        { label: 'Archetipo', key: 'appartenenza', optional: false },
        { label: 'Potenza', key: 'potenza', optional: false },
    ],
    Pokemon: [
        { label: 'Nome Espansione', key: 'espansione', optional: false },
        { label: 'Tipo', key: 'tipo', optional: false },
        { label: 'Punti', key: 'punti', optional: false },
        { label: 'Abilità', key: 'abilita', optional: true },
    ],
    dragonball: [
        { label: 'Espansione', key: 'nomeEspansione', optional: false },
        { label: 'Caratteristiche', key: 'features', optional: true },
        { label: 'Potenza', key: 'potenza', optional: true },
        { label: 'Counter', key: 'comboPower', optional: true },
    ],
    lorcana: [
        { label: 'Espansione', key: 'nomeEspansione', optional: false },
        { label: 'Caratteristiche', key: 'caratteristiche', optional: true },
        { label: 'Potenza', key: 'strength', optional: true },
        { label: 'Difesa', key: 'potenza', optional: true },
        { label: 'Franchise', key: 'franchiseName', optional: true },
        { label: 'Artista', key: 'artist', optional: true },
        { label: 'Aforisma', key: 'flavour', optional: true },
    ],
};
export const formatCardNumber = (input: string) => {
    return input ? input.replace(/_[^ ]*/g, '') : '';
};
export const handleFlip = (setRotating: any, setFlipped: any) => {
    setRotating(true);
    setTimeout(() => {
        setFlipped((prev: any) => !prev);
        setRotating(false);
    }, 600);
};

export const getCardImageUrl = (tipo: any, carta: any) => {
    let url;
    if (tipo === 'dragonball' && carta.alternativeArt && carta.alternativeArt.startsWith('_')) {
        url = `https://static.dotgg.gg/dragonball/card/${carta.idCard}.webp`;
    } else {
        url = carta?.imageUrl;
    }
    return url;
};

export const commonFields: Field[] = [
    { label: 'Rarità', key: 'rarity', optional: true },
    { label: 'Colore', key: 'colore', optional: true },
    { label: 'Costo', key: 'costo', optional: true },
];


export const fields = (tipo: any) => {
    return [...commonFields, ...(specificFields[tipo] || [])];
};

export const headers = [
    { id: 'nome', label: 'NOME', xs: 3 },
    { id: 'privacy', label: 'PRIVACY', xs: 3 },
    { id: 'tipoCollezione', label: 'TIPO', xs: 3 },
    { id: 'azioni', label: 'AZIONI', xs: 3 },
];
export const handleViewClick = (id: string | undefined, tipoCard: any, navigate: any) => {
    navigate(`/my-collection-detail/${id}/${tipoCard}`);
};
