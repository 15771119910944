/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Obiettivo
 */
export interface Obiettivo {
    /**
     * 
     * @type {string}
     * @memberof Obiettivo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Obiettivo
     */
    nome?: string;
    /**
     * 
     * @type {string}
     * @memberof Obiettivo
     */
    descrizione?: string;
    /**
     * 
     * @type {number}
     * @memberof Obiettivo
     */
    target?: number;
    /**
     * 
     * @type {number}
     * @memberof Obiettivo
     */
    punti?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Obiettivo
     */
    sbloccaBadge?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Obiettivo
     */
    dataInizio?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Obiettivo
     */
    scadenza?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Obiettivo
     */
    attivo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Obiettivo
     */
    tipoEvento?: ObiettivoTipoEventoEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Obiettivo
     */
    nuovoUtente?: boolean;
    /**
     * 
     * @type {Obiettivo}
     * @memberof Obiettivo
     */
    obiettivoSuccessivo?: Obiettivo;
}


/**
 * @export
 */
export const ObiettivoTipoEventoEnum = {
    Card: 'CARD',
    Collection: 'COLLECTION',
    CardCollection: 'CARD_COLLECTION',
    GestioneProfilo: 'GESTIONE_PROFILO'
} as const;
export type ObiettivoTipoEventoEnum = typeof ObiettivoTipoEventoEnum[keyof typeof ObiettivoTipoEventoEnum];


/**
 * Check if a given object implements the Obiettivo interface.
 */
export function instanceOfObiettivo(value: object): value is Obiettivo {
    return true;
}

export function ObiettivoFromJSON(json: any): Obiettivo {
    return ObiettivoFromJSONTyped(json, false);
}

export function ObiettivoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Obiettivo {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'nome': json['nome'] == null ? undefined : json['nome'],
        'descrizione': json['descrizione'] == null ? undefined : json['descrizione'],
        'target': json['target'] == null ? undefined : json['target'],
        'punti': json['punti'] == null ? undefined : json['punti'],
        'sbloccaBadge': json['sbloccaBadge'] == null ? undefined : json['sbloccaBadge'],
        'dataInizio': json['dataInizio'] == null ? undefined : (new Date(json['dataInizio'])),
        'scadenza': json['scadenza'] == null ? undefined : (new Date(json['scadenza'])),
        'attivo': json['attivo'] == null ? undefined : json['attivo'],
        'tipoEvento': json['tipoEvento'] == null ? undefined : json['tipoEvento'],
        'nuovoUtente': json['nuovoUtente'] == null ? undefined : json['nuovoUtente'],
        'obiettivoSuccessivo': json['obiettivoSuccessivo'] == null ? undefined : ObiettivoFromJSON(json['obiettivoSuccessivo']),
    };
}

export function ObiettivoToJSON(value?: Obiettivo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'nome': value['nome'],
        'descrizione': value['descrizione'],
        'target': value['target'],
        'punti': value['punti'],
        'sbloccaBadge': value['sbloccaBadge'],
        'dataInizio': value['dataInizio'] == null ? undefined : ((value['dataInizio']).toISOString()),
        'scadenza': value['scadenza'] == null ? undefined : ((value['scadenza']).toISOString()),
        'attivo': value['attivo'],
        'tipoEvento': value['tipoEvento'],
        'nuovoUtente': value['nuovoUtente'],
        'obiettivoSuccessivo': ObiettivoToJSON(value['obiettivoSuccessivo']),
    };
}

