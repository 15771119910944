/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIAuthenticationTokenDTO
 */
export interface AIAuthenticationTokenDTO {
    /**
     * 
     * @type {string}
     * @memberof AIAuthenticationTokenDTO
     */
    codice?: string;
    /**
     * 
     * @type {string}
     * @memberof AIAuthenticationTokenDTO
     */
    idUtente?: string;
    /**
     * 
     * @type {Date}
     * @memberof AIAuthenticationTokenDTO
     */
    dataInizioValidita?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AIAuthenticationTokenDTO
     */
    dataScadenza?: Date;
}

/**
 * Check if a given object implements the AIAuthenticationTokenDTO interface.
 */
export function instanceOfAIAuthenticationTokenDTO(value: object): value is AIAuthenticationTokenDTO {
    return true;
}

export function AIAuthenticationTokenDTOFromJSON(json: any): AIAuthenticationTokenDTO {
    return AIAuthenticationTokenDTOFromJSONTyped(json, false);
}

export function AIAuthenticationTokenDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIAuthenticationTokenDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'codice': json['codice'] == null ? undefined : json['codice'],
        'idUtente': json['idUtente'] == null ? undefined : json['idUtente'],
        'dataInizioValidita': json['dataInizioValidita'] == null ? undefined : (new Date(json['dataInizioValidita'])),
        'dataScadenza': json['dataScadenza'] == null ? undefined : (new Date(json['dataScadenza'])),
    };
}

export function AIAuthenticationTokenDTOToJSON(value?: AIAuthenticationTokenDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'codice': value['codice'],
        'idUtente': value['idUtente'],
        'dataInizioValidita': value['dataInizioValidita'] == null ? undefined : ((value['dataInizioValidita']).toISOString()),
        'dataScadenza': value['dataScadenza'] == null ? undefined : ((value['dataScadenza']).toISOString()),
    };
}

