export const handleFilter = (name: any, selectedExpansion: any, selectedRarity: any, selectedColor: any, onFilter: any) => {
    const criteria = {
        name: {contains: name},
        sourceName: selectedExpansion ? {equals: selectedExpansion} : undefined,
        rarity: selectedRarity ? {equals: selectedRarity} : undefined,
        colore: selectedColor ? {equals: selectedColor} : undefined,
    };

    onFilter({criteria});
};
export const handleReset = (setSelectedExpansion: any, setSelectedRarity: any, setSelectedColor: any, setName: any, setFilteredExpansions: any, onFilter: any) => {
    setSelectedExpansion(null);
    setSelectedRarity('');
    setSelectedColor('');
    setName('');
    setFilteredExpansions([]);
    onFilter({criteria: {}});
};

export const handleSearch = (event: any, value: any, setLoading: any, setFilteredExpansions: any, expansions: any) => {
    setLoading(true);

    if (value.trim() === '') {
        setFilteredExpansions([]);
    } else {
        const filtered = expansions.filter((expansion: any) =>
            expansion?.nome?.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredExpansions(filtered);
    }

    setLoading(false);
};
export const menuItemStyles = {
    padding: '10px 15px',
    fontSize: '16px',
    '&:hover': {
        backgroundColor: '#007bff',
        color: '#ffffff',
    },
    '&.Mui-selected': {
        backgroundColor: '#0056b3',
        color: '#ffffff',
    },
    '&.Mui-selected:hover': {
        backgroundColor: '#003d80',
    },
};
