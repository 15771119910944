import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { fetchCollezionById } from "../../../store/collezioni/CollezioniSlice";
import {CollezioneDTO} from "../../../api";

export const useFetchCollezione = (collectionId: string | undefined) => {
    const dispatch = useDispatch<AppDispatch>();
    const [collezione, setCollezione] = useState<CollezioneDTO | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!collectionId) return;

        const fetchData = async () => {
            try {
                setLoading(true);
                const resultAction = await dispatch(fetchCollezionById({ idCollection: collectionId }));
                if (fetchCollezionById.fulfilled.match(resultAction)) {
                    setCollezione(resultAction.payload);
                } else {
                    setError("Impossibile caricare la collezione.");
                }
            } catch (err) {
                setError("Errore durante il fetch della collezione.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [collectionId, dispatch]);

    return { collezione, loading, error };
};
