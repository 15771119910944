import {debounce} from "@mui/material";
import React from "react";
import {CarteRestApi, TipoCardFilterEqualsEnum} from "../../api";

const api = new CarteRestApi();
export const handleSearch = debounce(async (event: React.SyntheticEvent, value: string,
                                            setSearchTerm: any, setSuggestions: any,
                                            setLoading: any, currentPage: any) => {
    setSearchTerm(value);

    if (value.trim() === '') {
        setSuggestions([]);
        return;
    }

    if (value.length >= 4) {
        setLoading(true);
        try {
            const pageable = {pageNumber: currentPage - 1, pageSize: 15};
            const newFilter = {
                criteria: {tipoCard: {equals: TipoCardFilterEqualsEnum.OnePiece}},
                criteriaOnePiece: {numeroEspansione: {contains: value}},
                pageable,
            };
            const searchRequest = {searchRequest: newFilter};
            const response = await api.findByCriteriaSpecificCardRaw(searchRequest);
            const data = await response.raw.json();
            if (data?.content && Array.isArray(data.content)) {
                setSuggestions(data.content);
            } else {
                setSuggestions([]);
            }
        } catch (error) {
            console.error('Errore nella ricerca delle carte:', error);
            setSuggestions([]);
            alert('Errore nel recupero delle carte. Riprova più tardi!');
        } finally {
            setLoading(false);
        }
    } else {
        setSuggestions([]);
    }
}, 500);

export const handleSelectCard = async (
    newCard: any,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    prevSelectedCardId: string | null,
    setSelectedCard: React.Dispatch<React.SetStateAction<any>>,
    setPrevSelectedCardId: React.Dispatch<React.SetStateAction<string | null>>,
    setTendenza: React.Dispatch<React.SetStateAction<any>>
) => {
    setLoading(true);
    try {
        if (newCard && newCard.id !== prevSelectedCardId) {
            setSelectedCard(newCard);
            setPrevSelectedCardId(newCard.id);
            setTendenza(newCard.tendenzaPrezzo || []);
        }
    } catch (error) {
        console.error('Errore nel recuperare i dettagli della carta:', error);
        alert('Errore nel recupero dei dettagli della carta. Riprova più tardi!');
    } finally {
        setLoading(false);
    }
};

