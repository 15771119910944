import React, {useContext, useState} from 'react';
import {AuthContext} from '../auth/AuthContext';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import './../styles/login.css';
import {Divider} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const from = (location.state as { from: Location })?.from?.pathname || '/';
    const [error, setError] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (auth) {
                await auth.login(username, password);
                navigate(from, {replace: true});
                window.location.reload();
            }
        } catch (error) {
            console.error('Login failed', error);
            setError('Login failed, please try again.');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h2>Login</h2>
                {error && <p className="error">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Username</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '10px',
                                marginBottom: '20px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                fontSize: '16px',
                            }}
                        />
                    </div>
                    <div style={{position: 'relative', marginBottom: '20px'}}>
                        <label>Password</label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '10px',
                                paddingRight: '50px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                fontSize: '16px',
                            }}
                        />
                        <span
                            onClick={togglePasswordVisibility}
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                                color: 'grey',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                            }}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e0e0e0')}
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#f1f1f1')}
                        >
        {showPassword ? <VisibilityOff style={{fontSize: '20px'}}/> : <Visibility style={{fontSize: '20px'}}/>}
    </span>
                    </div>
                    <button
                        type="submit"
                        style={{
                            width: '100%',
                            padding: '10px',
                            borderRadius: '5px',
                            border: 'none',
                            backgroundColor: '#007bff',
                            color: '#fff',
                            fontSize: '16px',
                            cursor: 'pointer',
                            transition: 'background-color 0.2s ease',
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#0056b3')}
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#007bff')}
                    >
                        Login
                    </button>
                </form>
                <Divider style={{marginTop: '20px', marginBottom: '15px'}}/>
                <p className="register-link">
                <span style={{marginTop: '15px', display: 'inline-block', fontFamily: '"Montserrat", sans-serif'}}>
                    Non sei registrato?{' '}
                </span>
                    <Link to="/registrati"> Registrati</Link>
                    <span style={{marginTop: '15px', display: 'inline-block', fontFamily: '"Montserrat", sans-serif'}}>
                    Password dimenticata?{' '}
                </span>
                    <Link to="/recupera-password">Recuperala</Link>
                </p>
            </div>
        </div>
    );

};

export default Login;
