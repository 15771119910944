import React, { useContext } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { CircularProgress, Box } from '@mui/material';

const PrivateRoute: React.FC = () => {
    const auth = useContext(AuthContext);
    const location = useLocation();

    if (auth?.loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (!auth?.user) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return <Outlet />;
};

export default PrivateRoute;
