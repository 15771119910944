/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TipoCardFilter } from './TipoCardFilter';
import {
    TipoCardFilterFromJSON,
    TipoCardFilterFromJSONTyped,
    TipoCardFilterToJSON,
} from './TipoCardFilter';
import type { UUIDFilter } from './UUIDFilter';
import {
    UUIDFilterFromJSON,
    UUIDFilterFromJSONTyped,
    UUIDFilterToJSON,
} from './UUIDFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';
import type { LongFilter } from './LongFilter';
import {
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
} from './LongFilter';

/**
 * 
 * @export
 * @interface CartaOPCriteria
 */
export interface CartaOPCriteria {
    /**
     * 
     * @type {UUIDFilter}
     * @memberof CartaOPCriteria
     */
    id?: UUIDFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaOPCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaOPCriteria
     */
    artist?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaOPCriteria
     */
    rarity?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaOPCriteria
     */
    imageUrl?: StringFilter;
    /**
     * 
     * @type {TipoCardFilter}
     * @memberof CartaOPCriteria
     */
    tipoCard?: TipoCardFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaOPCriteria
     */
    idCartaPokemon?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaOPCriteria
     */
    sourceName?: StringFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CartaOPCriteria
     */
    idCartaOp?: LongFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaOPCriteria
     */
    numeroEspansione?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaOPCriteria
     */
    colore?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaOPCriteria
     */
    appartenenza?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaOPCriteria
     */
    costoOp?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaOPCriteria
     */
    alternativeArt?: StringFilter;
}

/**
 * Check if a given object implements the CartaOPCriteria interface.
 */
export function instanceOfCartaOPCriteria(value: object): value is CartaOPCriteria {
    return true;
}

export function CartaOPCriteriaFromJSON(json: any): CartaOPCriteria {
    return CartaOPCriteriaFromJSONTyped(json, false);
}

export function CartaOPCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartaOPCriteria {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : UUIDFilterFromJSON(json['id']),
        'name': json['name'] == null ? undefined : StringFilterFromJSON(json['name']),
        'artist': json['artist'] == null ? undefined : StringFilterFromJSON(json['artist']),
        'rarity': json['rarity'] == null ? undefined : StringFilterFromJSON(json['rarity']),
        'imageUrl': json['imageUrl'] == null ? undefined : StringFilterFromJSON(json['imageUrl']),
        'tipoCard': json['tipoCard'] == null ? undefined : TipoCardFilterFromJSON(json['tipoCard']),
        'idCartaPokemon': json['idCartaPokemon'] == null ? undefined : StringFilterFromJSON(json['idCartaPokemon']),
        'sourceName': json['sourceName'] == null ? undefined : StringFilterFromJSON(json['sourceName']),
        'idCartaOp': json['idCartaOp'] == null ? undefined : LongFilterFromJSON(json['idCartaOp']),
        'numeroEspansione': json['numeroEspansione'] == null ? undefined : StringFilterFromJSON(json['numeroEspansione']),
        'colore': json['colore'] == null ? undefined : StringFilterFromJSON(json['colore']),
        'appartenenza': json['appartenenza'] == null ? undefined : StringFilterFromJSON(json['appartenenza']),
        'costoOp': json['costoOp'] == null ? undefined : StringFilterFromJSON(json['costoOp']),
        'alternativeArt': json['alternativeArt'] == null ? undefined : StringFilterFromJSON(json['alternativeArt']),
    };
}

export function CartaOPCriteriaToJSON(value?: CartaOPCriteria | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': UUIDFilterToJSON(value['id']),
        'name': StringFilterToJSON(value['name']),
        'artist': StringFilterToJSON(value['artist']),
        'rarity': StringFilterToJSON(value['rarity']),
        'imageUrl': StringFilterToJSON(value['imageUrl']),
        'tipoCard': TipoCardFilterToJSON(value['tipoCard']),
        'idCartaPokemon': StringFilterToJSON(value['idCartaPokemon']),
        'sourceName': StringFilterToJSON(value['sourceName']),
        'idCartaOp': LongFilterToJSON(value['idCartaOp']),
        'numeroEspansione': StringFilterToJSON(value['numeroEspansione']),
        'colore': StringFilterToJSON(value['colore']),
        'appartenenza': StringFilterToJSON(value['appartenenza']),
        'costoOp': StringFilterToJSON(value['costoOp']),
        'alternativeArt': StringFilterToJSON(value['alternativeArt']),
    };
}

