/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CollezioneInsertDTO
 */
export interface CollezioneInsertDTO {
    /**
     * 
     * @type {string}
     * @memberof CollezioneInsertDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CollezioneInsertDTO
     */
    utenteId?: string;
    /**
     * 
     * @type {string}
     * @memberof CollezioneInsertDTO
     */
    nome?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CollezioneInsertDTO
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CollezioneInsertDTO
     */
    tipoCard?: CollezioneInsertDTOTipoCardEnum;
}


/**
 * @export
 */
export const CollezioneInsertDTOTipoCardEnum = {
    MagicTheGathering: 'MAGIC_THE_GATHERING',
    Pokemon: 'POKEMON',
    YugiOh: 'YUGI_OH',
    OnePiece: 'ONE_PIECE',
    Dragonball: 'DRAGONBALL',
    Lorcana: 'LORCANA',
    Digimon: 'DIGIMON',
    UnionArena: 'UNION_ARENA',
    Starwars: 'STARWARS'
} as const;
export type CollezioneInsertDTOTipoCardEnum = typeof CollezioneInsertDTOTipoCardEnum[keyof typeof CollezioneInsertDTOTipoCardEnum];


/**
 * Check if a given object implements the CollezioneInsertDTO interface.
 */
export function instanceOfCollezioneInsertDTO(value: object): value is CollezioneInsertDTO {
    return true;
}

export function CollezioneInsertDTOFromJSON(json: any): CollezioneInsertDTO {
    return CollezioneInsertDTOFromJSONTyped(json, false);
}

export function CollezioneInsertDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollezioneInsertDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'utenteId': json['utente_id'] == null ? undefined : json['utente_id'],
        'nome': json['nome'] == null ? undefined : json['nome'],
        'isPrivate': json['isPrivate'] == null ? undefined : json['isPrivate'],
        'tipoCard': json['tipoCard'] == null ? undefined : json['tipoCard'],
    };
}

export function CollezioneInsertDTOToJSON(value?: CollezioneInsertDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'utente_id': value['utenteId'],
        'nome': value['nome'],
        'isPrivate': value['isPrivate'],
        'tipoCard': value['tipoCard'],
    };
}

