/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Problem,
} from '../models/index';
import {
    ProblemFromJSON,
    ProblemToJSON,
} from '../models/index';

export interface ExportCollezioneToExcelRequest {
    collezioneId: string;
}

export interface ExportCollezioneToPDFRequest {
    collezioneId: string;
}

/**
 * 
 */
export class DocumentiRestApi extends runtime.BaseAPI {

    /**
     * Ha bisogno dell\'id della collezione
     * Estrae tutte le carte di una collezione e le mette in un Excel
     */
    async exportCollezioneToExcelRaw(requestParameters: ExportCollezioneToExcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['collezioneId'] == null) {
            throw new runtime.RequiredError(
                'collezioneId',
                'Required parameter "collezioneId" was null or undefined when calling exportCollezioneToExcel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/export-document/{collezioneId}/excel`.replace(`{${"collezioneId"}}`, encodeURIComponent(String(requestParameters['collezioneId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Ha bisogno dell\'id della collezione
     * Estrae tutte le carte di una collezione e le mette in un Excel
     */
    async exportCollezioneToExcel(requestParameters: ExportCollezioneToExcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportCollezioneToExcelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ha bisogno dell\'id della collezione
     * Estrae tutte le carte di una collezione e le mette in un PDF
     */
    async exportCollezioneToPDFRaw(requestParameters: ExportCollezioneToPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['collezioneId'] == null) {
            throw new runtime.RequiredError(
                'collezioneId',
                'Required parameter "collezioneId" was null or undefined when calling exportCollezioneToPDF().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/export-document/{collezioneId}/pdf`.replace(`{${"collezioneId"}}`, encodeURIComponent(String(requestParameters['collezioneId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Ha bisogno dell\'id della collezione
     * Estrae tutte le carte di una collezione e le mette in un PDF
     */
    async exportCollezioneToPDF(requestParameters: ExportCollezioneToPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportCollezioneToPDFRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
