import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./HorizontalMenu.module.css";
import logo from "../../assets/logo.png";
import onePieceLogo from "../../assets/one-piece_logo.png";
import dragonBallLogo from "../../assets/logo_dragonball.png";
import pokemonLogo from "../../assets/pokemon_logo.png";
import lorcanaLogo from "../../assets/lorcana_logo.png";
import {
    Button,
    Menu,
    MenuItem,
    Tooltip,
    IconButton,
} from "@mui/material";
import {FaBell, FaUser, FaSearch, FaChartLine, FaCircle, FaUserPlus, FaSignInAlt, FaBriefcase} from "react-icons/fa";
import { ExpandLess, ExpandMore, People } from "@mui/icons-material";

const HorizontalMenu: React.FC = () => {
    const [gamesMenuOpen, setGamesMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [userId, setUserId] = useState("");
    const [user, setUser] = useState<any>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [profileMenuAnchor, setProfileMenuAnchor] = useState<null | HTMLElement>(null);

    const isActive = (path: string) => location.pathname === path;

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
        if (storedUser && storedUser.id) {
            setUser(storedUser);
            setUserId(storedUser.id);
        }
    }, []);

    useEffect(() => {
        setGamesMenuOpen(false);
    }, [location.pathname]);

    const handleGamesMenuToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        setGamesMenuOpen((prev) => !prev);
        setAnchorEl(event.currentTarget);
    };

    const handleGamesMenuClose = () => {
        setGamesMenuOpen(false);
        setAnchorEl(null);
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setProfileMenuAnchor(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuAnchor(null);
    };

    const handleLogout = () => {
        localStorage.removeItem("user");
        setUser(null);
        navigate("/login");
    };

    const handleProfileClick = () => {
        navigate(`/user-profile`);
        handleProfileMenuClose();
    };

    return (
        <div className={styles.container}>
            <div className={styles.topBar}>
                <Link to="/" className={styles.logo}>
                    <img src={logo} alt="Collectoon" />
                </Link>
                <div className={styles.iconsContainer}>
                    {user ? (
                        <>
                            <Tooltip title="Notifiche">
                                <IconButton className={styles.icon}>
                                    <FaBell />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="wishlist">
                                <a href="/wishlist-utente" className={styles.icon}>
                                    <FaBriefcase />
                                </a>
                            </Tooltip>
                            <Tooltip title="Profilo">
                                <IconButton
                                    className={styles.icon}
                                    onClick={handleProfileMenuOpen}
                                >
                                    <FaUser />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                anchorEl={profileMenuAnchor}
                                open={Boolean(profileMenuAnchor)}
                                onClose={handleProfileMenuClose}
                            >
                                <MenuItem onClick={handleProfileClick}>
                                    Il mio Profilo
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>

                        </>
                    ) : (
                        <div className={styles.authButtons}>
                            <Tooltip title="Login">
                                <Button
                                    component={Link}
                                    to="/login"
                                    className={styles.loginButton}
                                >
                                    <FaSignInAlt size={22}/>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Registrati">
                                <Button
                                    component={Link}
                                    to="/registrati"
                                    className={styles.registerButton}
                                >
                                    <FaUserPlus size={22}/>
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.verticalMenu}>
                <Button
                    onClick={handleGamesMenuToggle}
                    endIcon={gamesMenuOpen ? <ExpandLess /> : <ExpandMore />}
                    className={`${styles.menuItem} ${
                        isActive("/explore") ? styles.active : ""
                    }`}
                >
                    <FaSearch style={{ marginRight: "8px" }} />
                    Esplora i TCG
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={gamesMenuOpen}
                    onClose={handleGamesMenuClose}
                >
                    <MenuItem onClick={() => navigate("/cards/one_piece")}>
                        <img
                            src={onePieceLogo}
                            alt="One Piece"
                            className={styles.gameLogo}
                        />
                    </MenuItem>
                    {/*<MenuItem onClick={() => navigate("/cards/pokemon")}>*/}
                    {/*    <img*/}
                    {/*        src={pokemonLogo}*/}
                    {/*        alt="Pokémon"*/}
                    {/*        className={styles.gameLogo}*/}
                    {/*    />*/}
                    {/*</MenuItem>*/}
                    <MenuItem onClick={() => navigate("/cards/dragonball")}>
                        <img
                            src={dragonBallLogo}
                            alt="Dragon Ball"
                            className={styles.gameLogo}
                        />
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/cards/lorcana")}>
                        <img
                            src={lorcanaLogo}
                            alt="Lorcana"
                            className={styles.gameLogo}
                        />
                    </MenuItem>
                </Menu>

                {user && (
                    <>
                        <Button
                            component={Link}
                            to={`/my-collection/${userId}`}
                            className={`${styles.menuItem} ${
                                isActive(`/my-collection/${userId}`)
                                    ? styles.active
                                    : ""
                            }`}
                        >
                            <FaCircle style={{ marginRight: "8px" }} />
                            Le mie Collezioni
                        </Button>
                        <Button
                            component={Link}
                            to="/collections-user/public"
                            className={`${styles.menuItem} ${
                                isActive("/collections-user/public")
                                    ? styles.active
                                    : ""
                            }`}
                        >
                            <People style={{ marginRight: "8px" }} />
                            Vetrina Utenti
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default HorizontalMenu;
