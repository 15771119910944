import React from 'react';
import './HeaderImg.css';
import onePieceImg from '../../assets/one-piece_logo.png';
import pokemonImg from '../../assets/logo_pokemon.png';
import dragonballFsImg from '../../assets/logo_dragonball.png';
import lorcanaImg from '../../assets/lorcana_logo.png';


interface TCGHeaderProps {
    category: string;
}

const TCGHeader: React.FC<TCGHeaderProps> = ({ category }) => {
    const getImageForCategory = (category: string) => {
        switch (category.toLowerCase()) {
            case 'one_piece':
                return onePieceImg;
            case 'pokemon':
                return pokemonImg;
            case 'dragonball':
                return dragonballFsImg;
            case 'lorcana':
                return lorcanaImg;
        }
    };

    return (
        <div className="tcg-header">
            <img src={getImageForCategory(category)} alt={category} className="tcg-header-image" />
        </div>
    );
};

export default TCGHeader;
