import {CollezioneDTOTipoCardEnum} from "../api";
import {format} from "date-fns";

export function transformCardType(cardType: CollezioneDTOTipoCardEnum | undefined): string {

    if (!cardType) {
        return '';
    }

    const lowercase = cardType?.replace(/_/g, ' ').toLowerCase();
    return lowercase?.replace(/\b\w/g, l => l.toUpperCase());
}

export const getBlockerText = (descrizioneOp: string | null | undefined): string => {
    if (!descrizioneOp) {
        return '';
    }

    let updatedText = descrizioneOp;

    if (descrizioneOp.includes('<Blocker>')) {
        updatedText = updatedText.replace('<Blocker>', '<span class="blocker">Blocker</span>');
    }

    if (descrizioneOp.includes('[Blocker]')) {
        updatedText = updatedText.replace('[Blocker]', '<span class="blocker">Blocker</span>');
    }

    if (descrizioneOp.includes('[On K.O.]')) {
        updatedText = updatedText.replace('[On K.O.]', '<span class="on-ko">On K.O.</span>');
    }

    if (descrizioneOp.includes('[On Play]')) {
        updatedText = updatedText.replace('[On Play]', '<span class="on-ko">On Play</span>');
    }

    if (descrizioneOp.includes('[End of Your Turn]')) {
        updatedText = updatedText.replace('[End of Your Turn]', '<span class="on-ko">End of Your Turn</span>');
    }

    if (descrizioneOp.includes('[Main]')) {
        updatedText = updatedText.replace('[Main]', '<span class="on-ko">Main</span>');
    }

    if (descrizioneOp.includes('[Trigger]')) {
        updatedText = updatedText.replace('[Trigger]', '<span class="trigger">Trigger</span>');
    }

    if (descrizioneOp.includes('[Counter]')) {
        updatedText = updatedText.replace('[Counter]', '<span class="counter">Counter</span>');
    }

    if (descrizioneOp.includes('[Opponent\'s Turn]')) {
        updatedText = updatedText.replace('[Opponent\'s Turn]', '<span class="on-ko">Opponent\'s Turn</span>');
    }

    if (descrizioneOp.includes('[Activate Main]')) {
        updatedText = updatedText.replace('[Activate Main]', '<span class="on-ko">Activate Main</span>');
    }

    if (descrizioneOp.includes('[Once Per Turn]')) {
        updatedText = updatedText.replace('[Once Per Turn]', '<span class="once">Once Per Turn</span>');
    }

    if (descrizioneOp.includes('[Once per turn]')) {
        updatedText = updatedText.replace('[Once per turn]', '<span class="once">Once Per Turn</span>');
    }

    if (descrizioneOp.includes('[When Attacking]')) {
        updatedText = updatedText.replace('[When Attacking]', '<span class="on-ko">When Attacking</span>');
    }

    if (descrizioneOp.includes('[Rush]')) {
        updatedText = updatedText.replace('[Rush]', '<span class="blocker">Rush</span>');
    }

    if (descrizioneOp.includes('[Banish]')) {
        updatedText = updatedText.replace('[Banish]', '<span class="blocker">Banish</span>');
    }

    if (descrizioneOp.includes('[Your Turn]')) {
        updatedText = updatedText.replace('[Your Turn]', '<span class="on-ko">Your Turn</span>');
    }

    if (descrizioneOp.includes('[During Your Turn]')) {
        updatedText = updatedText.replace('[During Your Turn]', '<span class="on-ko">During Your Turn</span>');
    }

    const regex = /(\[DON!! x\d+\])/;
    //
    // if (regex.test(descrizioneOp)) {
    //     updatedText = updatedText.replace(regex, '<span class="don-number">DON!! x$1</span>');
    // }

    if (descrizioneOp.includes('[When attacking]')) {
        updatedText = updatedText.replace('[When attacking]', '<span class="on-ko">When attacking</span>');
    }

    if (descrizioneOp.includes('[On Block]')) {
        updatedText = updatedText.replace('[On Block]', '<span class="on-ko">On Block</span>');
    }

    if (descrizioneOp.includes('[Activate: Main]')) {
        updatedText = updatedText.replace('[Activate: Main]', '<span class="on-ko">Activate: Main</span>');
    }

    if (descrizioneOp.includes('[During your turn]')) {
        updatedText = updatedText.replace('[During your turn]', '<span class="on-ko">During Your Turn</span>');
    }

    return updatedText;
}

export const formatRetreatCost = (retreatCost: string[]): string => {
    const costCount: { [key: string]: number } = {};

    retreatCost.forEach(cost => {
        if (costCount[cost]) {
            costCount[cost]++;
        } else {
            costCount[cost] = 1;
        }
    });

    return Object.entries(costCount)
        .map(([cost, count]) => `x${count} ${cost}`)
        .join(' ');
};

export function formatDate(timestamp: [number, number, number, number, number, number, number] | null): string | null {
    if (timestamp === null) {
        return null;
    }
    const date = new Date(...timestamp);
    return format(date, 'dd/MM/yyyy HH:mm:ss');
}

export const logout = () => {
    localStorage.removeItem('user');
    window.location.href = '/login';
};
