import React from 'react';
import {Dialog, DialogContent, Grid, IconButton} from '@mui/material';
import {FaTimes} from 'react-icons/fa';
import styles from './../Dettagli-carta-onepiece/DettagliCarta.module.css';
import './../Dettagli-carta-onepiece/CardStyles.css';
import {formatRetreatCost} from "../../../utility/utils";
import {DettagliCartaPkmnProps} from "../../../utility/props";


const DettagliCartaOnePiece: React.FC<DettagliCartaPkmnProps> = ({
                                                                 open,
                                                                 onClose,
                                                                 name,
                                                                 image,
                                                                 supertype,
                                                                 subtypes,
                                                                 artist,
                                                                 types,
                                                                 retreatCost = [],
                                                                 hp,
                                                                 evolvesFrom,
                                                                 convertedRetreatCost,
                                                                 rarita,
                                                                 series,
                                                                 set,
                                                                 number,
                                                                 idCartaPokemon,
                                                                 listaText
                                                             }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{ classes: { root: styles.dialogPaper } }}
            BackdropProps={{ classes: { root: styles.dialogBackdrop } }}
        >
            <div className={styles.header}>
                <h2 className={styles.cardName}>{name} <em>({idCartaPokemon})</em></h2>
                <IconButton className={styles.closeButton} onClick={onClose}>
                    <FaTimes />
                </IconButton>
            </div>
            <DialogContent className={styles.dialogContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} className={styles.imageContainer}>
                        <img src={image} alt={name} className={styles.dialogImage} />
                    </Grid>
                    <Grid item xs={12} md={6} className={styles.detailsContainer}>
                        <table className={styles.detailsTable}>
                            <tbody>
                            <tr>
                                <th>Rarità</th>
                                <td>
                                    {rarita}
                                </td>
                            </tr>
                            <tr>
                                <th>HP</th>
                                <td>{hp}</td>
                            </tr>
                            <tr>
                                <th>Tipo</th>
                                <td>{types?.join(', ') || '-'}</td>
                            </tr>
                            <tr>
                                <th>Evolve Da</th>
                                <td>{evolvesFrom || '-'}</td>
                            </tr>
                            <tr>
                                <th>Costo di ritiro</th>
                                <td>{formatRetreatCost(retreatCost) || '-'}</td>
                            </tr>
                            <tr>
                                <th>Espansione</th>
                                <td>{set}</td>
                            </tr>
                            <tr>
                                <th>Artista</th>
                                <td>{artist}</td>
                            </tr>
                            <tr>
                                <th>Attacchi</th>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DettagliCartaOnePiece;
