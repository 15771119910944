/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CartaDTO,
  CollezioneDTO,
  ColorOpDTO,
  Pageable,
  Problem,
  RarityOpDTO,
  ResourceOPDTO,
  SearchRequest,
  TendenzaPrezzoDTO,
} from '../models/index';
import {
    CartaDTOFromJSON,
    CartaDTOToJSON,
    CollezioneDTOFromJSON,
    CollezioneDTOToJSON,
    ColorOpDTOFromJSON,
    ColorOpDTOToJSON,
    PageableFromJSON,
    PageableToJSON,
    ProblemFromJSON,
    ProblemToJSON,
    RarityOpDTOFromJSON,
    RarityOpDTOToJSON,
    ResourceOPDTOFromJSON,
    ResourceOPDTOToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
    TendenzaPrezzoDTOFromJSON,
    TendenzaPrezzoDTOToJSON,
} from '../models/index';

export interface FindAllRequest {
    pageable: Pageable;
}

export interface FindAllEspansioneRequest {
    cardType: string;
}

export interface FindByCriteria1Request {
    searchRequest: SearchRequest;
}

export interface FindByCriteriaOPCardRequest {
    searchRequest: SearchRequest;
}

export interface FindByCriteriaSpecificCardRequest {
    searchRequest: SearchRequest;
}

export interface FindPriceRequest {
    idCard: string;
    tipo: string;
    alternativeArt: string;
}

export interface GetByIdRequest {
    id: string;
}

export interface SearchColorsRequest {
    colori: Array<string>;
    pageable: Pageable;
}

/**
 * 
 */
export class CarteRestApi extends runtime.BaseAPI {

    /**
     * Verifica quante carte sono state importate alla piattaforma
     * conta tutte le carte del db
     */
    async countCardRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartaDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cards/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartaDTOFromJSON(jsonValue));
    }

    /**
     * Verifica quante carte sono state importate alla piattaforma
     * conta tutte le carte del db
     */
    async countCard(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartaDTO> {
        const response = await this.countCardRaw(initOverrides);
        return await response.value();
    }

    /**
     * non ha bisogno di criteria
     * Ricerca di tutte le carte sul db
     */
    async findAllRaw(requestParameters: FindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartaDTO>> {
        if (requestParameters['pageable'] == null) {
            throw new runtime.RequiredError(
                'pageable',
                'Required parameter "pageable" was null or undefined when calling findAll().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cards/search/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartaDTOFromJSON(jsonValue));
    }

    /**
     * non ha bisogno di criteria
     * Ricerca di tutte le carte sul db
     */
    async findAll(requestParameters: FindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartaDTO> {
        const response = await this.findAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * non ha bisogno di criteria
     * Ricerca di tutte le carte sul db
     */
    async findAll1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartaDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cards/search/all/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartaDTOFromJSON(jsonValue));
    }

    /**
     * non ha bisogno di criteria
     * Ricerca di tutte le carte sul db
     */
    async findAll1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartaDTO> {
        const response = await this.findAll1Raw(initOverrides);
        return await response.value();
    }

    /**
     * non ha bisogno di criteria
     * Ricerca di tutti i colorim di carte One Piece
     */
    async findAllColorRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ColorOpDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cards/search/color`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ColorOpDTOFromJSON(jsonValue));
    }

    /**
     * non ha bisogno di criteria
     * Ricerca di tutti i colorim di carte One Piece
     */
    async findAllColor(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColorOpDTO> {
        const response = await this.findAllColorRaw(initOverrides);
        return await response.value();
    }

    /**
     * non ha bisogno di criteria
     * Ricerca di tutte le espansioni di One Piece presenti sul db
     */
    async findAllEspansioneRaw(requestParameters: FindAllEspansioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceOPDTO>> {
        if (requestParameters['cardType'] == null) {
            throw new runtime.RequiredError(
                'cardType',
                'Required parameter "cardType" was null or undefined when calling findAllEspansione().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['cardType'] != null) {
            queryParameters['cardType'] = requestParameters['cardType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cards/search/espansione`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceOPDTOFromJSON(jsonValue));
    }

    /**
     * non ha bisogno di criteria
     * Ricerca di tutte le espansioni di One Piece presenti sul db
     */
    async findAllEspansione(requestParameters: FindAllEspansioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceOPDTO> {
        const response = await this.findAllEspansioneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * non ha bisogno di criteria
     * Ricerca di tutte le rarità di carte One Piece
     */
    async findAllRarityRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RarityOpDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cards/search/rarity`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RarityOpDTOFromJSON(jsonValue));
    }

    /**
     * non ha bisogno di criteria
     * Ricerca di tutte le rarità di carte One Piece
     */
    async findAllRarity(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RarityOpDTO> {
        const response = await this.findAllRarityRaw(initOverrides);
        return await response.value();
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di tutte le carte sul db
     */
    async findByCriteria1Raw(requestParameters: FindByCriteria1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartaDTO>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling findByCriteria1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/cards/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartaDTOFromJSON(jsonValue));
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di tutte le carte sul db
     */
    async findByCriteria1(requestParameters: FindByCriteria1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartaDTO> {
        const response = await this.findByCriteria1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di tutte le carte di tipo ONE PIECE sul db
     */
    async findByCriteriaOPCardRaw(requestParameters: FindByCriteriaOPCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartaDTO>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling findByCriteriaOPCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/cards/one-piece/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartaDTOFromJSON(jsonValue));
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di tutte le carte di tipo ONE PIECE sul db
     */
    async findByCriteriaOPCard(requestParameters: FindByCriteriaOPCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartaDTO> {
        const response = await this.findByCriteriaOPCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di tutte le carte sul db in base ai criteri
     */
    async findByCriteriaSpecificCardRaw(requestParameters: FindByCriteriaSpecificCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartaDTO>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling findByCriteriaSpecificCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/cards/search-specific`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartaDTOFromJSON(jsonValue));
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di tutte le carte sul db in base ai criteri
     */
    async findByCriteriaSpecificCard(requestParameters: FindByCriteriaSpecificCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartaDTO> {
        const response = await this.findByCriteriaSpecificCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di tutte le carte sul db in base ai criteri
     */
    async findPriceRaw(requestParameters: FindPriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TendenzaPrezzoDTO>> {
        if (requestParameters['idCard'] == null) {
            throw new runtime.RequiredError(
                'idCard',
                'Required parameter "idCard" was null or undefined when calling findPrice().'
            );
        }

        if (requestParameters['tipo'] == null) {
            throw new runtime.RequiredError(
                'tipo',
                'Required parameter "tipo" was null or undefined when calling findPrice().'
            );
        }

        if (requestParameters['alternativeArt'] == null) {
            throw new runtime.RequiredError(
                'alternativeArt',
                'Required parameter "alternativeArt" was null or undefined when calling findPrice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['idCard'] != null) {
            queryParameters['idCard'] = requestParameters['idCard'];
        }

        if (requestParameters['tipo'] != null) {
            queryParameters['tipo'] = requestParameters['tipo'];
        }

        if (requestParameters['alternativeArt'] != null) {
            queryParameters['alternativeArt'] = requestParameters['alternativeArt'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cards/search-price`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TendenzaPrezzoDTOFromJSON(jsonValue));
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di tutte le carte sul db in base ai criteri
     */
    async findPrice(requestParameters: FindPriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TendenzaPrezzoDTO> {
        const response = await this.findPriceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di una carta sul db
     */
    async getByIdRaw(requestParameters: GetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartaDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartaDTOFromJSON(jsonValue));
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di una carta sul db
     */
    async getById(requestParameters: GetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartaDTO> {
        const response = await this.getByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * L\'inserimento richiede obbligatoriamente il nome e il tipo di card
     * inserimento di una collezione
     */
    async getTop10CardRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cards/top-10`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * L\'inserimento richiede obbligatoriamente il nome e il tipo di card
     * inserimento di una collezione
     */
    async getTop10Card(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.getTop10CardRaw(initOverrides);
        return await response.value();
    }

    /**
     * non ha bisogno di criteria
     * Ricerca di tutti i colorim di carte One Piece
     */
    async searchColorsRaw(requestParameters: SearchColorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceOPDTO>> {
        if (requestParameters['colori'] == null) {
            throw new runtime.RequiredError(
                'colori',
                'Required parameter "colori" was null or undefined when calling searchColors().'
            );
        }

        if (requestParameters['pageable'] == null) {
            throw new runtime.RequiredError(
                'pageable',
                'Required parameter "pageable" was null or undefined when calling searchColors().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cards/colors/{colori}`.replace(`{${"colori"}}`, encodeURIComponent(String(requestParameters['colori']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceOPDTOFromJSON(jsonValue));
    }

    /**
     * non ha bisogno di criteria
     * Ricerca di tutti i colorim di carte One Piece
     */
    async searchColors(requestParameters: SearchColorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceOPDTO> {
        const response = await this.searchColorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * L\'inserimento non richiede nulla
     * Import prezzi carte
     */
    async setPrezzoCarteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cards/prezzo-value`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * L\'inserimento non richiede nulla
     * Import prezzi carte
     */
    async setPrezzoCarte(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.setPrezzoCarteRaw(initOverrides);
        return await response.value();
    }

}
