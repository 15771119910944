import React, {useEffect} from 'react';
import {Box, Button, Card, CardContent, CardMedia, Container, Divider, Grid, Typography} from '@mui/material';
import {motion} from 'framer-motion';
import '../styles/Home.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Navigation} from 'swiper/modules';
import {fetchRecentCollection} from "../store/collezioni/CollezioniSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {transformCardType} from "../utility/utils";

const Home = () => {
    const recentCollections = useSelector((state: RootState) => state.collezioni.collezioni);
    const loading = useSelector((state: RootState) => state.collezioni.loading);
    const error = useSelector((state: RootState) => state.collezioni.error);
    const dispatch = useDispatch<AppDispatch>();

    const cardData = [
        { title: 'Dragonball Z', image: 'https://link-to-image.jpg', description: 'Colleziona le carte più potenti di Dragonball!' },
        { title: 'One Piece', image: 'https://link-to-image.jpg', description: 'Diventa il re dei pirati con One Piece!' },
        { title: 'Yu-Gi-Oh', image: 'https://link-to-image.jpg', description: 'Sfida altri duelisti con Yu-Gi-Oh!' },
    ];

    // useEffect(() => {
    //     dispatch(fetchRecentCollection());
    // }, [dispatch]);


    return (
        <div style={{backgroundColor: '#fafafa', minHeight: '100vh', paddingBottom: '50px'}}>
            <header style={{
                backgroundColor: '#3f51b5',
                color: 'white',
                padding: '80px 0',
                textAlign: 'center',
                marginBottom: '40px',
                borderRadius: '10px',
                position: 'relative',
                overflow: 'hidden',
                boxShadow: '0 15px 30px rgba(0,0,0,0.2)'
            }}>
                <Typography style={{
                    fontSize: '4rem',
                    fontWeight: 'bold',
                    marginBottom: '20px',
                    letterSpacing: '2px',
                    fontFamily: '"Montserrat", sans-serif'
                }}>Benvenuto su Collectoon!</Typography>
                <Typography style={{
                    fontSize: '1.5rem',
                    fontWeight: '300',
                    marginBottom: '20px',
                    letterSpacing: '1px',
                    fontFamily: '"Nunito", sans-serif'
                }}>
                    Crea le tue collezioni digitali dai TCG più famosi al mondo.
                </Typography>
                <Button variant="contained"
                        sx={{
                            marginTop: '20px',
                            backgroundColor: '#ff4081',
                            color: 'white',
                            fontWeight: '600',
                            borderRadius: '50px',
                            padding: '10px 30px',
                            '&:hover': {
                                backgroundColor: '#e91e63',
                            },
                        }}>
                    Inizia a Collezionare
                </Button>
            </header>

            <Container style={{marginTop: '60px'}}>
                <Typography variant="h4" align="center" gutterBottom>
                    Le collezioni più popolari
                </Typography>
                <Grid container spacing={3} justifyContent="center">
                    {cardData.map((card, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4}>
                            <motion.div
                                initial={{opacity: 0, y: 50}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: index * 0.2, type: 'spring', stiffness: 200}}
                            >
                                <Card
                                    sx={{
                                        maxWidth: '345px',
                                        margin: '20px',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
                                        },
                                    }}>
                                    <CardMedia
                                        style={{height: 180, objectFit: 'cover'}}
                                        image={card.image}
                                        title={card.title}
                                    />
                                    <CardContent>
                                        <Typography variant="h6" style={{
                                            fontSize: '1.25rem',
                                            fontWeight: 'bold'
                                        }}>{card.title}</Typography>
                                        <Typography variant="body2" style={{fontSize: '1rem', color: '#757575'}}>
                                            {card.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
                <Box display="flex" justifyContent="center" marginTop="20px">
                    <Button variant="contained"
                            sx={{
                                marginTop: '20px',
                                backgroundColor: '#ff4081',
                                color: 'white',
                                fontWeight: '600',
                                borderRadius: '50px',
                                padding: '10px 30px',
                                '&:hover': {
                                    backgroundColor: '#e91e63',
                                },
                            }}>
                        Esplora altre collezioni
                    </Button>
                </Box>
            </Container>
            <Divider orientation="vertical" flexItem style={{margin: '0 8px'}}/>

            <Container style={{ marginTop: '60px' }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Le Ultime Collezioni Create
                </Typography>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={3}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    modules={[Navigation]}
                    navigation={true}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {recentCollections.map((collection, index) => (
                        <SwiperSlide key={index}>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: index * 0.2, type: 'spring', stiffness: 200 }}
                                style={{
                                    background: '#fff',
                                    padding: '20px',
                                    borderRadius: '10px',
                                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Typography variant="h6" align="center" style={{ marginTop: '10px' }}>
                                    {collection.nome}
                                </Typography>
                                <Typography variant="body2" align="center" style={{ color: '#757575' }}>
                                    {transformCardType(collection.tipoCard)}
                                </Typography>
                            </motion.div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Container>

            <div style={{
                marginTop: '60px',
                textAlign: 'center',
                padding: '50px 20px',
                backgroundColor: '#3f51b5',
                borderRadius: '10px',
                boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                color: 'white'
            }}>
                <Typography style={{
                    fontSize: '3rem',
                    fontWeight: '600',
                    marginBottom: '20px',
                    fontFamily: '"Montserrat", sans-serif'
                }}>Dai Più Valore alla tua Collezione!</Typography>
                <Typography style={{
                    fontSize: '1.2rem',
                    fontWeight: '400',
                    maxWidth: '800px',
                    margin: '0 auto',
                    lineHeight: '1.7',
                    color: 'white',
                    fontFamily: '"Nunito", sans-serif',
                    fontStyle: 'talic'
                }}>
                    Aggiungi carte uniche alle tue collezioni, monitora i trend di mercato per scoprire il valore delle
                    tue carte, e interagisci con una community appassionata attraverso commenti e feedback.
                </Typography>
            </div>

        </div>
    );
};

export default Home;
