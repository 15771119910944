/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TendenzaDTO } from './TendenzaDTO';
import {
    TendenzaDTOFromJSON,
    TendenzaDTOFromJSONTyped,
    TendenzaDTOToJSON,
} from './TendenzaDTO';

/**
 * 
 * @export
 * @interface TendenzaPrezzoDTO
 */
export interface TendenzaPrezzoDTO {
    /**
     * 
     * @type {string}
     * @memberof TendenzaPrezzoDTO
     */
    idCarta?: string;
    /**
     * 
     * @type {Date}
     * @memberof TendenzaPrezzoDTO
     */
    dataInserimento?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TendenzaPrezzoDTO
     */
    dataUltimoAgg?: Date;
    /**
     * 
     * @type {Array<TendenzaDTO>}
     * @memberof TendenzaPrezzoDTO
     */
    tendenzaPrezzo?: Array<TendenzaDTO>;
}

/**
 * Check if a given object implements the TendenzaPrezzoDTO interface.
 */
export function instanceOfTendenzaPrezzoDTO(value: object): value is TendenzaPrezzoDTO {
    return true;
}

export function TendenzaPrezzoDTOFromJSON(json: any): TendenzaPrezzoDTO {
    return TendenzaPrezzoDTOFromJSONTyped(json, false);
}

export function TendenzaPrezzoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TendenzaPrezzoDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'idCarta': json['idCarta'] == null ? undefined : json['idCarta'],
        'dataInserimento': json['dataInserimento'] == null ? undefined : (new Date(json['dataInserimento'])),
        'dataUltimoAgg': json['dataUltimoAgg'] == null ? undefined : (new Date(json['dataUltimoAgg'])),
        'tendenzaPrezzo': json['tendenzaPrezzo'] == null ? undefined : ((json['tendenzaPrezzo'] as Array<any>).map(TendenzaDTOFromJSON)),
    };
}

export function TendenzaPrezzoDTOToJSON(value?: TendenzaPrezzoDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idCarta': value['idCarta'],
        'dataInserimento': value['dataInserimento'] == null ? undefined : ((value['dataInserimento']).toISOString().substring(0,10)),
        'dataUltimoAgg': value['dataUltimoAgg'] == null ? undefined : ((value['dataUltimoAgg']).toISOString().substring(0,10)),
        'tendenzaPrezzo': value['tendenzaPrezzo'] == null ? undefined : ((value['tendenzaPrezzo'] as Array<any>).map(TendenzaDTOToJSON)),
    };
}

