import React, {useState} from 'react';
import Tooltip from '@mui/material/Tooltip';
import {FaEye, FaHeart, FaPlus} from 'react-icons/fa';
import styles from './../SingolaCartaPokemon/CardPkmnComponent.module.css';
import DettagliCartaPokemon from "../../Dettagli-carta-Pokemon/dettagli-carta-pokemon";

interface CardComponentProps {
    name: string;
    image: string;
    supertype?: string;
    subtypes?: string;
    artist?: string;
    types?: Array<string>;
    retreatCost?: Array<string>;
    hp?: string;
    evolvesFrom?: string;
    convertedRetreatCost?: string;
    rarita: string;
    number?: string;
    series?: string;
    set?: string;
    idCartaPokemon?: string;
    listaText?: Array<string>;
}

const CardPokemonComponent: React.FC<CardComponentProps> = ({
                                                                name,
                                                                image,
                                                                supertype,
                                                                subtypes,
                                                                artist,
                                                                types,
                                                                retreatCost,
                                                                hp,
                                                                evolvesFrom,
                                                                convertedRetreatCost,
                                                                rarita,
                                                                series,
                                                                set,
                                                                number,
                                                                idCartaPokemon,
                                                                listaText
                                                            }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={styles.card}>
            <div className={styles.cardImageContainer}>
                <img src={image} alt={name} className={styles.cardImage}/>
                <div className={styles.symbolsContainer}>
                    <Tooltip title="Aggiungi Alla collezione">
                        <div className={styles.symbol}>
                            <FaPlus/>
                        </div>
                    </Tooltip>
                    <Tooltip title="Aggiungi alla WishList">
                        <div className={styles.symbol}>
                            <FaHeart/>
                        </div>
                    </Tooltip>
                    <Tooltip title="Visualizza">
                        <div className={styles.symbol} onClick={handleClickOpen}>
                            <FaEye/>
                        </div>
                    </Tooltip>
                </div>
            </div>
            <div className={styles.cardDetails}>
                <h3 className={styles.cardTitle}>{name} (<em>{idCartaPokemon}</em>)</h3>
            </div>
            <DettagliCartaPokemon
                open={open}
                onClose={handleClose}
                name={name}
                image={image}
                rarita={rarita}
                supertype={supertype}
                artist={artist}
                retreatCost={retreatCost}
                hp={hp}
                evolvesFrom={evolvesFrom}
                series={series}
                idCartaPokemon={idCartaPokemon}
                types={types}
                set={set}
            />
        </div>
    );
};

export default CardPokemonComponent;
