/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AIAuthenticationTokenDTO,
  CambiaPasswordDTO,
  IndirizzoDTO,
  LoginRequestDTO,
  Pageable,
  Problem,
  ResponseMessageDTO,
  UtenteDTO,
  UtenteInsertDTO,
} from '../models/index';
import {
    AIAuthenticationTokenDTOFromJSON,
    AIAuthenticationTokenDTOToJSON,
    CambiaPasswordDTOFromJSON,
    CambiaPasswordDTOToJSON,
    IndirizzoDTOFromJSON,
    IndirizzoDTOToJSON,
    LoginRequestDTOFromJSON,
    LoginRequestDTOToJSON,
    PageableFromJSON,
    PageableToJSON,
    ProblemFromJSON,
    ProblemToJSON,
    ResponseMessageDTOFromJSON,
    ResponseMessageDTOToJSON,
    UtenteDTOFromJSON,
    UtenteDTOToJSON,
    UtenteInsertDTOFromJSON,
    UtenteInsertDTOToJSON,
} from '../models/index';

export interface AddPointsRequest {
    userId: string;
    points: number;
}

export interface ChangePasswordRequest {
    token: string;
    cambiaPasswordDTO: CambiaPasswordDTO;
}

export interface CheckForBadgeRequest {
    userId: string;
}

export interface ConfirmUserAccountRequest {
    token: string;
}

export interface CreateAddressRequest {
    userId: string;
    indirizzoDTO: IndirizzoDTO;
}

export interface CreateUserRequest {
    utenteInsertDTO: UtenteInsertDTO;
}

export interface GetAllUtentiRequest {
    pageable: Pageable;
}

export interface GetAutenticationCodeRequest {
    userId: string;
}

export interface GetIndirizzoPrincipaleRequest {
    userId: string;
}

export interface GetUserByIdRequest {
    idUser: string;
}

export interface LoginRequest {
    loginRequestDTO: LoginRequestDTO;
}

export interface SendEmailPasswordRequest {
    usernameUtente: string;
}

export interface SessioneUtenteRequest {
    session: string;
}

export interface VerifyAIAuthenticationCodeRequest {
    userId: string;
}

/**
 * 
 */
export class UtenteRestApi extends runtime.BaseAPI {

    /**
     * Richiede obbligatoriamente il punteggio da assegnare
     * Assegna un punteggio all\'utente
     */
    async addPointsRaw(requestParameters: AddPointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UtenteDTO>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling addPoints().'
            );
        }

        if (requestParameters['points'] == null) {
            throw new runtime.RequiredError(
                'points',
                'Required parameter "points" was null or undefined when calling addPoints().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente/{userId}/add-points/{points}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"points"}}`, encodeURIComponent(String(requestParameters['points']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UtenteDTOFromJSON(jsonValue));
    }

    /**
     * Richiede obbligatoriamente il punteggio da assegnare
     * Assegna un punteggio all\'utente
     */
    async addPoints(requestParameters: AddPointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UtenteDTO> {
        const response = await this.addPointsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Richiede obbligatoriamente la password, l\'username
     * Cambia Password
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CambiaPasswordDTO>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling changePassword().'
            );
        }

        if (requestParameters['cambiaPasswordDTO'] == null) {
            throw new runtime.RequiredError(
                'cambiaPasswordDTO',
                'Required parameter "cambiaPasswordDTO" was null or undefined when calling changePassword().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['token'] != null) {
            queryParameters['token'] = requestParameters['token'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/utente/cambia-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CambiaPasswordDTOToJSON(requestParameters['cambiaPasswordDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CambiaPasswordDTOFromJSON(jsonValue));
    }

    /**
     * Richiede obbligatoriamente la password, l\'username
     * Cambia Password
     */
    async changePassword(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CambiaPasswordDTO> {
        const response = await this.changePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Assegna un Badge all\'utente in base al suo punteggio
     */
    async checkForBadgeRaw(requestParameters: CheckForBadgeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UtenteDTO>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling checkForBadge().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente/{userId}/badge`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UtenteDTOFromJSON(jsonValue));
    }

    /**
     * Assegna un Badge all\'utente in base al suo punteggio
     */
    async checkForBadge(requestParameters: CheckForBadgeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UtenteDTO> {
        const response = await this.checkForBadgeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Conferma email per attivare account
     */
    async confirmUserAccountRaw(requestParameters: ConfirmUserAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseMessageDTO>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling confirmUserAccount().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['token'] != null) {
            queryParameters['token'] = requestParameters['token'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente/conferma-account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseMessageDTOFromJSON(jsonValue));
    }

    /**
     * Conferma email per attivare account
     */
    async confirmUserAccount(requestParameters: ConfirmUserAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseMessageDTO> {
        const response = await this.confirmUserAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verifica quanti utenti sono iscritti alla piattaforma
     * conta tutti gli utenti del db
     */
    async countUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UtenteDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UtenteDTOFromJSON(jsonValue));
    }

    /**
     * Verifica quanti utenti sono iscritti alla piattaforma
     * conta tutti gli utenti del db
     */
    async countUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UtenteDTO> {
        const response = await this.countUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * crea un nuovo indirizzo per l\'utente
     */
    async createAddressRaw(requestParameters: CreateAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IndirizzoDTO>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling createAddress().'
            );
        }

        if (requestParameters['indirizzoDTO'] == null) {
            throw new runtime.RequiredError(
                'indirizzoDTO',
                'Required parameter "indirizzoDTO" was null or undefined when calling createAddress().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/utente/update-address/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IndirizzoDTOToJSON(requestParameters['indirizzoDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IndirizzoDTOFromJSON(jsonValue));
    }

    /**
     * crea un nuovo indirizzo per l\'utente
     */
    async createAddress(requestParameters: CreateAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IndirizzoDTO> {
        const response = await this.createAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Richiede obbligatoriamente la password, l\'username, la mail, il nome e il cognome
     * crea un nuovo utente
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UtenteDTO>> {
        if (requestParameters['utenteInsertDTO'] == null) {
            throw new runtime.RequiredError(
                'utenteInsertDTO',
                'Required parameter "utenteInsertDTO" was null or undefined when calling createUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/utente`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UtenteInsertDTOToJSON(requestParameters['utenteInsertDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UtenteDTOFromJSON(jsonValue));
    }

    /**
     * Richiede obbligatoriamente la password, l\'username, la mail, il nome e il cognome
     * crea un nuovo utente
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UtenteDTO> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * mostra tutti gli utenti presenti sul db
     */
    async getAllUtentiRaw(requestParameters: GetAllUtentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UtenteDTO>> {
        if (requestParameters['pageable'] == null) {
            throw new runtime.RequiredError(
                'pageable',
                'Required parameter "pageable" was null or undefined when calling getAllUtenti().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UtenteDTOFromJSON(jsonValue));
    }

    /**
     * mostra tutti gli utenti presenti sul db
     */
    async getAllUtenti(requestParameters: GetAllUtentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UtenteDTO> {
        const response = await this.getAllUtentiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generazione di un codice univoco da un ora per l\'utente per poter usare l\'AI
     * Generazione token per AI
     */
    async getAutenticationCodeRaw(requestParameters: GetAutenticationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AIAuthenticationTokenDTO>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getAutenticationCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente/autentication/card/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIAuthenticationTokenDTOFromJSON(jsonValue));
    }

    /**
     * Generazione di un codice univoco da un ora per l\'utente per poter usare l\'AI
     * Generazione token per AI
     */
    async getAutenticationCode(requestParameters: GetAutenticationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AIAuthenticationTokenDTO> {
        const response = await this.getAutenticationCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Bisogna passargli l\'id dell\'utente
     * Cerca un indirizzo principale per l\'utente
     */
    async getIndirizzoPrincipaleRaw(requestParameters: GetIndirizzoPrincipaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IndirizzoDTO>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getIndirizzoPrincipale().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente/indirizzo/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IndirizzoDTOFromJSON(jsonValue));
    }

    /**
     * Bisogna passargli l\'id dell\'utente
     * Cerca un indirizzo principale per l\'utente
     */
    async getIndirizzoPrincipale(requestParameters: GetIndirizzoPrincipaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IndirizzoDTO> {
        const response = await this.getIndirizzoPrincipaleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verifica se un utente è loggato
     */
    async getLoggedUserIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UtenteDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente/loggedUser/id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UtenteDTOFromJSON(jsonValue));
    }

    /**
     * Verifica se un utente è loggato
     */
    async getLoggedUserId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UtenteDTO> {
        const response = await this.getLoggedUserIdRaw(initOverrides);
        return await response.value();
    }

    /**
     * Bisogna passargli l\'id dell\'utente
     * Verifica login utente
     */
    async getUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IndirizzoDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente/verify/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IndirizzoDTOFromJSON(jsonValue));
    }

    /**
     * Bisogna passargli l\'id dell\'utente
     * Verifica login utente
     */
    async getUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IndirizzoDTO> {
        const response = await this.getUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * cerca un utente per id
     */
    async getUserByIdRaw(requestParameters: GetUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UtenteDTO>> {
        if (requestParameters['idUser'] == null) {
            throw new runtime.RequiredError(
                'idUser',
                'Required parameter "idUser" was null or undefined when calling getUserById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente/{idUser}`.replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters['idUser']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UtenteDTOFromJSON(jsonValue));
    }

    /**
     * cerca un utente per id
     */
    async getUserById(requestParameters: GetUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UtenteDTO> {
        const response = await this.getUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Richiede obbligatoriamente la password, l\'username
     * effettua il login
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UtenteDTO>> {
        if (requestParameters['loginRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'loginRequestDTO',
                'Required parameter "loginRequestDTO" was null or undefined when calling login().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/utente/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestDTOToJSON(requestParameters['loginRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UtenteDTOFromJSON(jsonValue));
    }

    /**
     * Richiede obbligatoriamente la password, l\'username
     * effettua il login
     */
    async login(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UtenteDTO> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logout dell\'utente
     */
    async logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UtenteDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UtenteDTOFromJSON(jsonValue));
    }

    /**
     * Logout dell\'utente
     */
    async logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UtenteDTO> {
        const response = await this.logoutRaw(initOverrides);
        return await response.value();
    }

    /**
     * Richiede obbligatoriamente la password, l\'username
     * Cambia Password
     */
    async sendEmailPasswordRaw(requestParameters: SendEmailPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CambiaPasswordDTO>> {
        if (requestParameters['usernameUtente'] == null) {
            throw new runtime.RequiredError(
                'usernameUtente',
                'Required parameter "usernameUtente" was null or undefined when calling sendEmailPassword().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['usernameUtente'] != null) {
            queryParameters['usernameUtente'] = requestParameters['usernameUtente'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente/cambia-password-mail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CambiaPasswordDTOFromJSON(jsonValue));
    }

    /**
     * Richiede obbligatoriamente la password, l\'username
     * Cambia Password
     */
    async sendEmailPassword(requestParameters: SendEmailPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CambiaPasswordDTO> {
        const response = await this.sendEmailPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Controlla la sessione dell\'utente
     */
    async sessioneUtenteRaw(requestParameters: SessioneUtenteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UtenteDTO>> {
        if (requestParameters['session'] == null) {
            throw new runtime.RequiredError(
                'session',
                'Required parameter "session" was null or undefined when calling sessioneUtente().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['session'] != null) {
            queryParameters['session'] = requestParameters['session'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente/sessione-utente`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UtenteDTOFromJSON(jsonValue));
    }

    /**
     * Controlla la sessione dell\'utente
     */
    async sessioneUtente(requestParameters: SessioneUtenteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UtenteDTO> {
        const response = await this.sessioneUtenteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Bisogna passargli l\'id dell\'utente
     * Cerca un token valido per l\'utente
     */
    async verifyAIAuthenticationCodeRaw(requestParameters: VerifyAIAuthenticationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AIAuthenticationTokenDTO>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling verifyAIAuthenticationCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/utente/verify/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIAuthenticationTokenDTOFromJSON(jsonValue));
    }

    /**
     * Bisogna passargli l\'id dell\'utente
     * Cerca un token valido per l\'utente
     */
    async verifyAIAuthenticationCode(requestParameters: VerifyAIAuthenticationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AIAuthenticationTokenDTO> {
        const response = await this.verifyAIAuthenticationCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
