import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    CircularProgress,
    createTheme, MenuItem,
    TextField,
    ThemeProvider,
} from '@mui/material';
import {CarteRestApi, ColorOpDTO, FindAllEspansioneRequest, RarityOpDTO, ResourceOPDTO} from '../../../api';
import {styled} from '@mui/system';
import styles from'../Filtri-ricerca/FilterAndSort.module.css';
import {menuItemStyles} from "./FiltriHandler";

const api = new CarteRestApi();

interface FilterAndSortProps {
    onFilter: (filter: any) => void;
    isCollection: boolean;
    tipoCard?: any;
    initialFilters?: {
        sort?: string;
        name?: string;
        sourceName?: string;
        rarity?: string;
        colore?: string;
    };
    cards?: any[] | null;
}




const RicercaSpan = styled('span')`
    font-size: 23px;
    font-weight: bold;
    color: #3f51b5;
    padding: 8px 12px;
    margin-bottom: 20px;
    font-family: "Nunito", sans-serif;
`;

const FilterAndSort: React.FC<FilterAndSortProps> = ({
                                                         onFilter,
                                                         isCollection,
                                                         tipoCard,
                                                         initialFilters = {},
                                                         cards,
                                                     }) => {
    const [name, setName] = useState(initialFilters.name || '');
    const [selectedExpansion, setSelectedExpansion] = useState<ResourceOPDTO | null>(null);
    const [selectedRarity, setSelectedRarity] = useState(initialFilters.rarity || '');
    const [selectedColor, setSelectedColor] = useState(initialFilters.colore || '');
    const [loading, setLoading] = useState(false);
    const [expansions, setExpansions] = useState<ResourceOPDTO[]>([]);
    const [filteredExpansions, setFilteredExpansions] = useState<ResourceOPDTO[]>([]);
    const [rarity, setRarity] = useState<RarityOpDTO[]>([]);
    const [color, setColor] = useState<ColorOpDTO[]>([]);
    const [selectedSort, setSelectedSort] = useState(initialFilters.sort || '');
    const hasNumeroEspansione = cards?.some((card) => card.numeroEspansione);
    const sortOptions = [
        { label: 'Più Economico', value: 'prezzo,asc' },
        { label: 'Più Caro', value: 'prezzo,desc' },
        { label: 'Nome (A-Z)', value: 'name,asc' },
        { label: 'Nome (Z-A)', value: 'name,desc' },
        // { label: 'Rarità (Prima il più raro)', value: 'rarity,desc' },
        // { label: 'Rarità (Prima il più comune)', value: 'rarity,asc' },
        {
            label: 'Per numero Espansione Decrescente',
            value: hasNumeroEspansione ? 'numeroEspansione,desc' : 'idCard,desc',
        },
        {
            label: 'Per numero Espansione Crescente',
            value: hasNumeroEspansione ? 'numeroEspansione,asc' : 'idCard,asc',
        },
    ];



    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (tipoCard != null) {
                    const request: FindAllEspansioneRequest = { cardType: tipoCard };
                    const [expResponse, rarResponse, colResponse] = await Promise.all([
                        api.findAllEspansioneRaw(request),
                        api.findAllRarityRaw(),
                        api.findAllColorRaw(),
                    ]);

                    const [expJson, rarJson, colJson] = await Promise.all([
                        expResponse.raw.json(),
                        rarResponse.raw.json(),
                        colResponse.raw.json(),
                    ]);

                    setExpansions(expJson || []);
                    setRarity(rarJson || []);
                    setColor(colJson || []);
                }
            } catch (error) {
                console.error('Errore durante il recupero dei dati:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [tipoCard]);

    const handleExpansionSearch = (query: string) => {
        if (query.trim() === '') {
            setFilteredExpansions([]);
        } else if (query.length >= 3) {
            const filtered = expansions.filter((exp) =>
                exp.nome?.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredExpansions(filtered);
        }
    };

    const handleFilter = () => {
        const criteria = {
            name: name ? { contains: name } : undefined,
            sourceName: selectedExpansion ? { equals: selectedExpansion.nome } : undefined,
            rarity: selectedRarity ? { equals: selectedRarity } : undefined,
            colore: selectedColor ? { equals: selectedColor } : undefined,
        };

        onFilter({ criteria, sort: selectedSort });
    };

    const handleReset = () => {
        setName('');
        setSelectedExpansion(null);
        setSelectedRarity('');
        setSelectedColor('');
        setSelectedSort('');
        onFilter({ criteria: {}, sort: '' });
    };

    return (
        <ThemeProvider theme={createTheme()}>
            <Card
                sx={{
                    padding: '23px',
                    margin: '20px',
                    borderRadius: '50px',
                    background: 'linear-gradient(135deg, #f9f9f9, #eaeaea)',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                }}
            >
                <CardContent>
                    <RicercaSpan>Ricerca</RicercaSpan>
                    <div>
                        <TextField
                            label="Cerca"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <Autocomplete
                            options={filteredExpansions}
                            getOptionLabel={(option) => option.nome || ''}
                            value={selectedExpansion}
                            onChange={(event, newValue) => setSelectedExpansion(newValue)}
                            noOptionsText="Digita i primi tre caratteri per cercare."
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Espansione"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => handleExpansionSearch(e.target.value)}
                                />
                            )}
                        />
                        <TextField
                            select
                            label="Rarità"
                            value={selectedRarity}
                            onChange={(e) => setSelectedRarity(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            className={styles.textField}
                        >
                            {rarity.map((rar) => (
                                <MenuItem key={rar.id} value={rar.nome} sx={menuItemStyles}>
                                    {rar.nome}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            select
                            label="Colore"
                            value={selectedColor}
                            onChange={(e) => setSelectedColor(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        >
                            {color.map((col) => (
                                <MenuItem key={col.id} value={col.nome} sx={menuItemStyles}>
                                    {col.nome}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            label="Ordina per"
                            value={selectedSort}
                            onChange={(e) => setSelectedSort(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        >
                            {sortOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value} sx={menuItemStyles}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <Button
                            sx={{
                                marginTop: '20px',
                                borderRadius: '20px',
                                padding: '10px',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                fontFamily: '"Nunito", sans-serif',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                '&:hover': {
                                    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                            onClick={handleFilter}
                            variant="contained"
                            color="warning"
                            fullWidth
                        >
                            FILTRA
                        </Button>
                        <Button
                            onClick={handleReset}
                            variant="contained"
                            color="error"
                            fullWidth
                            sx={{
                                marginTop: '20px',
                                borderRadius: '20px',
                                padding: '10px',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                fontFamily: '"Nunito", sans-serif',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                '&:hover': {
                                    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                        >
                            RESET
                        </Button>
                    </div>
                </CardContent>
                {loading && <CircularProgress/>}
            </Card>
        </ThemeProvider>
    );
};

export default FilterAndSort;
