import React, {useEffect, useRef, useState} from 'react';
import {Button, Chip, Dialog, DialogContent, Grid, IconButton, Tabs, Tab, Box, Tooltip} from '@mui/material';
import {FaRedo, FaStar, FaTimes} from 'react-icons/fa';
import styles from './DettagliCarta.module.css';
import {getBlockerText} from "../../../utility/utils";
import {DettagliCartaProps, Field, fields, formatCardNumber, getCardImageUrl, handleFlip} from './ConstDetail';
import TendenzaPrezzo from "../../Grafici/TendenzaPrezzo";

const DettagliCarta: React.FC<DettagliCartaProps> = ({ open, onClose, carta, tipo }) => {
    const dialogContentRef = useRef<HTMLDivElement | null>(null);
    const [flipped, setFlipped] = useState(false);
    const [rotating, setRotating] = useState(false);
    const [tabValue, setTabValue] = useState(0); // Stato per i tab attivi
    const [selectedCard, setSelectedCard] = useState<any>(carta);


    useEffect(() => {
        if (!open) {
            setFlipped(false);
            setRotating(false);
        }
        if (dialogContentRef.current) {
            const dialogContent = dialogContentRef.current;
            if (dialogContent.scrollHeight > dialogContent.clientHeight) {
                dialogContent.style.overflowY = 'auto';
            } else {
                dialogContent.style.overflowY = 'hidden';
            }
        }
    }, [open, carta]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
            fullWidth
            PaperProps={{ classes: { root: styles.dialogPaper } }}
            BackdropProps={{ classes: { root: styles.dialogBackdrop } }}
        >
            <div className={styles.header}>
                <IconButton className={styles.closeButton} onClick={onClose}>
                    <FaTimes />
                </IconButton>
            </div>
            <DialogContent className={styles.dialogContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={styles.cardContainer}></Grid>
                    <Grid item xs={12} md={6} className={styles.imageContainer}>
                        <img
                            src={flipped && carta.imageBack ? carta.imageBack : getCardImageUrl(tipo, carta)}
                            alt={carta.name}
                            className={`${styles.dialogImage} ${flipped ? styles.flipped : ''} ${rotating ? styles.rotating : ''}`}
                        />
                        {carta.imageBack && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleFlip(setRotating, setFlipped)}
                                className={`${styles.flipButton} ${flipped ? styles.flippedButton : ''}`}
                            >
                                <FaRedo style={{ marginRight: '8px' }} />
                                {flipped ? 'Fronte' : 'Awakened'}
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6} className={styles.detailsContainer}>
                        <div className={styles.cardWrapper}>
                            <h2 className={styles.cardName}>
                                {carta.name}
                                <em>
                                    ({tipo === 'lorcana' || tipo === 'dragonball' ? formatCardNumber(carta.idCard) : formatCardNumber(carta.numeroEspansione)})
                                </em>
                            </h2>
                        </div>

                        <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="Card Details Tabs"
                                TabIndicatorProps={{ style: { backgroundColor: '#3f51b5', height: '3px' } }}
                            >
                                <Tab
                                    label="INFO"
                                    style={{
                                        fontWeight: tabValue === 0 ? 'bold' : 'normal',
                                        fontSize: '1rem',
                                        color: 'white', // Colore testo bianco
                                    }}
                                />
                                <Tab
                                    label="TENDENZA PREZZO"
                                    style={{
                                        fontWeight: tabValue === 1 ? 'bold' : 'normal',
                                        fontSize: '1rem',
                                        color: 'white', // Colore testo bianco
                                    }}
                                    disabled={carta.prezzo === null}
                                />
                            </Tabs>

                        </Box>

                        <Box sx={{ padding: 2 }}>
                            {tabValue === 0 && (
                                <Box>
                                    <table className={`${styles.detailsTable} ${styles.withBorders}`}>
                                        <thead>
                                        </thead>
                                        <tbody>
                                        {fields(tipo)
                                            .reduce<Field[][]>((rows, field, index) => {
                                                if (index % 2 === 0) {
                                                    rows.push([]);
                                                }
                                                rows[rows.length - 1].push(field);
                                                return rows;
                                            }, [])
                                            .map((rowFields, rowIndex) => (
                                                <React.Fragment key={rowIndex}>
                                                    <tr>
                                                        {rowFields.map((field) => (
                                                            <React.Fragment key={field.key}>
                                                                <th>{field.label.toLowerCase()}</th>
                                                                <td
                                                                    className={`${styles.uppercase} ${field.key === 'flavour' ? styles.italic : ''}`}
                                                                >
                                                                    {field.key === 'rarity' ? (
                                                                        <>
                                                                            {carta?.[field.key] || 'N/A'}
                                                                            {carta?.alternativeArt && carta.alternativeArt.trim() !== '' && (
                                                                                <Tooltip title="Versione Alternativa">
                                                                                    <FaStar className={styles.alternativeArtIcon} />
                                                                                </Tooltip>
                                                                            )}
                                                                        </>
                                                                    ) : field.key === 'caratteristiche' ? (
                                                                        carta[field.key] ? (
                                                                            (JSON.parse(carta[field.key]) as string[]).map((caratteristica: string, index: number) => (
                                                                                <Chip key={index} label={caratteristica.toLowerCase()} className={styles.chip} />
                                                                            ))
                                                                        ) : null
                                                                    ) : field.key === 'flavour' ? (
                                                                        <span
                                                                            className={styles.aforisma}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: carta[field.key] || (field.optional ? '-' : ''),
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        (carta[field.key] || (field.optional ? '-' : '')).toLowerCase()
                                                                    )}
                                                                </td>
                                                            </React.Fragment>
                                                        ))}
                                                        {rowFields.length === 1 && <td colSpan={2}></td>}
                                                    </tr>
                                                    <tr className={styles.rowDivider} />
                                                </React.Fragment>
                                            ))}
                                        <tr>
                                            <th>Effetto</th>
                                            <td colSpan={3} dangerouslySetInnerHTML={{ __html: getBlockerText(carta.effetto) }} />
                                        </tr>
                                        </tbody>
                                    </table>
                                </Box>
                            )}
                            {tabValue === 1 && (
                                <Box
                                    sx={{
                                        backgroundColor: '#1e1e1e', // Sfondo scuro
                                        borderRadius: '12px',
                                        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.6)', // Ombra profonda
                                        padding: '25px',
                                        margin: '20px auto',
                                        maxWidth: '900px',
                                        border: '1px solid #333', // Bordo sottile per delineare il contenitore
                                    }}
                                >
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: '1.8rem', // Testo leggermente più grande
                                            color: '#f1f1f1', // Testo chiaro per contrasto
                                            fontWeight: 'bold',
                                            marginBottom: '15px',
                                            textShadow: '1px 1px 5px rgba(0, 0, 0, 0.7)', // Ombra sul testo per profondità
                                        }}
                                    >

                                    </Box>
                                    <Box
                                        sx={{
                                            backgroundColor: '#2e2e2e', // Sfondo leggermente più chiaro rispetto al contenitore principale
                                            borderRadius: '12px',
                                            padding: '20px',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)', // Ombra per il box interno
                                            border: '1px solid #444', // Bordo interno per maggiore contrasto
                                        }}
                                    >
                                        <TendenzaPrezzo selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DettagliCarta;
