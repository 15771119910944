import React, {useState} from 'react';
import {toast} from "react-toastify";
import {CambiaPasswordDTO, ChangePasswordRequest, UtenteRestApi} from "../../api";
import {useNavigate, useParams} from "react-router-dom";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const api = new UtenteRestApi();

const CambiaPassword: React.FC = () => {
    const {token} = useParams<{ token: string }>();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleSubmit = async () => {
        if (newPassword !== confirmPassword) {
            toast.error("La nuova password e la conferma non corrispondono.");
            return;
        }

        if (!token) {
            toast.error("Token mancante. Riprova più tardi.");
            return;
        }


        setIsSubmitting(true);
        try {
            const cambiaPssw: CambiaPasswordDTO = {
                nuovaPassword: newPassword,
                confermaPassword: confirmPassword
            }
            const request: ChangePasswordRequest = {
                token: token,
                cambiaPasswordDTO: cambiaPssw
            }
            await api.changePasswordRaw(request)
            toast.success("Password aggiornata con successo.");
            navigate('/login');
        } catch (error: any) {
            if (error.response) {
                const errorResponse = await error.response.json();
                toast.error(errorResponse.message);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #6a11cb, #007bff)',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        color: '#ffffff',
    } as React.CSSProperties;

    const cardStyle = {
        backgroundColor: '#ffffff',
        padding: '40px',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        textAlign: 'center',
        maxWidth: '400px',
        color: '#333',
    } as React.CSSProperties;

    const inputStyle = {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '8px',
        border: '1px solid #ccc',
        fontSize: '16px',
    } as React.CSSProperties;

    const buttonStyle = {
        width: '100%',
        padding: '12px',
        backgroundColor: isSubmitting ? '#aaa' : '#007bff',
        color: '#ffffff',
        border: 'none',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: 'bold',
        cursor: isSubmitting ? 'not-allowed' : 'pointer',
        boxShadow: '0 4px 6px rgba(0, 123, 255, 0.4)',
        transition: 'background-color 0.3s, transform 0.2s',
    } as React.CSSProperties;

    return (
        <div style={containerStyle}>
            <div style={cardStyle}>
                <h2 style={{ color: '#007bff', marginBottom: '20px' }}>Cambia Password</h2>

                <div style={{ position: 'relative', marginBottom: '20px' }}>
                    <input
                        type={showNewPassword ? 'text' : 'password'}
                        placeholder="Nuova Password"
                        style={{ ...inputStyle, paddingRight: '40px' }}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        disabled={isSubmitting}
                    />
                    <span
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            color: '#007bff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                            backgroundColor: '#f1f1f1',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'background-color 0.2s ease',
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e0e0e0')}
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#f1f1f1')}
                    >
                    {showNewPassword ? (
                        <VisibilityOff style={{ fontSize: '20px' }} />
                    ) : (
                        <Visibility style={{ fontSize: '20px' }} />
                    )}
                </span>
                </div>

                <div style={{ position: 'relative', marginBottom: '20px' }}>
                    <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="Conferma Nuova Password"
                        style={{ ...inputStyle, paddingRight: '40px' }}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        disabled={isSubmitting}
                    />
                    <span
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            color: '#007bff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                            backgroundColor: '#f1f1f1',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'background-color 0.2s ease',
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e0e0e0')}
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#f1f1f1')}
                    >
                    {showConfirmPassword ? (
                        <VisibilityOff style={{ fontSize: '20px' }} />
                    ) : (
                        <Visibility style={{ fontSize: '20px' }} />
                    )}
                </span>
                </div>

                <button
                    style={buttonStyle}
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Invio in corso..." : "Cambia Password"}
                </button>
            </div>
        </div>
    );

};

export default CambiaPassword;
