import React, {useState} from 'react';
import {FaStar} from 'react-icons/fa';
import styles from './CommentoCollezione.module.css';
import {useDispatch} from 'react-redux';
import {handleSaveClick} from "../../store/commenti/commentHandlers"; // Importa la funzione aggiornata

interface CommentAndRateProps {
    collezioneId: string | undefined;
}

const CommentoCollezione: React.FC<CommentAndRateProps> = ({ collezioneId }) => {
    const [rating, setRating] = useState<number>(0);
    const [hover, setHover] = useState<number>(0);
    const [comment, setComment] = useState<string>('');

    const savedUser = localStorage.getItem('user');
    const datiUtente = savedUser ? JSON.parse(savedUser) : null;
    const dispatch = useDispatch();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await handleSaveClick(dispatch, comment, rating, datiUtente, collezioneId);
        setComment('');
        setRating(0);
        setHover(0);
    };

    return (
        <div className={styles.commentAndRateContainer}>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.stars}>
                    {[...Array(5)].map((_, i) => {
                        const ratingValue = i + 1;
                        return (
                            <label key={i}>
                                <input
                                    type="radio"
                                    name="rating"
                                    value={ratingValue}
                                    onClick={() => setRating(ratingValue)}
                                    className={styles.radioInput}
                                />
                                <FaStar
                                    className={styles.star}
                                    color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                                    size={30}
                                    onMouseEnter={() => setHover(ratingValue)}
                                    onMouseLeave={() => setHover(0)}
                                />
                            </label>
                        );
                    })}
                </div>
                <textarea
                    className={styles.textarea}
                    placeholder="Scrivi il tuo commento qui.."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
                <button type="submit" className={styles.submitButton}>INVIA</button>
            </form>
        </div>
    );
};

export default CommentoCollezione;
