import {FindByIdUserRequest, ObiettivoDTO, ObiettivoRestApi} from "../../api";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

interface ObiettiviState {
    obiettivi: ObiettivoDTO[];
    loading: boolean;
    error: string | null;
}

const initialState: ObiettiviState = {
    obiettivi: [],
    loading: false,
    error: null,
};

const obiettivoApi = new ObiettivoRestApi();

// Fetch Obiettivi
export const fetchObiettivi = createAsyncThunk(
    'comments/fetchComments',
    async ({datiUtente}: { datiUtente: any }, {rejectWithValue}) => {


        const apiRequest: FindByIdUserRequest = {
            id: datiUtente.id,
        };

        try {
            const response = await obiettivoApi.findByIdUserRaw(apiRequest);
            const data = await response.raw.json();
            return data || [];
        } catch (error) {
            toast.error('Si è verificato un errore con il caricamento dei commenti.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

const obiettiviSlice = createSlice({
    name: 'obiettivi',
    initialState,
    reducers: {
        setObiettivi: (state, action: PayloadAction<ObiettivoDTO[]>) => {
            state.obiettivi = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchObiettivi.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchObiettivi.fulfilled, (state, action) => {
                state.loading = false;
                state.obiettivi = action.payload;
            })
            .addCase(fetchObiettivi.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
    }
});

export const {setObiettivi} = obiettiviSlice.actions;
export default obiettiviSlice.reducer;
