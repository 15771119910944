/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CollezioneCartaUtenteDTO } from './CollezioneCartaUtenteDTO';
import {
    CollezioneCartaUtenteDTOFromJSON,
    CollezioneCartaUtenteDTOFromJSONTyped,
    CollezioneCartaUtenteDTOToJSON,
} from './CollezioneCartaUtenteDTO';
import type { TendenzaDTO } from './TendenzaDTO';
import {
    TendenzaDTOFromJSON,
    TendenzaDTOFromJSONTyped,
    TendenzaDTOToJSON,
} from './TendenzaDTO';
import type { UtenteDTO } from './UtenteDTO';
import {
    UtenteDTOFromJSON,
    UtenteDTOFromJSONTyped,
    UtenteDTOToJSON,
} from './UtenteDTO';

/**
 * 
 * @export
 * @interface CollezioneDTO
 */
export interface CollezioneDTO {
    /**
     * 
     * @type {string}
     * @memberof CollezioneDTO
     */
    id?: string;
    /**
     * 
     * @type {UtenteDTO}
     * @memberof CollezioneDTO
     */
    utente?: UtenteDTO;
    /**
     * 
     * @type {Array<CollezioneCartaUtenteDTO>}
     * @memberof CollezioneDTO
     */
    carte?: Array<CollezioneCartaUtenteDTO>;
    /**
     * 
     * @type {string}
     * @memberof CollezioneDTO
     */
    nome?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CollezioneDTO
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CollezioneDTO
     */
    tipoCard?: CollezioneDTOTipoCardEnum;
    /**
     * 
     * @type {string}
     * @memberof CollezioneDTO
     */
    tipoPrivacy?: CollezioneDTOTipoPrivacyEnum;
    /**
     * 
     * @type {number}
     * @memberof CollezioneDTO
     */
    mediaValutazioni?: number;
    /**
     * 
     * @type {Date}
     * @memberof CollezioneDTO
     */
    dataInizio?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof CollezioneDTO
     */
    imgCopertina?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof CollezioneDTO
     */
    ultimaModifica?: Date;
    /**
     * 
     * @type {string}
     * @memberof CollezioneDTO
     */
    preferenza?: CollezioneDTOPreferenzaEnum;
    /**
     * 
     * @type {number}
     * @memberof CollezioneDTO
     */
    valoreCollezione?: number;
    /**
     * 
     * @type {Array<TendenzaDTO>}
     * @memberof CollezioneDTO
     */
    tendenzaPrezzo?: Array<TendenzaDTO>;
}


/**
 * @export
 */
export const CollezioneDTOTipoCardEnum = {
    MagicTheGathering: 'MAGIC_THE_GATHERING',
    Pokemon: 'POKEMON',
    YugiOh: 'YUGI_OH',
    OnePiece: 'ONE_PIECE',
    Dragonball: 'DRAGONBALL',
    Lorcana: 'LORCANA',
    Digimon: 'DIGIMON',
    UnionArena: 'UNION_ARENA',
    Starwars: 'STARWARS'
} as const;
export type CollezioneDTOTipoCardEnum = typeof CollezioneDTOTipoCardEnum[keyof typeof CollezioneDTOTipoCardEnum];

/**
 * @export
 */
export const CollezioneDTOTipoPrivacyEnum = {
    Pubblica: 'PUBBLICA',
    Privata: 'PRIVATA',
    SoloAlcuni: 'SOLO_ALCUNI'
} as const;
export type CollezioneDTOTipoPrivacyEnum = typeof CollezioneDTOTipoPrivacyEnum[keyof typeof CollezioneDTOTipoPrivacyEnum];

/**
 * @export
 */
export const CollezioneDTOPreferenzaEnum = {
    Griglia: 'GRIGLIA',
    Lista: 'LISTA'
} as const;
export type CollezioneDTOPreferenzaEnum = typeof CollezioneDTOPreferenzaEnum[keyof typeof CollezioneDTOPreferenzaEnum];


/**
 * Check if a given object implements the CollezioneDTO interface.
 */
export function instanceOfCollezioneDTO(value: object): value is CollezioneDTO {
    return true;
}

export function CollezioneDTOFromJSON(json: any): CollezioneDTO {
    return CollezioneDTOFromJSONTyped(json, false);
}

export function CollezioneDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollezioneDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'utente': json['utente'] == null ? undefined : UtenteDTOFromJSON(json['utente']),
        'carte': json['carte'] == null ? undefined : ((json['carte'] as Array<any>).map(CollezioneCartaUtenteDTOFromJSON)),
        'nome': json['nome'] == null ? undefined : json['nome'],
        'isPrivate': json['isPrivate'] == null ? undefined : json['isPrivate'],
        'tipoCard': json['tipoCard'] == null ? undefined : json['tipoCard'],
        'tipoPrivacy': json['tipoPrivacy'] == null ? undefined : json['tipoPrivacy'],
        'mediaValutazioni': json['mediaValutazioni'] == null ? undefined : json['mediaValutazioni'],
        'dataInizio': json['dataInizio'] == null ? undefined : (new Date(json['dataInizio'])),
        'imgCopertina': json['imgCopertina'] == null ? undefined : json['imgCopertina'],
        'ultimaModifica': json['ultimaModifica'] == null ? undefined : (new Date(json['ultimaModifica'])),
        'preferenza': json['preferenza'] == null ? undefined : json['preferenza'],
        'valoreCollezione': json['valoreCollezione'] == null ? undefined : json['valoreCollezione'],
        'tendenzaPrezzo': json['tendenzaPrezzo'] == null ? undefined : ((json['tendenzaPrezzo'] as Array<any>).map(TendenzaDTOFromJSON)),
    };
}

export function CollezioneDTOToJSON(value?: CollezioneDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'utente': UtenteDTOToJSON(value['utente']),
        'carte': value['carte'] == null ? undefined : ((value['carte'] as Array<any>).map(CollezioneCartaUtenteDTOToJSON)),
        'nome': value['nome'],
        'isPrivate': value['isPrivate'],
        'tipoCard': value['tipoCard'],
        'tipoPrivacy': value['tipoPrivacy'],
        'mediaValutazioni': value['mediaValutazioni'],
        'dataInizio': value['dataInizio'] == null ? undefined : ((value['dataInizio']).toISOString()),
        'imgCopertina': value['imgCopertina'],
        'ultimaModifica': value['ultimaModifica'] == null ? undefined : ((value['ultimaModifica']).toISOString()),
        'preferenza': value['preferenza'],
        'valoreCollezione': value['valoreCollezione'],
        'tendenzaPrezzo': value['tendenzaPrezzo'] == null ? undefined : ((value['tendenzaPrezzo'] as Array<any>).map(TendenzaDTOToJSON)),
    };
}

