import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    CommentoCartaRestApi,
    CommentoCollezioneDTO,
    CommentoSearchRequest, DeleteCommentRequest,
    GetCommentiByCartaRequest,
    UpdateCommentRequest
} from "../../api";
import {toast} from "react-toastify";

interface CommentsState {
    comments: CommentoCollezioneDTO[];
    loading: boolean;
    error: string | null;
}

const initialState: CommentsState = {
    comments: [],
    loading: false,
    error: null,
};

const commentiApi = new CommentoCartaRestApi();

// Fetch Comments
export const fetchComments = createAsyncThunk(
    'comments/fetchComments',
    async ({idCollection, page}: { idCollection: string | undefined; page: number }, {rejectWithValue}) => {
        if (!idCollection) return rejectWithValue('ID collezione mancante');

        const pageable = {
            pageNumber: page - 1,
            pageSize: 15,
        };

        const commentoSearchRequest: CommentoSearchRequest = {
            idCollezione: idCollection,
            pageable: pageable,
        };

        const apiRequest: GetCommentiByCartaRequest = {
            commentoSearchRequest: commentoSearchRequest,
        };

        try {
            const response = await commentiApi.getCommentiByCartaRaw(apiRequest);
            const data = await response.raw.json();
            return data.content || [];
        } catch (error) {
            toast.error('Si è verificato un errore con il caricamento dei commenti.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);



// Update Comment
export const updateComment = createAsyncThunk(
    'comments/updateComment',
    async (comment: CommentoCollezioneDTO, {rejectWithValue}) => {
        const updateCommentRequest: UpdateCommentRequest = {
            commentoCollezioneDTO: comment
        };

        try {
            const response = await commentiApi.updateCommentRaw(updateCommentRequest);
            toast.success('Commento aggiornato con successo!');
            return comment;
        } catch (error) {
            toast.error('Si è verificato un errore con l\'aggiornamento del commento.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

// Delete Comment
export const deleteComment = createAsyncThunk(
    'comments/deleteComment',
    async (id: number | undefined, {rejectWithValue}) => {
        if (!id) return rejectWithValue('ID commento mancante');

        const deleteCommentoRequest: DeleteCommentRequest = {
            idCommento: id
        };

        try {
            const response = await commentiApi.deleteCommentRaw(deleteCommentoRequest);
            return id;
        } catch (error) {
            toast.error('Si è verificato un errore con l\'eliminazione del commento.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

export const addComment = createAsyncThunk(
    'comments/addComment',
    async (comment: CommentoCollezioneDTO, { rejectWithValue }) => {
        const addCommentRequest = {
            commentoCollezioneDTO: comment
        };

        try {
            const response = await commentiApi.addComment1Raw(addCommentRequest);
            toast.success('Commento aggiornato con successo!');
            return response.raw.json();
        } catch (error) {
            toast.error('Si è verificato un errore con l\'aggiornamento del commento.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);



const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        setComments: (state, action: PayloadAction<CommentoCollezioneDTO[]>) => {
            state.comments = action.payload;
        },
        updateCommentReducer: (state, action: PayloadAction<CommentoCollezioneDTO>) => {
            const index = state.comments.findIndex(comment => comment.id === action.payload.id);
            if (index !== -1) {
                state.comments[index] = action.payload;
            }
        },
        deleteCommentReducer: (state, action: PayloadAction<number | undefined>) => {
            state.comments = state.comments.filter(comment => comment.id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchComments.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchComments.fulfilled, (state, action) => {
                state.loading = false;
                state.comments = action.payload;
            })
            .addCase(fetchComments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(addComment.fulfilled, (state, action) => {
                state.loading = false;
                state.comments.push(action.payload);
            })
            .addCase(updateComment.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateComment.fulfilled, (state, action) => {
                state.loading = false;
                const index = state.comments.findIndex(comment => comment.id === action.payload.id);
                if (index !== -1) {
                    state.comments[index] = action.payload;
                }
            })
            .addCase(updateComment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(deleteComment.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteComment.fulfilled, (state, action) => {
                state.loading = false;
                state.comments = state.comments.filter(comment => comment.id !== action.payload);
            })
            .addCase(deleteComment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    }
});
export const {setComments, updateCommentReducer, deleteCommentReducer} = commentsSlice.actions;
export default commentsSlice.reducer;
