import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, Grid, List, ListItemButton, ListItemText, Typography,} from '@mui/material';
import Profilo from "../components/UserProfile/Profilo";
import ListaObiettivi from "../components/obiettivi/Lista-obiettivi/Lista-obiettivi";
import {
    ConfigurazioniByUserRequest,
    ConfigurazioniRestApi,
    FindByIdUserRequest,
    ObiettivoRestApi,
    UtenteDTO
} from "../api";
import {useLocation} from "react-router-dom";

type MenuItem = {
    id: string;
    label: string;
};

interface UserProfileProps {
    activeMenuItem?: string;
}

const menuItems: MenuItem[] = [
    { id: 'profile', label: 'Profilo' },
    { id: 'badge', label: 'Badge' },
    { id: 'goals', label: 'Obiettivi' },
    { id: 'settings', label: 'Impostazioni' },
    { id: 'logout', label: 'Logout' },
];

const obiettivoApi = new ObiettivoRestApi();
const configurazioniApi = new ConfigurazioniRestApi();

const UserProfile: React.FC<UserProfileProps> = ({ activeMenuItem }) => {
    const location = useLocation();
    const initialMenuItem = location.pathname.endsWith('/settings') ? 'settings' : 'profile';
    const [currentMenuItem, setCurrentMenuItem] = useState<string>(activeMenuItem || initialMenuItem);
    const [obiettivi, setObiettivi] = useState<any[]>([]);
    const [utente, setUtente] = useState<UtenteDTO | null>(null);
    const [configurazioni, setConfigurazioni] = useState<any | null>(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUtente(JSON.parse(storedUser));
        }
    }, []);

    const handleMenuClick = (menuId: string) => {
        if (menuId === 'logout') {
            localStorage.removeItem('user');
            window.location.reload();
            return;
        }
        setCurrentMenuItem(menuId);
    };

    const fetchObiettivi = async () => {
        if (!utente?.id) return;

        const requestData: FindByIdUserRequest = { id: utente.id };
        try {
            const response = await obiettivoApi.findByIdUserRaw(requestData);
            const jsonResponse = await response.raw.json();

            if (jsonResponse && jsonResponse.length > 0) {
                setObiettivi(jsonResponse);
            }
        } catch (error) {
            console.error('Errore durante il recupero degli obiettivi:', error);
        }
    };

    const fetchConfigurazioni = async (userId: string) => {
        if (!userId) return;

        const requestData: ConfigurazioniByUserRequest = { idUtente: userId };
        try {
            const response = await configurazioniApi.configurazioniByUserRaw(requestData);
            const jsonResponse = await response.raw.json();
            setConfigurazioni(jsonResponse);
        } catch (error) {
            console.error('Errore durante il recupero delle configurazioni:', error);
        }
    };


    useEffect(() => {
        if (currentMenuItem === 'goals') {
            fetchObiettivi().then(r => {});
        } else if (currentMenuItem === 'settings') {
            if (utente?.id) {
                fetchConfigurazioni(utente.id).then(r => {});
            }
        }
    }, [currentMenuItem, utente?.id]);

    const renderContent = () => {
        if (!utente) {
            return <Typography variant="h6">Per favore, effettua il login.</Typography>;
        }

        switch (currentMenuItem) {
            case 'profile':
                return <Profilo user={utente} />;
            case 'badge':
                return (
                    <>
                        <Typography variant="h4" gutterBottom>
                            I tuoi Badge
                        </Typography>
                        <Typography variant="body1">
                            Qui puoi vedere i tuoi badge e i progressi.
                        </Typography>
                    </>
                );
            case 'goals':
                return (
                    <>
                        <Typography variant="h4" gutterBottom sx={{ fontFamily: '"Montserrat", sans-serif', fontWeight: 'bold', fontSize: '2rem', textAlign: 'center', color: '#3f51b5' }}>
                            OBIETTIVI
                        </Typography>
                        <Typography variant="body1" sx={{ fontFamily: 'Verdana, sans-serif', fontSize: '0.9rem', fontStyle: 'italic' }}>
                            Qui puoi vedere gli obiettivi in corso e quelli ancora da sbloccare.
                        </Typography>
                        <ListaObiettivi achievements={obiettivi} />
                    </>
                );
            case 'settings':
                return (
                    <>
                        <Typography variant="h4" gutterBottom sx={{ fontFamily: '"Montserrat", sans-serif', fontWeight: 'bold', fontSize: '2rem', textAlign: 'center', color: '#3f51b5' }}>
                            IMPOSTAZIONI
                        </Typography>
                        <Typography variant="body1" sx={{ fontFamily: 'Verdana, sans-serif', fontSize: '0.9rem', fontStyle: 'italic',   marginBottom: '1rem'}}>
                            Qui puoi modificare le tue preferenze e configurazioni.
                        </Typography>
                        {/*<Configurazioni*/}
                        {/*    utente={utente}*/}
                        {/*    configurazioni={configurazioni}/>*/}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={3}>
                    <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom sx={{ textTransform: 'uppercase' }}>
                                {utente?.username}
                            </Typography>
                            <List>
                                {menuItems.map((item) => (
                                    <ListItemButton
                                        key={item.id}
                                        onClick={() => handleMenuClick(item.id)}
                                        selected={currentMenuItem === item.id}
                                        sx={{
                                            backgroundColor: currentMenuItem === item.id ? 'orange' : 'inherit',
                                            '&:hover': { backgroundColor: 'orange' },
                                        }}
                                    >
                                        <ListItemText primary={item.label} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <Box sx={{ boxShadow: 3, borderRadius: 3, padding: 3 }}>
                        {renderContent()}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UserProfile;
