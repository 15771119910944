/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CollezioneUpdateDTO
 */
export interface CollezioneUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof CollezioneUpdateDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CollezioneUpdateDTO
     */
    nome?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CollezioneUpdateDTO
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CollezioneUpdateDTO
     */
    imgCopertina?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CollezioneUpdateDTO
     */
    modificaCopertina?: boolean;
}

/**
 * Check if a given object implements the CollezioneUpdateDTO interface.
 */
export function instanceOfCollezioneUpdateDTO(value: object): value is CollezioneUpdateDTO {
    return true;
}

export function CollezioneUpdateDTOFromJSON(json: any): CollezioneUpdateDTO {
    return CollezioneUpdateDTOFromJSONTyped(json, false);
}

export function CollezioneUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollezioneUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'nome': json['nome'] == null ? undefined : json['nome'],
        'isPrivate': json['isPrivate'] == null ? undefined : json['isPrivate'],
        'imgCopertina': json['imgCopertina'] == null ? undefined : json['imgCopertina'],
        'modificaCopertina': json['modificaCopertina'] == null ? undefined : json['modificaCopertina'],
    };
}

export function CollezioneUpdateDTOToJSON(value?: CollezioneUpdateDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'nome': value['nome'],
        'isPrivate': value['isPrivate'],
        'imgCopertina': value['imgCopertina'],
        'modificaCopertina': value['modificaCopertina'],
    };
}

