/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UUIDFilter
 */
export interface UUIDFilter {
    /**
     * 
     * @type {string}
     * @memberof UUIDFilter
     */
    equals?: string;
    /**
     * 
     * @type {string}
     * @memberof UUIDFilter
     */
    notEquals?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UUIDFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UUIDFilter
     */
    _in?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UUIDFilter
     */
    notIn?: Array<string>;
}

/**
 * Check if a given object implements the UUIDFilter interface.
 */
export function instanceOfUUIDFilter(value: object): value is UUIDFilter {
    return true;
}

export function UUIDFilterFromJSON(json: any): UUIDFilter {
    return UUIDFilterFromJSONTyped(json, false);
}

export function UUIDFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): UUIDFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'equals': json['equals'] == null ? undefined : json['equals'],
        'notEquals': json['notEquals'] == null ? undefined : json['notEquals'],
        'specified': json['specified'] == null ? undefined : json['specified'],
        '_in': json['in'] == null ? undefined : json['in'],
        'notIn': json['notIn'] == null ? undefined : json['notIn'],
    };
}

export function UUIDFilterToJSON(value?: UUIDFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'equals': value['equals'],
        'notEquals': value['notEquals'],
        'specified': value['specified'],
        'in': value['_in'],
        'notIn': value['notIn'],
    };
}

