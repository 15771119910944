/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TipoCardFilter } from './TipoCardFilter';
import {
    TipoCardFilterFromJSON,
    TipoCardFilterFromJSONTyped,
    TipoCardFilterToJSON,
} from './TipoCardFilter';
import type { UUIDFilter } from './UUIDFilter';
import {
    UUIDFilterFromJSON,
    UUIDFilterFromJSONTyped,
    UUIDFilterToJSON,
} from './UUIDFilter';
import type { IntegerFilter } from './IntegerFilter';
import {
    IntegerFilterFromJSON,
    IntegerFilterFromJSONTyped,
    IntegerFilterToJSON,
} from './IntegerFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';

/**
 * 
 * @export
 * @interface CartaPokemonCriteria
 */
export interface CartaPokemonCriteria {
    /**
     * 
     * @type {UUIDFilter}
     * @memberof CartaPokemonCriteria
     */
    id?: UUIDFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    artist?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    rarity?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    imageUrl?: StringFilter;
    /**
     * 
     * @type {TipoCardFilter}
     * @memberof CartaPokemonCriteria
     */
    tipoCard?: TipoCardFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    idCartaPokemon?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    sourceName?: StringFilter;
    /**
     * 
     * @type {IntegerFilter}
     * @memberof CartaPokemonCriteria
     */
    nationalPokedexNumber?: IntegerFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    imageUrlHiRes?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    supertype?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    subtypes?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    hp?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    evolvesFrom?: StringFilter;
    /**
     * 
     * @type {IntegerFilter}
     * @memberof CartaPokemonCriteria
     */
    convertedRetreatCost?: IntegerFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    number?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    series?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    set?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaPokemonCriteria
     */
    setCode?: StringFilter;
}

/**
 * Check if a given object implements the CartaPokemonCriteria interface.
 */
export function instanceOfCartaPokemonCriteria(value: object): value is CartaPokemonCriteria {
    return true;
}

export function CartaPokemonCriteriaFromJSON(json: any): CartaPokemonCriteria {
    return CartaPokemonCriteriaFromJSONTyped(json, false);
}

export function CartaPokemonCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartaPokemonCriteria {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : UUIDFilterFromJSON(json['id']),
        'name': json['name'] == null ? undefined : StringFilterFromJSON(json['name']),
        'artist': json['artist'] == null ? undefined : StringFilterFromJSON(json['artist']),
        'rarity': json['rarity'] == null ? undefined : StringFilterFromJSON(json['rarity']),
        'imageUrl': json['imageUrl'] == null ? undefined : StringFilterFromJSON(json['imageUrl']),
        'tipoCard': json['tipoCard'] == null ? undefined : TipoCardFilterFromJSON(json['tipoCard']),
        'idCartaPokemon': json['idCartaPokemon'] == null ? undefined : StringFilterFromJSON(json['idCartaPokemon']),
        'sourceName': json['sourceName'] == null ? undefined : StringFilterFromJSON(json['sourceName']),
        'nationalPokedexNumber': json['nationalPokedexNumber'] == null ? undefined : IntegerFilterFromJSON(json['nationalPokedexNumber']),
        'imageUrlHiRes': json['imageUrlHiRes'] == null ? undefined : StringFilterFromJSON(json['imageUrlHiRes']),
        'supertype': json['supertype'] == null ? undefined : StringFilterFromJSON(json['supertype']),
        'subtypes': json['subtypes'] == null ? undefined : StringFilterFromJSON(json['subtypes']),
        'hp': json['hp'] == null ? undefined : StringFilterFromJSON(json['hp']),
        'evolvesFrom': json['evolvesFrom'] == null ? undefined : StringFilterFromJSON(json['evolvesFrom']),
        'convertedRetreatCost': json['convertedRetreatCost'] == null ? undefined : IntegerFilterFromJSON(json['convertedRetreatCost']),
        'number': json['number'] == null ? undefined : StringFilterFromJSON(json['number']),
        'series': json['series'] == null ? undefined : StringFilterFromJSON(json['series']),
        'set': json['set'] == null ? undefined : StringFilterFromJSON(json['set']),
        'setCode': json['setCode'] == null ? undefined : StringFilterFromJSON(json['setCode']),
    };
}

export function CartaPokemonCriteriaToJSON(value?: CartaPokemonCriteria | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': UUIDFilterToJSON(value['id']),
        'name': StringFilterToJSON(value['name']),
        'artist': StringFilterToJSON(value['artist']),
        'rarity': StringFilterToJSON(value['rarity']),
        'imageUrl': StringFilterToJSON(value['imageUrl']),
        'tipoCard': TipoCardFilterToJSON(value['tipoCard']),
        'idCartaPokemon': StringFilterToJSON(value['idCartaPokemon']),
        'sourceName': StringFilterToJSON(value['sourceName']),
        'nationalPokedexNumber': IntegerFilterToJSON(value['nationalPokedexNumber']),
        'imageUrlHiRes': StringFilterToJSON(value['imageUrlHiRes']),
        'supertype': StringFilterToJSON(value['supertype']),
        'subtypes': StringFilterToJSON(value['subtypes']),
        'hp': StringFilterToJSON(value['hp']),
        'evolvesFrom': StringFilterToJSON(value['evolvesFrom']),
        'convertedRetreatCost': IntegerFilterToJSON(value['convertedRetreatCost']),
        'number': StringFilterToJSON(value['number']),
        'series': StringFilterToJSON(value['series']),
        'set': StringFilterToJSON(value['set']),
        'setCode': StringFilterToJSON(value['setCode']),
    };
}

