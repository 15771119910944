import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {ConfirmUserAccountRequest, UtenteRestApi} from "../../api";
import {toast} from "react-toastify";

const api = new UtenteRestApi();

const ConfermaAccount: React.FC = () => {
    const {token} = useParams<{ token: string }>();
    const [status, setStatus] = useState<'pending' | 'success' | 'error'>('pending');
    const [message, setMessage] = useState<string>('');

    useEffect(() => {

        if (!token) {
            setStatus('error');
            setMessage('Token di conferma mancante.');
            return;
        }

        const confirmAccount = async () => {
            const request: ConfirmUserAccountRequest = {
                token,
            };

            try {
                const response = await api.confirmUserAccountRaw(request);
                const jsonResponse = await response.raw.json();

                if (jsonResponse.ok) {
                    setStatus('success');
                    setMessage(jsonResponse.message);
                }
            } catch (error: any) {
                if (error.response) {
                    const errorResponse = await error.response.json();
                    setStatus('error');
                    setMessage(errorResponse.message);
                } else {
                    toast.error('Errore di rete. Riprova più tardi.');
                }
            }
        };

        confirmAccount().then(r => {
        });
    }, [token]);

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #007bff, #6a11cb)',
        padding: '20px',
        color: '#ffffff',
        fontFamily: 'Arial, sans-serif',
    } as React.CSSProperties;

    const cardStyle = {
        backgroundColor: '#ffffff',
        padding: '30px',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        textAlign: 'center',
        maxWidth: '400px',
        color: '#333',
        fontFamily: 'Arial, sans-serif',
    } as React.CSSProperties;

    const buttonStyle = {
        textDecoration: 'none',
        color: '#ffffff',
        backgroundColor: '#007bff',
        padding: '12px 25px',
        borderRadius: '8px',
        display: 'inline-block',
        marginTop: '20px',
        fontSize: '16px',
        fontWeight: 'bold',
        boxShadow: '0 4px 6px rgba(0, 123, 255, 0.4)',
        transition: 'background-color 0.3s, transform 0.2s',
    } as React.CSSProperties;

    const buttonHoverStyle = {
        backgroundColor: '#0056b3',
        transform: 'scale(1.05)',
    };

    return (
        <div style={containerStyle}>
            <div style={cardStyle}>
                {status === 'pending' && (
                    <>
                        <h2 style={{color: '#007bff'}}>Conferma in corso...</h2>
                        <p>Stiamo verificando il tuo account. Attendi un momento.</p>
                    </>
                )}

                {status === 'success' && (
                    <>
                        <h2 style={{color: '#28a745', fontSize: '24px'}}>🎉 ACCOUNT CONFERMATO CON SUCCESSO!</h2>
                        <p>Grazie per aver confermato il tuo account. Adesso puoi accedere!</p>
                        <a
                            href="/login"
                            style={buttonStyle}
                            onMouseEnter={(e) => {
                                (e.target as HTMLElement).style.backgroundColor = buttonHoverStyle.backgroundColor;
                                (e.target as HTMLElement).style.transform = buttonHoverStyle.transform;
                            }}
                        >
                            Vai al login
                        </a>
                    </>
                )}

                {status === 'error' && (
                    <>
                        <h2 style={{color: '#dc3545', fontSize: '24px'}}>⚠️ Errore</h2>
                        <p>{message}</p>
                        <a
                            href="/"
                            style={buttonStyle}
                            onMouseEnter={(e) => {
                                (e.target as HTMLElement).style.backgroundColor = buttonHoverStyle.backgroundColor;
                                (e.target as HTMLElement).style.transform = buttonHoverStyle.transform;
                            }}
                        >
                            Torna alla home
                        </a>
                    </>
                )}
            </div>
        </div>
    );
};

export default ConfermaAccount;
