/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TipoCardFilter } from './TipoCardFilter';
import {
    TipoCardFilterFromJSON,
    TipoCardFilterFromJSONTyped,
    TipoCardFilterToJSON,
} from './TipoCardFilter';
import type { UUIDFilter } from './UUIDFilter';
import {
    UUIDFilterFromJSON,
    UUIDFilterFromJSONTyped,
    UUIDFilterToJSON,
} from './UUIDFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';

/**
 * 
 * @export
 * @interface CartaCriteria
 */
export interface CartaCriteria {
    /**
     * 
     * @type {UUIDFilter}
     * @memberof CartaCriteria
     */
    id?: UUIDFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaCriteria
     */
    artist?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaCriteria
     */
    rarity?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaCriteria
     */
    imageUrl?: StringFilter;
    /**
     * 
     * @type {TipoCardFilter}
     * @memberof CartaCriteria
     */
    tipoCard?: TipoCardFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaCriteria
     */
    idCartaPokemon?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CartaCriteria
     */
    sourceName?: StringFilter;
}

/**
 * Check if a given object implements the CartaCriteria interface.
 */
export function instanceOfCartaCriteria(value: object): value is CartaCriteria {
    return true;
}

export function CartaCriteriaFromJSON(json: any): CartaCriteria {
    return CartaCriteriaFromJSONTyped(json, false);
}

export function CartaCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartaCriteria {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : UUIDFilterFromJSON(json['id']),
        'name': json['name'] == null ? undefined : StringFilterFromJSON(json['name']),
        'artist': json['artist'] == null ? undefined : StringFilterFromJSON(json['artist']),
        'rarity': json['rarity'] == null ? undefined : StringFilterFromJSON(json['rarity']),
        'imageUrl': json['imageUrl'] == null ? undefined : StringFilterFromJSON(json['imageUrl']),
        'tipoCard': json['tipoCard'] == null ? undefined : TipoCardFilterFromJSON(json['tipoCard']),
        'idCartaPokemon': json['idCartaPokemon'] == null ? undefined : StringFilterFromJSON(json['idCartaPokemon']),
        'sourceName': json['sourceName'] == null ? undefined : StringFilterFromJSON(json['sourceName']),
    };
}

export function CartaCriteriaToJSON(value?: CartaCriteria | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': UUIDFilterToJSON(value['id']),
        'name': StringFilterToJSON(value['name']),
        'artist': StringFilterToJSON(value['artist']),
        'rarity': StringFilterToJSON(value['rarity']),
        'imageUrl': StringFilterToJSON(value['imageUrl']),
        'tipoCard': TipoCardFilterToJSON(value['tipoCard']),
        'idCartaPokemon': StringFilterToJSON(value['idCartaPokemon']),
        'sourceName': StringFilterToJSON(value['sourceName']),
    };
}

