export const getCardImageUrl = (tipoCard: any, carta: any) => {
    let url;
    if (tipoCard === 'dragonball' && carta.alternativeArt && carta.alternativeArt.startsWith('_')) {
        url = `https://static.dotgg.gg/dragonball/card/${carta.idCard}.webp`;
    } else {
        url = carta?.imageUrl;
    }
    return url;
};
export const handleClickOpen = (setOpen: any) => {
    setOpen(true);
};

export const handleClose = (setOpen: any) => {
    setOpen(false);
};

export const handleClickOpenAdd = (setOpenAdd: any) => {
    setOpenAdd(true);
};

export const handleCloseAdd = (setOpenAdd: any) => {
    setOpenAdd(false);
};

