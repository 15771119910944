import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Box, CardContent, Grid, Tooltip, Typography, CircularProgress} from "@mui/material";
import {FaPlus} from "react-icons/fa";
import styles from "../styles/MyCollection.module.css";
import TabellaMiaCollezione from "../components/Collection/Tabella-mia-collezione";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {fetchCollezioni} from "../store/collezioni/CollezioniSlice";
import {Person} from "@mui/icons-material";

import {
    CustomButton,
    handleCloseCreateDialogCollection,
    handleCloseDeleteDialogCollection,
    handleCloseEditDialogCollection,
    handleDeleteClickCollection,
    handleEditClickCollection,
    handleOpenCreateDialogCollection,
    StyledCard,
} from "../store/collezioni/CollezioniHandlers";
import CreateCollection from "../components/dialog/CreateCollection/Create-collection";
import EditCollection from "../components/dialog/EditCollection/Edit-collection";
import DeleteCollection from "../components/dialog/DeleteCollection/Delete-collection";

const MyCollection: React.FC = () => {
    const { idUser, tipoCard } = useParams<{ idUser: string | undefined; tipoCard?: string | undefined }>();
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [selectedCollectionId, setSelectedCollectionId] = useState<any>(undefined);
    const [selectedNomeCollection, setSelectedNomeCollection] = useState<any>(undefined);
    const collections = useSelector((state: RootState) => state.collezioni.collezioni);

    useEffect(() => {
        setLoading(true); // Inizia il caricamento
        dispatch(fetchCollezioni({ idUtente: idUser, tipoCard }))
            .unwrap()
            .then(() => setLoading(false)) // Termina il caricamento
            .catch((err) => {
                console.error(err.message);
                setLoading(false); // Assicurati che il caricamento termini anche in caso di errore
            });
    }, [dispatch, idUser, tipoCard]);


    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress size={60} />
            </Box>
        );
    }

    if (error) {
        return <div className={styles.error}>Errore: {error}</div>;
    }

    return (
        <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: '20px', marginTop: '2%' }}>
            <StyledCard variant="outlined" sx={{ borderRadius: 3, boxShadow: 3 }}>
                <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        <Person sx={{ marginRight: '8px', fontSize: '2.5rem', color: '#1976d2' }} />
                        Le Mie Collezioni
                    </Typography>
                    <Tooltip title="Crea nuova collezione">
                        <CustomButton
                            variant="contained"
                            startIcon={<FaPlus />}
                            sx={{
                                backgroundColor: '#1976d2',
                                '&:hover': { backgroundColor: '#1565c0' },
                                color: '#fff',
                                borderRadius: 2,
                                padding: '8px 16px',
                            }}
                            onClick={() => handleOpenCreateDialogCollection(setSelectedCollectionId, setOpenCreateDialog)}
                        >
                            Nuova
                        </CustomButton>
                    </Tooltip>
                </CardContent>
            </StyledCard>

            <Grid container spacing={2} sx={{ marginTop: '3%', marginBottom: '5%' }}>
                <Grid item xs={12}>
                    <TabellaMiaCollezione
                        rows={collections}
                        onEditClick={(id) =>
                            handleEditClickCollection(id, setSelectedCollectionId, setSelectedNomeCollection, setOpenEditDialog, collections)
                        }
                        onDeleteClick={(id) =>
                            handleDeleteClickCollection(id, collections, setSelectedCollectionId, setSelectedNomeCollection, setOpenDeleteDialog)
                        }
                    />
                </Grid>
            </Grid>

            <CreateCollection
                open={openCreateDialog}
                onClose={() => handleCloseCreateDialogCollection(setOpenCreateDialog)}
                userId={idUser}
            />
            <EditCollection
                open={openEditDialog}
                onClose={() =>
                    handleCloseEditDialogCollection(setOpenEditDialog, setSelectedCollectionId, dispatch, idUser, tipoCard)
                }
                collectionId={selectedCollectionId}
            />
            <DeleteCollection
                open={openDeleteDialog}
                onClose={() =>
                    handleCloseDeleteDialogCollection(setOpenDeleteDialog, setSelectedCollectionId, setSelectedNomeCollection)
                }
                collectionId={selectedCollectionId}
                nome={selectedNomeCollection}
            />
        </Box>
    );
};

export default MyCollection;
