/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfigurazioniUtenteDTO
 */
export interface ConfigurazioniUtenteDTO {
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtenteDTO
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurazioniUtenteDTO
     */
    collezioneAutomatica?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtenteDTO
     */
    nomeCollezioneAutomatica?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurazioniUtenteDTO
     */
    notificheEmail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtenteDTO
     */
    tipoInterfaccia?: ConfigurazioniUtenteDTOTipoInterfacciaEnum;
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtenteDTO
     */
    preferenzaCollezione?: ConfigurazioniUtenteDTOPreferenzaCollezioneEnum;
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtenteDTO
     */
    linguaPreferita?: ConfigurazioniUtenteDTOLinguaPreferitaEnum;
    /**
     * 
     * @type {number}
     * @memberof ConfigurazioniUtenteDTO
     */
    elementiPerPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurazioniUtenteDTO
     */
    notificaObiettivoCompletato?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurazioniUtenteDTO
     */
    backupAutomatico?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtenteDTO
     */
    frequenzaBackup?: ConfigurazioniUtenteDTOFrequenzaBackupEnum;
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtenteDTO
     */
    tipoFormatoBackup?: ConfigurazioniUtenteDTOTipoFormatoBackupEnum;
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtenteDTO
     */
    utenteId?: string;
}


/**
 * @export
 */
export const ConfigurazioniUtenteDTOTipoInterfacciaEnum = {
    Dark: 'DARK',
    Classic: 'CLASSIC'
} as const;
export type ConfigurazioniUtenteDTOTipoInterfacciaEnum = typeof ConfigurazioniUtenteDTOTipoInterfacciaEnum[keyof typeof ConfigurazioniUtenteDTOTipoInterfacciaEnum];

/**
 * @export
 */
export const ConfigurazioniUtenteDTOPreferenzaCollezioneEnum = {
    Griglia: 'GRIGLIA',
    Lista: 'LISTA'
} as const;
export type ConfigurazioniUtenteDTOPreferenzaCollezioneEnum = typeof ConfigurazioniUtenteDTOPreferenzaCollezioneEnum[keyof typeof ConfigurazioniUtenteDTOPreferenzaCollezioneEnum];

/**
 * @export
 */
export const ConfigurazioniUtenteDTOLinguaPreferitaEnum = {
    Italiano: 'ITALIANO',
    English: 'ENGLISH',
    Espanol: 'ESPANOL'
} as const;
export type ConfigurazioniUtenteDTOLinguaPreferitaEnum = typeof ConfigurazioniUtenteDTOLinguaPreferitaEnum[keyof typeof ConfigurazioniUtenteDTOLinguaPreferitaEnum];

/**
 * @export
 */
export const ConfigurazioniUtenteDTOFrequenzaBackupEnum = {
    Settimanale: 'SETTIMANALE',
    Mensile: 'MENSILE',
    Trimestrale: 'TRIMESTRALE',
    Semestrale: 'SEMESTRALE',
    Annuale: 'ANNUALE'
} as const;
export type ConfigurazioniUtenteDTOFrequenzaBackupEnum = typeof ConfigurazioniUtenteDTOFrequenzaBackupEnum[keyof typeof ConfigurazioniUtenteDTOFrequenzaBackupEnum];

/**
 * @export
 */
export const ConfigurazioniUtenteDTOTipoFormatoBackupEnum = {
    Xlsx: 'XLSX',
    Pdf: 'PDF'
} as const;
export type ConfigurazioniUtenteDTOTipoFormatoBackupEnum = typeof ConfigurazioniUtenteDTOTipoFormatoBackupEnum[keyof typeof ConfigurazioniUtenteDTOTipoFormatoBackupEnum];


/**
 * Check if a given object implements the ConfigurazioniUtenteDTO interface.
 */
export function instanceOfConfigurazioniUtenteDTO(value: object): value is ConfigurazioniUtenteDTO {
    return true;
}

export function ConfigurazioniUtenteDTOFromJSON(json: any): ConfigurazioniUtenteDTO {
    return ConfigurazioniUtenteDTOFromJSONTyped(json, false);
}

export function ConfigurazioniUtenteDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurazioniUtenteDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'collezioneAutomatica': json['collezioneAutomatica'] == null ? undefined : json['collezioneAutomatica'],
        'nomeCollezioneAutomatica': json['nomeCollezioneAutomatica'] == null ? undefined : json['nomeCollezioneAutomatica'],
        'notificheEmail': json['notificheEmail'] == null ? undefined : json['notificheEmail'],
        'tipoInterfaccia': json['tipoInterfaccia'] == null ? undefined : json['tipoInterfaccia'],
        'preferenzaCollezione': json['preferenzaCollezione'] == null ? undefined : json['preferenzaCollezione'],
        'linguaPreferita': json['linguaPreferita'] == null ? undefined : json['linguaPreferita'],
        'elementiPerPagina': json['elementiPerPagina'] == null ? undefined : json['elementiPerPagina'],
        'notificaObiettivoCompletato': json['notificaObiettivoCompletato'] == null ? undefined : json['notificaObiettivoCompletato'],
        'backupAutomatico': json['backupAutomatico'] == null ? undefined : json['backupAutomatico'],
        'frequenzaBackup': json['frequenzaBackup'] == null ? undefined : json['frequenzaBackup'],
        'tipoFormatoBackup': json['tipoFormatoBackup'] == null ? undefined : json['tipoFormatoBackup'],
        'utenteId': json['utenteId'] == null ? undefined : json['utenteId'],
    };
}

export function ConfigurazioniUtenteDTOToJSON(value?: ConfigurazioniUtenteDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'collezioneAutomatica': value['collezioneAutomatica'],
        'nomeCollezioneAutomatica': value['nomeCollezioneAutomatica'],
        'notificheEmail': value['notificheEmail'],
        'tipoInterfaccia': value['tipoInterfaccia'],
        'preferenzaCollezione': value['preferenzaCollezione'],
        'linguaPreferita': value['linguaPreferita'],
        'elementiPerPagina': value['elementiPerPagina'],
        'notificaObiettivoCompletato': value['notificaObiettivoCompletato'],
        'backupAutomatico': value['backupAutomatico'],
        'frequenzaBackup': value['frequenzaBackup'],
        'tipoFormatoBackup': value['tipoFormatoBackup'],
        'utenteId': value['utenteId'],
    };
}

