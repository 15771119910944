import React, {useEffect, useState} from "react";
import {
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Typography
} from "@mui/material";
import {FaTimes} from "react-icons/fa";
import {CartaDTOTipoCardEnum} from "../../../api";
import {transformCardType} from "../../../utility/utils";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../store/store";
import {handleUpdateCollection} from "../../../store/collezioni/CollezioniHandlers";
import {useFetchCollezione} from "../../../store/collezioni/Hooks/useFetchCollezione";
import {
    CancelButton,
    CustomButtonEdit,
    CustomSelectEdit,
    CustomTextFieldEdit,
    Header,
    StyledDialogEditCollection,
    StyledIconButton,
    TitleEdit
} from "../../../utility/utility-css";
import {EditCollectionProps} from "../../../utility/props";


const EditCollection: React.FC<EditCollectionProps> = ({ open, onClose, collectionId }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { collezione, loading, error } = useFetchCollezione(collectionId);
    const [nomeCollezione, setNomeCollezione] = useState("");
    const [tipoCollezione, setTipoCollezione] = useState<CartaDTOTipoCardEnum>();
    const [isPrivata, setIsPrivata] = useState(false);

    useEffect(() => {
        if (collezione) {
            setNomeCollezione(collezione.nome ?? "");
            setTipoCollezione(collezione.tipoCard ?? CartaDTOTipoCardEnum.MagicTheGathering);
            setIsPrivata(collezione.isPrivate ?? false);
        }
    }, [collezione]);

    return (
        <StyledDialogEditCollection
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
           <Header>
                <TitleEdit variant="h6">MODIFICA COLLEZIONE</TitleEdit>
                <StyledIconButton onClick={onClose}>
                    <FaTimes  />
                </StyledIconButton>
            </Header>


            <DialogContent>
                {loading ? (
                    <div style={{textAlign: 'center', padding: '20px'}}>
                        <CircularProgress/>
                    </div>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <>
                        <CustomTextFieldEdit
                            label="Nome Collezione"
                            fullWidth
                            value={nomeCollezione}
                            onChange={(e) => setNomeCollezione(e.target.value)}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Tipo Collezione</InputLabel>
                            <CustomSelectEdit
                                value={tipoCollezione}
                                onChange={(e) => setTipoCollezione(e.target.value as CartaDTOTipoCardEnum)}
                                disabled
                            >
                                {Object.keys(CartaDTOTipoCardEnum).map((key) => (
                                    <MenuItem key={key} value={CartaDTOTipoCardEnum[key as keyof typeof CartaDTOTipoCardEnum]}>
                                        {transformCardType(CartaDTOTipoCardEnum[key as keyof typeof CartaDTOTipoCardEnum])}
                                    </MenuItem>
                                ))}
                            </CustomSelectEdit>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isPrivata}
                                    onChange={(e) => setIsPrivata(e.target.checked)}
                                    sx={{
                                        '&.Mui-checked': {
                                            color: 'darkgreen',
                                        },
                                    }}
                                />

                            }
                            label="Privata"
                            sx={{
                                "& .MuiFormControlLabel-label": {
                                    fontFamily: "'Nunito', sans-serif !important",
                                    fontSize: '20px'
                                }
                            }}
                        />


                    </>
                )}
            </DialogContent>
            <DialogActions>
                <CustomButtonEdit
                    onClick={() => {
                        handleUpdateCollection(dispatch, collectionId, nomeCollezione, isPrivata)
                            .then(() => {
                                onClose();
                            })
                            .catch((error) => {
                                console.error("Errore:", error);
                            });
                    }}
                >
                    Modifica
                </CustomButtonEdit>
                <CancelButton onClick={onClose}>Annulla</CancelButton>
            </DialogActions>
        </StyledDialogEditCollection>
    );
};

export default EditCollection;
