import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemText,
    Box,
} from '@mui/material';

const TermsPage: React.FC = () => {
    return (
        <Box
            className="terms-container"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: '#f4f6f8',
                padding: 3,
            }}
        >
            <Card
                sx={{
                    maxWidth: 900,
                    boxShadow: 6,
                    borderRadius: 2,
                    backgroundColor: '#fff',
                    overflow: 'hidden',
                }}
            >
                <CardHeader
                    title={
                        <Typography
                            variant="h4"
                            align="center"
                            sx={{ fontWeight: 'bold',
                                color: 'white' }}
                        >
                            Termini di Utilizzo di Collectoon
                        </Typography>
                    }
                    sx={{
                        backgroundColor: '#1976d2',
                        color: 'white',
                        padding: 2,
                        borderBottom: '2px solid #e3f2fd',
                    }}
                />
                <CardContent sx={{ padding: 4 }}>
                    <Typography
                        variant="body1"
                        paragraph
                        sx={{ color: '#555', marginBottom: 2 }}
                    >
                        Qui di seguito le condizioni generali del contratto del
                        <strong> 01.01.2025</strong> di Collectoon - d'ora in poi
                        denominato "Fornitore".
                    </Typography>
                    <Divider sx={{ marginY: 3 }} />

                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: 'bold', color: '#1976d2', marginBottom: 2 }}
                    >
                        1. Registrazione
                    </Typography>
                    <List sx={{ paddingLeft: 3 }}>
                        <ListItem>
                            <ListItemText>
                                Prerequisito per l’utilizzo della piattaforma online del Fornitore
                                è la creazione di un account utente. La registrazione per
                                l’utilizzo della piattaforma è gratuita.
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                Al momento della registrazione, è necessario inserire nome, indirizzo postale e indirizzo e-mail, E'  inoltre possibile scegliere un nome utente e una password.
                                È altresì necessario confermare di aver letto e accettato le Condizioni Generali e di aver ricevuto le istruzioni per il recesso.
                                Per verificare il tuo indirizzo e-mail Il Fornitore ti invierà un'e-mail con un codice di attivazione.
                                Con tale e-mail, il Fornitore presenta un'offerta per la stipula di un contratto d'uso.
                                Con l’inserimento del codice di attivazione dopo il primo login e l’attivazione del tuo account utente, accetti la suddetta offerta.
                                Il Fornitore si riserva tuttavia il diritto di rifiutare la registrazione senza doverne comunicare il motivo.
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                Tutte le informazioni (fatta eccezione per nome e cognome) potranno essere modificate o completate in un secondo momento nelle impostazioni del tuo profilo.
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                La password da te scelta dovrà essere mantenuta riservata. In particolare, dovrai garantire che terzi non entrino in possesso dei tuoi dati di accesso,
                                che potrebbero utilizzare per il tuo account utente. Nel caso in cui tu smarrisca la tua password o sospetti che terzi stiano usando il tuo profilo utente,
                                sei tenuto ad informare immediatamente il Fornitore.
                                Per proteggere il tuo account da accessi non autorizzati di terze parti, dovrai cambiare la tua password con frequenza regolare.
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                Qualsiasi persona fisica e legalmente responsabile di età superiore a 18 anni, nonché persone giuridiche possono registrarsi come utente.
                                La registrazione di un account per conto terzi senza consenso nonché molteplici account per un singolo utente sono espressamente vietati. Il Fornitore si riserva il diritto di richiedere che,
                                prima dell'attivazione di un account, venga esibita adeguata documentazione comprovante quanto precede (estratto del registro delle imprese,
                                licenza commerciale, autorizzazioni amministrative).
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                        <ListItemText>
                            I dati richiesti durante la registrazione devono essere forniti in modo esaustivo e corretto.
                        </ListItemText>
                    </ListItem>
                    </List>

                    <Divider sx={{ marginY: 3 }} />

                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: 'bold', color: '#1976d2' }}
                    >
                        3. Servizi Offerti
                    </Typography>
                    <List sx={{ paddingLeft: 3 }}>
                        <ListItem>
                            <ListItemText
                                primary="Creazione di Collezioni"
                                secondary="Gli utenti registrati hanno la possibilità di creare collezioni di Trading Card Game (TCG) specifiche.
                                Ogni collezione può essere configurata come pubblica, rendendola visibile a tutti, o privata, accessibile solo all'utente stesso.
                                Gli utenti possono aggiungere carte dello stesso tipo alla propria collezione e, per una maggiore praticità, esportare l'elenco in formato PDF."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Monitoraggio dei Prezzi delle Carte"
                                secondary="Gli utenti possono visualizzare i prezzi aggiornati delle carte basandosi sui dati del mercato.
                                Le valutazioni vengono aggiornate quotidianamente a mezzanotte, assicurando così informazioni sempre precise e tempestive."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Valore delle Collezioni"
                                secondary="Gli utenti hanno la possibilità di monitorare il valore delle proprie collezioni in base alle fluttuazioni del mercato.
                                È possibile verificare l'incremento o il decremento del valore della collezione nel tempo,
                                ottenendo così una visione chiara della performance economica delle proprie carte."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Interazione con la Community"
                                secondary="Gli utenti possono esplorare le collezioni pubbliche condivise da altri membri della community,
                                lasciare commenti, valutazioni e dimostrare il proprio supporto.
                                Questa funzione promuove l'interazione e il senso di appartenenza all'interno della comunità di appassionati."
                            />
                        </ListItem>
                    </List>

                    <Divider sx={{ marginY: 3 }} />

                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: 'bold', color: '#1976d2' }}
                    >
                        4. Responsabilità Fornitore
                    </Typography>
                    <List sx={{ paddingLeft: 3 }}>
                        <ListItem>
                            <ListItemText
                                primary="Perdita di dati"
                                secondary="Il Fornitore non è responsabile per i danni derivanti dalla perdita di dati,
                                a condizione che tali danni avrebbero potuto essere evitati tramite il backup regolare e completo di tutti i dati pertinenti da parte dell'utente."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Dati personali e applicazioni di terzi"
                                secondary="Il Fornitore non è responsabile per eventuali danni o uso scorretto di dati personali che potrebbero verificarsi in conseguenza
                                dell’uso di applicazioni gestite da terzi."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Contratti tra utenti"
                                secondary="Il Fornitore non è responsabile per la formazione, l’attuazione e la conclusione dei contratti tra utenti,
                                limitandosi a fornire la piattaforma per la pubblicazione delle offerte."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Danni non intrinseci al contratto"
                                secondary="La responsabilità del Fornitore per danni non correlati direttamente al contratto è esclusa,
                                a meno che tali danni non pregiudichino la vita, l'integrità fisica o la salute,
                                siano stati causati intenzionalmente o per colpa grave, derivino dall'assenza di proprietà garantite o
                                dalla violazione colposa di obblighi contrattuali essenziali (doveri cardinali)."
                            />
                        </ListItem>
                    </List>

                    <Divider sx={{ marginY: 3 }} />

                    {/*<Typography*/}
                    {/*    variant="h6"*/}
                    {/*    gutterBottom*/}
                    {/*    sx={{ fontWeight: 'bold', color: '#1976d2' }}*/}
                    {/*>*/}
                    {/*    10. Contatti*/}
                    {/*</Typography>*/}
                    {/*<Typography*/}
                    {/*    variant="body1"*/}
                    {/*    paragraph*/}
                    {/*    sx={{ color: '#555' }}*/}
                    {/*>*/}
                    {/*    Per domande o dubbi relativi a questi Termini, contattaci via email:*/}
                    {/*    <strong> staff@collectooon.com</strong>.*/}
                    {/*</Typography>*/}
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: 'bold', color: '#1976d2' }}
                    >
                       5. Responsabilità Utente
                    </Typography>
                    <List sx={{ paddingLeft: 3 }}>
                        <ListItem>
                            <ListItemText>
                                Assumi piena responsabilità per qualsiasi concessione di diritti, contributi, contenuti del tuo profilo utente ed offerte.
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                Nel caso in cui i servizi del Fornitore siano forniti tramite una terza parte a causa di una tua violazione degli obblighi relativi alla concessione di diritti,
                                sarai tenuto ad indennizzare il Fornitore completamente e alla prima richiesta per qualsiasi responsabilità e costi, incluse le spese legali.
                                Il Provider ti informerà immediatamente riguardo a eventuali reclami e ti offrirà l'opportunità di iniziare una difesa,
                                qualora ciò sia legalmente necessario e/o possibile.
                                Lo stesso principio si applica per i reclami di terze parti derivanti da contenuti che violano diritti di terzi,
                                in particolare diritti d'uso e sfruttamento ai sensi della legge sul copyright.
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                Sarai responsabile nei confronti del Provider e di terze parti per i tuoi contributi, commenti e qualsiasi contenuto del tuo profilo utente,
                                specialmente per quanto riguarda la loro accuratezza. Il Provider si riserva il diritto di sospendere, a sua discrezione,
                                l'utente nei casi più gravi.
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                L'utilizzo della piattaforma online tramite applicazioni di terze parti e programmi applicativi simili avverrà a tuo esclusivo rischio.
                            </ListItemText>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        </Box>
    );
};

export default TermsPage;
