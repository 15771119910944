import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import styles from './tendenza-prezzo.module.css';
import { handleSelectCard } from "../../store/tendenza/TendenzaHandler";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TendenzaPrezzo = ({ selectedCard, setSelectedCard }: { selectedCard: any; setSelectedCard: any }) => {
    const [prices, setPrices] = useState<number[]>([]);
    const [dates, setDates] = useState<string[]>([]);
    const [filteredPrices, setFilteredPrices] = useState<number[]>([]);
    const [filteredDates, setFilteredDates] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [prevSelectedCardId, setPrevSelectedCardId] = useState<string | null>(null);
    const [tendenza, setTendenza] = useState<any | null>(null);

    useEffect(() => {
        const fetchCardData = async () => {
            await handleSelectCard(
                selectedCard,
                setLoading,
                prevSelectedCardId,
                setSelectedCard,
                setPrevSelectedCardId,
                setTendenza
            );
        };

        fetchCardData();
    }, [selectedCard, prevSelectedCardId]);

    useEffect(() => {
        if (tendenza && tendenza.length > 0) {
            const combinedPrices = tendenza.map((line: any) =>
                parseFloat(line.normale || line.foil || line.prezzoChiusura || 0)
            );

            const extractedDates = tendenza.map((line: any) =>
                line.data ? new Date(parseInt(line.data, 10) * 1000).toLocaleDateString() : ''
            );

            setPrices(combinedPrices);
            setDates(extractedDates);
            setFilteredPrices(combinedPrices);
            setFilteredDates(extractedDates);
        }
    }, [tendenza]);

    const filterDataByRange = (range: '3m' | '6m' | '1y') => {
        const today = new Date();
        let startDate: Date;

        switch (range) {
            case '3m':
                startDate = new Date(today.setMonth(today.getMonth() - 3));
                break;
            case '6m':
                startDate = new Date(today.setMonth(today.getMonth() - 6));
                break;
            case '1y':
                startDate = new Date(today.setFullYear(today.getFullYear() - 1));
                break;
            default:
                startDate = new Date(0); // Nessun filtro
        }

        const filteredDates = dates.filter((date) => new Date(date) >= startDate);
        const startIndex = dates.length - filteredDates.length;
        const filteredPrices = prices.slice(startIndex);

        setFilteredDates(filteredDates);
        setFilteredPrices(filteredPrices);
    };

    const data = {
        labels: filteredDates,
        datasets: [
            {
                label: 'Prezzo (€)',
                data: filteredPrices,
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top' as const,
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => `€${context.raw.toFixed(2)}`,
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Data',
                    color: '#FF5733',
                    font: {
                        size: 10,
                        weight: 'bold',
                    },
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Prezzo (€)',
                    color: '#FF5733',
                    font: {
                        size: 16,
                        weight: 'bold',
                    },
                    padding: {
                        top: 20,
                        bottom: 10,
                    },
                },
                beginAtZero: false,
            },
        },
    } as const;

    return (
        <div style={{position: 'relative', textAlign: 'center', marginBottom: '20px'}}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center', // Centra i pulsanti orizzontalmente
                    alignItems: 'center',   // Centra verticalmente nel contenitore
                    gap: '10px',            // Spaziatura uniforme tra i pulsanti
                }}
            >
                <button
                    style={{
                        marginBottom: '20px',
                        fontSize: '0.8rem',
                        padding: '6px 12px',
                        borderRadius: '8px',
                        backgroundColor: '#3f51b5',
                        color: 'white',
                        border: 'none',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease, transform 0.2s ease',
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#2c387e')}
                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#3f51b5')}
                    onClick={() => filterDataByRange('3m')}
                >
                    Ultimi 3 mesi
                </button>
                <button
                    style={{
                        marginBottom: '20px',
                        fontSize: '0.8rem',
                        padding: '6px 12px',
                        borderRadius: '8px',
                        backgroundColor: '#3f51b5',
                        color: 'white',
                        border: 'none',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease, transform 0.2s ease',
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#2c387e')}
                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#3f51b5')}
                    onClick={() => filterDataByRange('6m')}
                >
                    Ultimi 6 mesi
                </button>
                <button
                    style={{
                        marginBottom: '20px',
                        fontSize: '0.8rem',
                        padding: '6px 12px',
                        borderRadius: '8px',
                        backgroundColor: '#3f51b5',
                        color: 'white',
                        border: 'none',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease, transform 0.2s ease',
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#2c387e')}
                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#3f51b5')}
                    onClick={() => filterDataByRange('1y')}
                >
                    Ultimo anno
                </button>
            </div>

            <div className={styles.chartContainer}
                 style={{width: '70%', height: '70%', margin: '0 auto', paddingTop: '50px'}}>
                {loading ? (
                    <p>Caricamento in corso...</p>
                ) : (
                    <Line data={data} options={options}/>
                )}
            </div>
        </div>


    );
};

export default TendenzaPrezzo;
