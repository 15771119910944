/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UtenteInsertDTO
 */
export interface UtenteInsertDTO {
    /**
     * 
     * @type {string}
     * @memberof UtenteInsertDTO
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UtenteInsertDTO
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UtenteInsertDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UtenteInsertDTO
     */
    nome?: string;
    /**
     * 
     * @type {string}
     * @memberof UtenteInsertDTO
     */
    cognome?: string;
    /**
     * 
     * @type {Date}
     * @memberof UtenteInsertDTO
     */
    dataNascita?: Date;
}

/**
 * Check if a given object implements the UtenteInsertDTO interface.
 */
export function instanceOfUtenteInsertDTO(value: object): value is UtenteInsertDTO {
    return true;
}

export function UtenteInsertDTOFromJSON(json: any): UtenteInsertDTO {
    return UtenteInsertDTOFromJSONTyped(json, false);
}

export function UtenteInsertDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UtenteInsertDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'username': json['username'] == null ? undefined : json['username'],
        'password': json['password'] == null ? undefined : json['password'],
        'email': json['email'] == null ? undefined : json['email'],
        'nome': json['nome'] == null ? undefined : json['nome'],
        'cognome': json['cognome'] == null ? undefined : json['cognome'],
        'dataNascita': json['dataNascita'] == null ? undefined : (new Date(json['dataNascita'])),
    };
}

export function UtenteInsertDTOToJSON(value?: UtenteInsertDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'username': value['username'],
        'password': value['password'],
        'email': value['email'],
        'nome': value['nome'],
        'cognome': value['cognome'],
        'dataNascita': value['dataNascita'] == null ? undefined : ((value['dataNascita']).toISOString().substring(0,10)),
    };
}

