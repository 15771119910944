/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Problem,
  WishlistUtenteDTO,
  WishlistUtenteInsertDTO,
} from '../models/index';
import {
    ProblemFromJSON,
    ProblemToJSON,
    WishlistUtenteDTOFromJSON,
    WishlistUtenteDTOToJSON,
    WishlistUtenteInsertDTOFromJSON,
    WishlistUtenteInsertDTOToJSON,
} from '../models/index';

export interface AddCardWishlistRequest {
    wishlistUtenteInsertDTO: WishlistUtenteInsertDTO;
}

export interface DeleteWishlistByCardRequest {
    idCarta: string;
    idUser: string;
}

export interface GetCardWishlistByUtenteRequest {
    idUser: string;
    tipoTcg: string;
}

/**
 * 
 */
export class WishlistRestApi extends runtime.BaseAPI {

    /**
     * L\'inserimento richiede obbligatoriamente l\'id della carta e l\'id dell\'utente
     * inserimento di una carta ad una wishlist utente
     */
    async addCardWishlistRaw(requestParameters: AddCardWishlistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WishlistUtenteDTO>> {
        if (requestParameters['wishlistUtenteInsertDTO'] == null) {
            throw new runtime.RequiredError(
                'wishlistUtenteInsertDTO',
                'Required parameter "wishlistUtenteInsertDTO" was null or undefined when calling addCardWishlist().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/wishlist`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WishlistUtenteInsertDTOToJSON(requestParameters['wishlistUtenteInsertDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WishlistUtenteDTOFromJSON(jsonValue));
    }

    /**
     * L\'inserimento richiede obbligatoriamente l\'id della carta e l\'id dell\'utente
     * inserimento di una carta ad una wishlist utente
     */
    async addCardWishlist(requestParameters: AddCardWishlistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WishlistUtenteDTO> {
        const response = await this.addCardWishlistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * E\' necessario l\'id della Carta
     * Elimina una carta dalla wishlist
     */
    async deleteWishlistByCardRaw(requestParameters: DeleteWishlistByCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WishlistUtenteDTO>> {
        if (requestParameters['idCarta'] == null) {
            throw new runtime.RequiredError(
                'idCarta',
                'Required parameter "idCarta" was null or undefined when calling deleteWishlistByCard().'
            );
        }

        if (requestParameters['idUser'] == null) {
            throw new runtime.RequiredError(
                'idUser',
                'Required parameter "idUser" was null or undefined when calling deleteWishlistByCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/wishlist/{idCarta}/{idUser}`.replace(`{${"idCarta"}}`, encodeURIComponent(String(requestParameters['idCarta']))).replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters['idUser']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WishlistUtenteDTOFromJSON(jsonValue));
    }

    /**
     * E\' necessario l\'id della Carta
     * Elimina una carta dalla wishlist
     */
    async deleteWishlistByCard(requestParameters: DeleteWishlistByCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WishlistUtenteDTO> {
        const response = await this.deleteWishlistByCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera tutte le carte associate alla wishlist di un utente tramite il suo ID
     * Recupera la wishlist di un utente
     */
    async getCardWishlistByUtenteRaw(requestParameters: GetCardWishlistByUtenteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WishlistUtenteDTO>> {
        if (requestParameters['idUser'] == null) {
            throw new runtime.RequiredError(
                'idUser',
                'Required parameter "idUser" was null or undefined when calling getCardWishlistByUtente().'
            );
        }

        if (requestParameters['tipoTcg'] == null) {
            throw new runtime.RequiredError(
                'tipoTcg',
                'Required parameter "tipoTcg" was null or undefined when calling getCardWishlistByUtente().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['idUser'] != null) {
            queryParameters['idUser'] = requestParameters['idUser'];
        }

        if (requestParameters['tipoTcg'] != null) {
            queryParameters['tipoTcg'] = requestParameters['tipoTcg'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/wishlist/carte-utente`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WishlistUtenteDTOFromJSON(jsonValue));
    }

    /**
     * Recupera tutte le carte associate alla wishlist di un utente tramite il suo ID
     * Recupera la wishlist di un utente
     */
    async getCardWishlistByUtente(requestParameters: GetCardWishlistByUtenteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WishlistUtenteDTO> {
        const response = await this.getCardWishlistByUtenteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
