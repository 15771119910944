import React, {useState} from 'react';
import {toast} from "react-toastify";
import {SendEmailPasswordRequest, UtenteRestApi} from "../../api";
import {Divider} from "@mui/material";
import {Link} from "react-router-dom";

const api = new UtenteRestApi();

const RecoveryPassword: React.FC = () => {
    const [username, setUsername] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handlePasswordRecovery = async () => {
        if (!username) {
            toast.error("Per favore, inserisci il tuo username.");
            return;
        }

        setIsSubmitting(true);
        try {
            const request: SendEmailPasswordRequest = {
                usernameUtente: username
            }
            await api.sendEmailPasswordRaw(request);
            toast.success("Abbiamo inviato una mail al tuo indirizzo di posta elettronica. Controlla la tua casella, anche tra lo spam.");
        } catch (error: any) {
            if (error.response) {
                const errorResponse = await error.response.json();
                toast.error(errorResponse.message);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            background: 'linear-gradient(135deg, #6a11cb, #007bff)',
            fontFamily: '"Montserrat", sans-serif',
            color: '#ffffff',
            padding: '20px',
        }}>
            <div style={{
                backgroundColor: '#ffffff',
                padding: '40px',
                borderRadius: '12px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                textAlign: 'center',
                maxWidth: '400px',
                width: '100%',
                color: '#333',
            }}>
                <h2 style={{color: '#007bff', marginBottom: '20px'}}>Recupera Password</h2>
                <input
                    type="text"
                    placeholder="Inserisci il tuo username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    style={{
                        width: '100%',
                        padding: '12px',
                        marginBottom: '20px',
                        borderRadius: '8px',
                        border: '1px solid #ccc',
                        fontSize: '16px',
                        boxSizing: 'border-box',
                    }}
                    disabled={isSubmitting}
                />
                <button
                    onClick={handlePasswordRecovery}
                    disabled={isSubmitting}
                    style={{
                        width: '100%',
                        padding: '12px',
                        backgroundColor: isSubmitting ? '#aaa' : '#007bff',
                        color: '#ffffff',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: isSubmitting ? 'not-allowed' : 'pointer',
                        transition: 'background-color 0.3s',
                        boxShadow: '0 4px 6px rgba(0, 123, 255, 0.4)',
                    }}
                >
                    {isSubmitting ? "Invio..." : "Invia"}
                </button>
                <Divider style={{marginTop: '20px', marginBottom: '15px'}}/>
                <p className="register-link">
                    <span style={{marginTop: '15px', display: 'inline-block', fontFamily: '"Montserrat", sans-serif'}}>
                        Vuoi tornare alla login?{' '}
                    </span>
                    <Link to="/login"> Accedi Ora!</Link>
                    <span style={{marginTop: '15px', display: 'inline-block', fontFamily: '"Montserrat", sans-serif'}}>
                        Non sei ancora registrato?{' '}
                    </span>
                    <Link to="/registrati"> Registrati!</Link>
                </p>
            </div>
        </div>
    );
};

export default RecoveryPassword;
