/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PageRequestDTO } from './PageRequestDTO';
import {
    PageRequestDTOFromJSON,
    PageRequestDTOFromJSONTyped,
    PageRequestDTOToJSON,
} from './PageRequestDTO';

/**
 * 
 * @export
 * @interface CommentoSearchRequest
 */
export interface CommentoSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof CommentoSearchRequest
     */
    idCollezione?: string;
    /**
     * 
     * @type {PageRequestDTO}
     * @memberof CommentoSearchRequest
     */
    pageable?: PageRequestDTO;
}

/**
 * Check if a given object implements the CommentoSearchRequest interface.
 */
export function instanceOfCommentoSearchRequest(value: object): value is CommentoSearchRequest {
    return true;
}

export function CommentoSearchRequestFromJSON(json: any): CommentoSearchRequest {
    return CommentoSearchRequestFromJSONTyped(json, false);
}

export function CommentoSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentoSearchRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'idCollezione': json['idCollezione'] == null ? undefined : json['idCollezione'],
        'pageable': json['pageable'] == null ? undefined : PageRequestDTOFromJSON(json['pageable']),
    };
}

export function CommentoSearchRequestToJSON(value?: CommentoSearchRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idCollezione': value['idCollezione'],
        'pageable': PageRequestDTOToJSON(value['pageable']),
    };
}

