/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResourceOPDTO
 */
export interface ResourceOPDTO {
    /**
     * 
     * @type {number}
     * @memberof ResourceOPDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ResourceOPDTO
     */
    idSrc?: number;
    /**
     * 
     * @type {string}
     * @memberof ResourceOPDTO
     */
    nome?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceOPDTO
     */
    imageURL?: string;
}

/**
 * Check if a given object implements the ResourceOPDTO interface.
 */
export function instanceOfResourceOPDTO(value: object): value is ResourceOPDTO {
    return true;
}

export function ResourceOPDTOFromJSON(json: any): ResourceOPDTO {
    return ResourceOPDTOFromJSONTyped(json, false);
}

export function ResourceOPDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceOPDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'idSrc': json['idSrc'] == null ? undefined : json['idSrc'],
        'nome': json['nome'] == null ? undefined : json['nome'],
        'imageURL': json['imageURL'] == null ? undefined : json['imageURL'],
    };
}

export function ResourceOPDTOToJSON(value?: ResourceOPDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'idSrc': value['idSrc'],
        'nome': value['nome'],
        'imageURL': value['imageURL'],
    };
}

