import { configureStore } from '@reduxjs/toolkit';
import commentsReducer from './commenti/CommentsSlice';
import obiettiviReducer from './obiettivi/ObiettiviSlice';
import collezioniReducer from './collezioni/CollezioniSlice';
import carteReducer from './carte/CarteSlice';


const store = configureStore({
    reducer: {
        comments: commentsReducer,
        obiettivi: obiettiviReducer,
        collezioni: collezioniReducer,
        carte: carteReducer,
    },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
