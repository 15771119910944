import React, { useEffect, useState } from 'react';
import { GetCardWishlistByUtenteRequest, WishlistRestApi } from "../api";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import styles from "../styles/MyCollectionDetail.module.css";
import CardCollectionList from "../components/Card/Lista/Card-list-collection";
import SelectTipoCollezione from "../components/input/TipiCollezione/SelectTipoCollezione";
import CircularProgress from '@mui/material/CircularProgress';

const wishlistApi = new WishlistRestApi();

const WishlistPage: React.FC = () => {
    const [carte, setCarte] = useState<any[]>([]);
    const [utente, setUtente] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [tipoCollezione, setTipoCollezione] = useState<string>("");

    const fetchCarte = async (tipoCollezioneFilter: string = "") => {
        setIsLoading(true);

        const utente = localStorage.getItem('user');
        if (!utente) {
            setError('Utente non trovato nel localStorage');
            setIsLoading(false);
            return;
        }

        const datiUtente = JSON.parse(utente);
        setUtente(datiUtente);

        if (!datiUtente.id) {
            setError('ID utente non trovato');
            setIsLoading(false);
            return;
        }

        const requestData: GetCardWishlistByUtenteRequest = {
            idUser: datiUtente.id,
            tipoTcg: tipoCollezioneFilter,
        };

        try {
            const response = await wishlistApi.getCardWishlistByUtenteRaw(requestData);
            const jsonResponse = await response.raw.json();

            if (jsonResponse && jsonResponse.length > 0) {
                setCarte(jsonResponse);
                setError(null); // Reset dell'errore
            } else {
                setCarte([]);
                setError('Nessuna carta trovata.');
            }
        } catch (error) {
            setError('Errore durante il recupero delle carte.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCarte(tipoCollezione);
    }, [tipoCollezione]);

    const handleTipoCollezioneChange = (tipo: string) => {
        setTipoCollezione(tipo); // Aggiorna lo stato e avvia automaticamente la ricerca
    };

    const breadcrumbItems = [
        { name: 'Home', link: '/' },
        { name: `Wishlist`, link: '/wishlist-utente' },
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} />
            <div className={styles.profileContainer}>
                <div className={styles.profileHeader}>
                    <div className={styles.userInfo}>
                        <h1 className={styles.collectionName}>
                            WishList {utente?.username?.toUpperCase()}
                        </h1>
                    </div>
                </div>

                <SelectTipoCollezione onSelectionChange={handleTipoCollezioneChange} />

                {isLoading ? (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <CircularProgress />
                    </div>
                ) : error ? (
                    <div style={{ textAlign: 'center', color: 'red', marginTop: '20px' }}>{error}</div>
                ) : (
                    <>
                        {carte.length > 0 && (
                            <div className={styles.statsContainer}>
                                <div className={styles.stats}>
                                    <span>{carte.length}</span>
                                    <span>TOTALE CARTE DESIDERATE</span>
                                </div>
                            </div>
                        )}

                        <div className={styles.cardGridContainer}>
                            {carte.length > 0 ? (
                                <CardCollectionList
                                    cardsWishlist={carte}
                                    idCollection={undefined}
                                    cardsPerPage={12}
                                    showDeleteIcon={true}
                                />
                            ) : (
                                <div style={{
                                    textAlign: 'center',
                                    color: '#A9A9A9',
                                    fontSize: '1.8em',
                                    fontFamily: '"Montserrat", sans-serif',
                                    marginTop: '20px'
                                }}>
                                    Non ci sono elementi da visualizzare.
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default WishlistPage;
