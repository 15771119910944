import React, { useState } from 'react';
import CardOnePieceCollection from '../OnePiece/SingolaCartaOnePieceCollection/CardOnePieceCollection';
import styles from './CardListCollection.module.css';
import { CardCollectionListProps } from "../../../utility/props";

const CardCollectionList: React.FC<CardCollectionListProps> = ({
                                                                   idCollection,
                                                                   cardsCollection,
                                                                   cardsWishlist,
                                                                   cardsPerPage = 8,
                                                                   showDeleteIcon,
                                                                   collectionType
                                                               }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const cards = cardsCollection || cardsWishlist || [];
    const totalCards = cards.length;

    const totalPages = Math.ceil(totalCards / cardsPerPage);
    const startIndex = (currentPage - 1) * cardsPerPage;
    const currentCards = cards.slice(startIndex, startIndex + cardsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div>
            <div className={styles.cardList}>
                {totalCards === 0 ? (
                    <div className={styles.noCardsMessage}>
                        Non c'è nulla da vedere qui..
                    </div>
                ) : (
                    currentCards.map((card, index) => {
                        const cardData = cardsCollection ? card?.carta : card;
                        const qty = cardsCollection ? card?.qtyCarta : 0;

                        return (
                            <CardOnePieceCollection
                                key={index}
                                qty={qty}
                                alternativeArt={cardData?.alternativeArt}
                                appartenenza={cardData?.appartenenza}
                                attributo={cardData?.attacco}
                                colore={cardData?.colore}
                                costo={cardData?.costoOp}
                                effettto={cardData?.descrizioneOp}
                                espansione={cardData?.numeroEspansione}
                                image={cardData?.imageUrl}
                                name={cardData?.name}
                                nomeEspansione={cardData?.sourceName}
                                potenza={cardData?.potenza}
                                rarita={cardData?.rarity}
                                collezioneId={idCollection}
                                idCarta={cardData?.id}
                                showDeleteIcon={showDeleteIcon}
                                collectionType={collectionType}
                                prezzo={cardData?.prezzo}
                                carta={cardData}
                            />
                        );
                    })
                )}
            </div>
            {totalCards >= 10 && (
                <div className={styles.pagination}>
                    <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                        Previous
                    </button>
                    <span>{currentPage} / {totalPages}</span>
                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                        Next
                    </button>
                </div>
            )}
        </div>
    );
};

export default CardCollectionList;
