import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { UtenteRestApi } from "../api";
import { toast } from "react-toastify";
import {Box, Button, Divider, Grid, IconButton, InputAdornment, TextField, Typography, useTheme} from '@mui/material';
import {Visibility, VisibilityOff} from "@mui/icons-material";

const api = new UtenteRestApi();

const Registrazione: React.FC = () => {
    const [nome, setNome] = useState<string>('');
    const [cognome, setCognome] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [dataNascita, setDataNascita] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const navigate = useNavigate();
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const validateForm = (): boolean => {
        const namePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ]+$/;
        const usernamePattern = /^[A-Za-z0-9_]{4,}$/;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if (!namePattern.test(nome)) {
            setError('Il nome deve contenere solo lettere.');
            return false;
        }

        if (!namePattern.test(cognome)) {
            setError('Il cognome deve contenere solo lettere.');
            return false;
        }

        // if (!usernamePattern.test(username)) {
        //     setError('Lo username deve essere lungo almeno 4 caratteri e non contenere caratteri speciali.');
        //     return false;
        // }

        if (!emailPattern.test(email)) {
            setError('Inserisci un\'email valida.');
            return false;
        }

        // if (!passwordPattern.test(password)) {
        //     setError('La password deve contenere almeno 8 caratteri, una maiuscola, un numero e un simbolo speciale.');
        //     return false;
        // }

        if (password !== confirmPassword) {
            setError('Le password non corrispondono.');
            return false;
        }

        const birthDate = new Date('1900-01-01');
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();
        if (age < 14 || (age === 14 && (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)))) {
            setError('Devi avere almeno 14 anni.');
            return false;
        }

        return true;
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setError(null);
        setSuccess(null);

        if (validateForm()) {
            try {
                const data = {
                    utenteInsertDTO: {
                        username: username,
                        nome: nome,
                        cognome: cognome,
                        email: email,
                        password: password,
                        dataNascita: new Date("1900-01-01"),
                    }
                };
                api.createUserRaw(data).then(r => {
                    toast.success("Registrazione avvenuta con successo! Controlla la tua mail per attivare l'account!");
                    navigate('/login');
                });
            } catch (error) {
                toast.error("Errore nella registrazione dell'utente.");
            }
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '60vh',
                backgroundColor: theme.palette.grey[100],
                padding: 2
            }}
        >
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    padding: 4,
                    borderRadius: 3,
                    boxShadow: theme.shadows[5],
                    maxWidth: 600,
                    width: '100%',
                    [theme.breakpoints.down('sm')]: {
                        padding: 2,
                    }
                }}
            >
                <Typography variant="h4" component="h1" color="primary" fontWeight='bold'  gutterBottom align="center" sx={{ mb: 3 }}>
                    REGISTRAZIONE
                </Typography>
                <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.12)', mb: 3 }} />
                {error && <Typography color="error" align="center">{error}</Typography>}
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Nome"
                                variant="outlined"
                                fullWidth
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                required
                                sx={{
                                    '& .MuiInputBase-root': {
                                        borderRadius: 3,
                                    },
                                    '& .MuiFormLabel-root': {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Cognome"
                                variant="outlined"
                                fullWidth
                                value={cognome}
                                onChange={(e) => setCognome(e.target.value)}
                                required
                                sx={{
                                    '& .MuiInputBase-root': {
                                        borderRadius: 3,
                                    },
                                    '& .MuiFormLabel-root': {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Username"
                                variant="outlined"
                                fullWidth
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                sx={{
                                    '& .MuiInputBase-root': {
                                        borderRadius: 3,
                                    },
                                    '& .MuiFormLabel-root': {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                type="email"
                                variant="outlined"
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                sx={{
                                    '& .MuiInputBase-root': {
                                        borderRadius: 3,
                                    },
                                    '& .MuiFormLabel-root': {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                variant="outlined"
                                fullWidth
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        borderRadius: 3,
                                    },
                                    '& .MuiFormLabel-root': {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Conferma Password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                variant="outlined"
                                fullWidth
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        borderRadius: 3,
                                    },
                                    '& .MuiFormLabel-root': {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color: theme.palette.common.white,
                                    borderRadius: 19,
                                    padding: '12px 20px',
                                    fontSize: '1rem',
                                    '&:hover': {
                                        backgroundColor: theme.palette.secondary.dark,
                                    },
                                    boxShadow: theme.shadows[2],
                                }}
                            >
                                REGISTRATI
                            </Button>
                            <Divider style={{marginTop: '15px', marginBottom: '15px' }} />
                            <p className="login-link">
                                <span style={{marginTop: '15px', display: 'inline-block', fontFamily: '"Montserrat", sans-serif'}}>
                                 Sei Già registrato?{' '}
                                </span>
                                <Link to="/login"> Accedi</Link>
                            </p>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    );
};

export default Registrazione;
