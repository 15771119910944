/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CambiaPasswordDTO
 */
export interface CambiaPasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof CambiaPasswordDTO
     */
    nuovaPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof CambiaPasswordDTO
     */
    confermaPassword?: string;
}

/**
 * Check if a given object implements the CambiaPasswordDTO interface.
 */
export function instanceOfCambiaPasswordDTO(value: object): value is CambiaPasswordDTO {
    return true;
}

export function CambiaPasswordDTOFromJSON(json: any): CambiaPasswordDTO {
    return CambiaPasswordDTOFromJSONTyped(json, false);
}

export function CambiaPasswordDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CambiaPasswordDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'nuovaPassword': json['nuovaPassword'] == null ? undefined : json['nuovaPassword'],
        'confermaPassword': json['confermaPassword'] == null ? undefined : json['confermaPassword'],
    };
}

export function CambiaPasswordDTOToJSON(value?: CambiaPasswordDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'nuovaPassword': value['nuovaPassword'],
        'confermaPassword': value['confermaPassword'],
    };
}

