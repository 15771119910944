/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RangeFilterInteger
 */
export interface RangeFilterInteger {
    /**
     * 
     * @type {number}
     * @memberof RangeFilterInteger
     */
    equals?: number;
    /**
     * 
     * @type {number}
     * @memberof RangeFilterInteger
     */
    notEquals?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RangeFilterInteger
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof RangeFilterInteger
     */
    _in?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof RangeFilterInteger
     */
    notIn?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof RangeFilterInteger
     */
    greaterThan?: number;
    /**
     * 
     * @type {number}
     * @memberof RangeFilterInteger
     */
    lessThan?: number;
    /**
     * 
     * @type {number}
     * @memberof RangeFilterInteger
     */
    greaterThanOrEqual?: number;
    /**
     * 
     * @type {number}
     * @memberof RangeFilterInteger
     */
    lessThanOrEqual?: number;
    /**
     * 
     * @type {RangeFilterInteger}
     * @memberof RangeFilterInteger
     */
    greaterOrEqualThan?: RangeFilterInteger;
    /**
     * 
     * @type {RangeFilterInteger}
     * @memberof RangeFilterInteger
     */
    lessOrEqualThan?: RangeFilterInteger;
}

/**
 * Check if a given object implements the RangeFilterInteger interface.
 */
export function instanceOfRangeFilterInteger(value: object): value is RangeFilterInteger {
    return true;
}

export function RangeFilterIntegerFromJSON(json: any): RangeFilterInteger {
    return RangeFilterIntegerFromJSONTyped(json, false);
}

export function RangeFilterIntegerFromJSONTyped(json: any, ignoreDiscriminator: boolean): RangeFilterInteger {
    if (json == null) {
        return json;
    }
    return {
        
        'equals': json['equals'] == null ? undefined : json['equals'],
        'notEquals': json['notEquals'] == null ? undefined : json['notEquals'],
        'specified': json['specified'] == null ? undefined : json['specified'],
        '_in': json['in'] == null ? undefined : json['in'],
        'notIn': json['notIn'] == null ? undefined : json['notIn'],
        'greaterThan': json['greaterThan'] == null ? undefined : json['greaterThan'],
        'lessThan': json['lessThan'] == null ? undefined : json['lessThan'],
        'greaterThanOrEqual': json['greaterThanOrEqual'] == null ? undefined : json['greaterThanOrEqual'],
        'lessThanOrEqual': json['lessThanOrEqual'] == null ? undefined : json['lessThanOrEqual'],
        'greaterOrEqualThan': json['greaterOrEqualThan'] == null ? undefined : RangeFilterIntegerFromJSON(json['greaterOrEqualThan']),
        'lessOrEqualThan': json['lessOrEqualThan'] == null ? undefined : RangeFilterIntegerFromJSON(json['lessOrEqualThan']),
    };
}

export function RangeFilterIntegerToJSON(value?: RangeFilterInteger | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'equals': value['equals'],
        'notEquals': value['notEquals'],
        'specified': value['specified'],
        'in': value['_in'],
        'notIn': value['notIn'],
        'greaterThan': value['greaterThan'],
        'lessThan': value['lessThan'],
        'greaterThanOrEqual': value['greaterThanOrEqual'],
        'lessThanOrEqual': value['lessThanOrEqual'],
        'greaterOrEqualThan': RangeFilterIntegerToJSON(value['greaterOrEqualThan']),
        'lessOrEqualThan': RangeFilterIntegerToJSON(value['lessOrEqualThan']),
    };
}

