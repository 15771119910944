import {CommentoCollezioneDTO} from "../../api";
import {AppDispatch} from "../../store/store";
import {toast} from 'react-toastify';
import {addComment, deleteComment} from '../../store/commenti/CommentsSlice';

export const handleEditClick = (
    setEditingCommentId: React.Dispatch<React.SetStateAction<number | null>>,
    setEditedCommentText: React.Dispatch<React.SetStateAction<string>>,
    setEditedRating: React.Dispatch<React.SetStateAction<number>>,
    id?: number,
    currentComment?: string,
    currentRating?: number
) => {
    if (id !== undefined && currentComment !== undefined && currentRating !== undefined) {
        setEditingCommentId(id);
        setEditedCommentText(currentComment);
        setEditedRating(currentRating);
    } else {
        setEditingCommentId(null);
        setEditedCommentText('');
        setEditedRating(0);
    }
};

export const handleSaveClick = async (
    dispatch: AppDispatch,
    editedCommentText: string,
    editedRating: number,
    datiUtente: any,
    idCollection: string | undefined
) => {

    const updateData: CommentoCollezioneDTO = {
        commento: editedCommentText,
        gradoSoddisfazione: editedRating.toString(),
        utenteId: datiUtente.id,
        usernameUtente: datiUtente.username,
        collezioneId: idCollection
    };


    try {
        const resultAction = await dispatch(addComment(updateData));

        if (addComment.fulfilled.match(resultAction)) {
            toast.success("Commento aggiornato con successo!");
        } else {
            toast.error('Errore nel salvataggio del commento: ' + resultAction.error.message);
        }
    } catch (error) {
        toast.error('Errore nel salvataggio del commento.');
    }
};


    export const handleRatingChange = (
        setEditedRating: React.Dispatch<React.SetStateAction<number>>,
        rating: number
    ) => {
        setEditedRating(rating);
    };

    export const handleDeleteClick = (
        setCommentToDelete: React.Dispatch<React.SetStateAction<number | undefined>>,
        setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>,
        id: number | undefined
    ) => {
        setCommentToDelete(id);
        setOpenDialog(true);
    };

    export const handleConfirmDelete = async (
        dispatch: AppDispatch,
        commentToDelete: number | undefined,
        setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
        if (commentToDelete !== undefined) {
            await dispatch(deleteComment(commentToDelete));
            toast.success("Commento eliminato dalla collezione!");
            setOpenDialog(false);
        }
    };

    export const handleCloseDialog = (
        setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
        setOpenDialog(false);
    };
