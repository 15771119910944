/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TendenzaDTO } from './TendenzaDTO';
import {
    TendenzaDTOFromJSON,
    TendenzaDTOFromJSONTyped,
    TendenzaDTOToJSON,
} from './TendenzaDTO';

/**
 * 
 * @export
 * @interface CartaDTO
 */
export interface CartaDTO {
    /**
     * 
     * @type {string}
     * @memberof CartaDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CartaDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CartaDTO
     */
    rarity?: string;
    /**
     * 
     * @type {string}
     * @memberof CartaDTO
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CartaDTO
     */
    tipoCard?: CartaDTOTipoCardEnum;
    /**
     * 
     * @type {string}
     * @memberof CartaDTO
     */
    effetto?: string;
    /**
     * 
     * @type {string}
     * @memberof CartaDTO
     */
    potenza?: string;
    /**
     * 
     * @type {string}
     * @memberof CartaDTO
     */
    artist?: string;
    /**
     * 
     * @type {string}
     * @memberof CartaDTO
     */
    costo?: string;
    /**
     * 
     * @type {string}
     * @memberof CartaDTO
     */
    nomeEspansione?: string;
    /**
     * 
     * @type {string}
     * @memberof CartaDTO
     */
    colore?: string;
    /**
     * 
     * @type {string}
     * @memberof CartaDTO
     */
    marketIds?: string;
    /**
     * 
     * @type {number}
     * @memberof CartaDTO
     */
    collezionisti?: number;
    /**
     * 
     * @type {Date}
     * @memberof CartaDTO
     */
    ultimaModifica?: Date;
    /**
     * 
     * @type {number}
     * @memberof CartaDTO
     */
    prezzo?: number;
    /**
     * 
     * @type {Array<TendenzaDTO>}
     * @memberof CartaDTO
     */
    tendenzaPrezzo?: Array<TendenzaDTO>;
}


/**
 * @export
 */
export const CartaDTOTipoCardEnum = {
    MagicTheGathering: 'MAGIC_THE_GATHERING',
    Pokemon: 'POKEMON',
    YugiOh: 'YUGI_OH',
    OnePiece: 'ONE_PIECE',
    Dragonball: 'DRAGONBALL',
    Lorcana: 'LORCANA',
    Digimon: 'DIGIMON',
    UnionArena: 'UNION_ARENA',
    Starwars: 'STARWARS'
} as const;
export type CartaDTOTipoCardEnum = typeof CartaDTOTipoCardEnum[keyof typeof CartaDTOTipoCardEnum];


/**
 * Check if a given object implements the CartaDTO interface.
 */
export function instanceOfCartaDTO(value: object): value is CartaDTO {
    return true;
}

export function CartaDTOFromJSON(json: any): CartaDTO {
    return CartaDTOFromJSONTyped(json, false);
}

export function CartaDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartaDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'rarity': json['rarity'] == null ? undefined : json['rarity'],
        'imageUrl': json['imageUrl'] == null ? undefined : json['imageUrl'],
        'tipoCard': json['tipoCard'] == null ? undefined : json['tipoCard'],
        'effetto': json['effetto'] == null ? undefined : json['effetto'],
        'potenza': json['potenza'] == null ? undefined : json['potenza'],
        'artist': json['artist'] == null ? undefined : json['artist'],
        'costo': json['costo'] == null ? undefined : json['costo'],
        'nomeEspansione': json['nomeEspansione'] == null ? undefined : json['nomeEspansione'],
        'colore': json['colore'] == null ? undefined : json['colore'],
        'marketIds': json['marketIds'] == null ? undefined : json['marketIds'],
        'collezionisti': json['collezionisti'] == null ? undefined : json['collezionisti'],
        'ultimaModifica': json['ultimaModifica'] == null ? undefined : (new Date(json['ultimaModifica'])),
        'prezzo': json['prezzo'] == null ? undefined : json['prezzo'],
        'tendenzaPrezzo': json['tendenzaPrezzo'] == null ? undefined : ((json['tendenzaPrezzo'] as Array<any>).map(TendenzaDTOFromJSON)),
    };
}

export function CartaDTOToJSON(value?: CartaDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'rarity': value['rarity'],
        'imageUrl': value['imageUrl'],
        'tipoCard': value['tipoCard'],
        'effetto': value['effetto'],
        'potenza': value['potenza'],
        'artist': value['artist'],
        'costo': value['costo'],
        'nomeEspansione': value['nomeEspansione'],
        'colore': value['colore'],
        'marketIds': value['marketIds'],
        'collezionisti': value['collezionisti'],
        'ultimaModifica': value['ultimaModifica'] == null ? undefined : ((value['ultimaModifica']).toISOString()),
        'prezzo': value['prezzo'],
        'tendenzaPrezzo': value['tendenzaPrezzo'] == null ? undefined : ((value['tendenzaPrezzo'] as Array<any>).map(TendenzaDTOToJSON)),
    };
}

