/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UtenteDTO
 */
export interface UtenteDTO {
    /**
     * 
     * @type {string}
     * @memberof UtenteDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UtenteDTO
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UtenteDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UtenteDTO
     */
    nome?: string;
    /**
     * 
     * @type {string}
     * @memberof UtenteDTO
     */
    cognome?: string;
    /**
     * 
     * @type {number}
     * @memberof UtenteDTO
     */
    punteggioCliente?: number;
    /**
     * 
     * @type {Date}
     * @memberof UtenteDTO
     */
    dataNascita?: Date;
    /**
     * 
     * @type {string}
     * @memberof UtenteDTO
     */
    token?: string;
    /**
     * 
     * @type {Date}
     * @memberof UtenteDTO
     */
    dataCreazione?: Date;
}

/**
 * Check if a given object implements the UtenteDTO interface.
 */
export function instanceOfUtenteDTO(value: object): value is UtenteDTO {
    return true;
}

export function UtenteDTOFromJSON(json: any): UtenteDTO {
    return UtenteDTOFromJSONTyped(json, false);
}

export function UtenteDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UtenteDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'username': json['username'] == null ? undefined : json['username'],
        'email': json['email'] == null ? undefined : json['email'],
        'nome': json['nome'] == null ? undefined : json['nome'],
        'cognome': json['cognome'] == null ? undefined : json['cognome'],
        'punteggioCliente': json['punteggioCliente'] == null ? undefined : json['punteggioCliente'],
        'dataNascita': json['dataNascita'] == null ? undefined : (new Date(json['dataNascita'])),
        'token': json['token'] == null ? undefined : json['token'],
        'dataCreazione': json['dataCreazione'] == null ? undefined : (new Date(json['dataCreazione'])),
    };
}

export function UtenteDTOToJSON(value?: UtenteDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'username': value['username'],
        'email': value['email'],
        'nome': value['nome'],
        'cognome': value['cognome'],
        'punteggioCliente': value['punteggioCliente'],
        'dataNascita': value['dataNascita'] == null ? undefined : ((value['dataNascita']).toISOString().substring(0,10)),
        'token': value['token'],
        'dataCreazione': value['dataCreazione'] == null ? undefined : ((value['dataCreazione']).toISOString()),
    };
}

