import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import {FaCamera, FaEye, FaHeart, FaPlus} from 'react-icons/fa';
import styles from './CardComponent.module.css';
import { addToWishlist } from '../../../../store/carte/CarteSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import DettagliCartaOnePiece from "../../Dettagli-carta-onepiece/dettagli-carta-onePiece";
import AddCardInCollection from '../../../dialog/AddCardInCollection/add-card-in-collection';
import {
    getCardImageUrl,
    handleClickOpen,
    handleClickOpenAdd,
    handleClose,
    handleCloseAdd
} from '../../../../store/carte/CardHandler';

interface CardComponentProps {
    carta: any;
    tipoCard: any;
    viewMode: 'grid' | 'list'; // Nuovo prop per gestire le viste
}

const CardOnePieceComponent: React.FC<CardComponentProps> = ({
                                                                 carta,
                                                                 tipoCard,
                                                                 viewMode
                                                             }) => {
    const [open, setOpen] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [userId, setUserId] = useState('');
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        if (user && user.id) {
            setUserId(user.id);
        }
    }, []);

    const handleAddToWishlist = () => {
        dispatch(addToWishlist({ cartaId: carta.id, userId }));
    };


    if (viewMode === 'list') {
        return (
            <>
                <tr>
                    <td style={{textAlign: 'center', verticalAlign: 'middle'}}>
                        <Tooltip
                            title={
                                <div style={{padding: '5px', textAlign: 'center'}}>
                                    <img
                                        src={carta?.imageUrl}
                                        alt={carta?.name}
                                        style={{
                                            width: '100px',
                                            height: 'auto',
                                            borderRadius: '5px',
                                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                        }}
                                    />
                                </div>
                            }
                            arrow
                            placement="right"
                        >
                    <span>
                        <FaCamera
                            className={styles.actionIcon}
                            style={{
                                cursor: 'pointer',
                                fontSize: '18px',
                                color: '#007BFF',
                            }}
                        />
                    </span>
                        </Tooltip>

                    </td>
                    <td>{carta.name}</td>
                    <td>{carta.numeroEspansione ? carta.numeroEspansione : carta.idCard}</td>
                    <td>{carta.rarity}</td>
                    <td>
                        <Tooltip title="Visualizza">
                            <FaEye
                                className={styles.actionIcon}
                                onClick={() => handleClickOpen(setOpen)}
                            />
                        </Tooltip>
                        <Tooltip title="Aggiungi Alla collezione">
                            <FaPlus
                                className={styles.actionIcon}
                                onClick={() => handleClickOpenAdd(setOpenAdd)}
                            />
                        </Tooltip>
                        <Tooltip title="Aggiungi alla WishList">
                            <FaHeart
                                className={styles.actionIcon}
                                onClick={() => handleAddToWishlist()}
                            />
                        </Tooltip>
                    </td>
                </tr>

                <DettagliCartaOnePiece
                    open={open}
                    onClose={() =>
                        handleClose(
                            setOpen
                        )
                    }
                    carta={carta}
                    tipo={tipoCard}/>
                <AddCardInCollection
                    open={openAdd}
                    onClose={() =>
                        handleCloseAdd(
                            setOpenAdd
                        )
                    }
                    tipoCard={tipoCard}
                    cartaId={carta?.id}
                    nomeCarta={carta?.name}
                    userId={userId}
                />
            </>
        );
    }

    return (
        <>
            <div className={styles.card}>
                {carta.prezzo !== null && carta.prezzo !== undefined && (
                    <div className={styles.priceTag}>
                        € {typeof carta.prezzo === 'number' ? carta.prezzo : 'N/A'}
                    </div>
                )}

                <div className={styles.cardImageContainer}>
                    <img
                        src={getCardImageUrl(tipoCard, carta)}
                        alt={carta?.name}
                        className={styles.cardImage}
                    />
                    <div className={styles.symbolsContainer}>
                        <Tooltip title="Visualizza">
                            <div
                                className={`${styles.symbol} ${styles.viewCard}`}
                                onClick={() => handleClickOpen(setOpen)}
                            >
                                <FaEye />
                            </div>
                        </Tooltip>

                        {userId && (
                            <>
                                <Tooltip title="Aggiungi Alla collezione">
                                    <div
                                        className={`${styles.symbol} ${styles.addColl}`}
                                        onClick={() => handleClickOpenAdd(setOpenAdd)}
                                    >
                                        <FaPlus />
                                    </div>
                                </Tooltip>
                                <Tooltip title="Aggiungi alla WishList">
                                    <div
                                        className={`${styles.symbol} ${styles.heartSymbol}`}
                                        onClick={() => handleAddToWishlist()}
                                    >
                                        <FaHeart />
                                    </div>
                                </Tooltip>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <DettagliCartaOnePiece
                open={open}
                onClose={() =>
                    handleClose(
                        setOpen
                    )
                }
                carta={carta}
                tipo={tipoCard}/>
            <AddCardInCollection
                open={openAdd}
                onClose={() =>
                    handleCloseAdd(
                        setOpenAdd
                    )
                }
                tipoCard={tipoCard}
                cartaId={carta?.id}
                nomeCarta={carta?.name}
                userId={userId}
            />
        </>
    );
};

export default CardOnePieceComponent;
