/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommentoCollezioneDTO
 */
export interface CommentoCollezioneDTO {
    /**
     * 
     * @type {number}
     * @memberof CommentoCollezioneDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentoCollezioneDTO
     */
    gradoSoddisfazione?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentoCollezioneDTO
     */
    commento?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentoCollezioneDTO
     */
    utenteId?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentoCollezioneDTO
     */
    usernameUtente?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentoCollezioneDTO
     */
    collezioneId?: string;
    /**
     * 
     * @type {number}
     * @memberof CommentoCollezioneDTO
     */
    numeroSegnalazioni?: number;
    /**
     * 
     * @type {Date}
     * @memberof CommentoCollezioneDTO
     */
    dataCommento?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CommentoCollezioneDTO
     */
    ultimaModifica?: Date;
}

/**
 * Check if a given object implements the CommentoCollezioneDTO interface.
 */
export function instanceOfCommentoCollezioneDTO(value: object): value is CommentoCollezioneDTO {
    return true;
}

export function CommentoCollezioneDTOFromJSON(json: any): CommentoCollezioneDTO {
    return CommentoCollezioneDTOFromJSONTyped(json, false);
}

export function CommentoCollezioneDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentoCollezioneDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'gradoSoddisfazione': json['gradoSoddisfazione'] == null ? undefined : json['gradoSoddisfazione'],
        'commento': json['commento'] == null ? undefined : json['commento'],
        'utenteId': json['utenteId'] == null ? undefined : json['utenteId'],
        'usernameUtente': json['usernameUtente'] == null ? undefined : json['usernameUtente'],
        'collezioneId': json['collezioneId'] == null ? undefined : json['collezioneId'],
        'numeroSegnalazioni': json['numeroSegnalazioni'] == null ? undefined : json['numeroSegnalazioni'],
        'dataCommento': json['dataCommento'] == null ? undefined : (new Date(json['dataCommento'])),
        'ultimaModifica': json['ultimaModifica'] == null ? undefined : (new Date(json['ultimaModifica'])),
    };
}

export function CommentoCollezioneDTOToJSON(value?: CommentoCollezioneDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'gradoSoddisfazione': value['gradoSoddisfazione'],
        'commento': value['commento'],
        'utenteId': value['utenteId'],
        'usernameUtente': value['usernameUtente'],
        'collezioneId': value['collezioneId'],
        'numeroSegnalazioni': value['numeroSegnalazioni'],
        'dataCommento': value['dataCommento'] == null ? undefined : ((value['dataCommento']).toISOString()),
        'ultimaModifica': value['ultimaModifica'] == null ? undefined : ((value['ultimaModifica']).toISOString()),
    };
}

