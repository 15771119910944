/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CartaDTO } from './CartaDTO';
import {
    CartaDTOFromJSON,
    CartaDTOFromJSONTyped,
    CartaDTOToJSON,
} from './CartaDTO';

/**
 * 
 * @export
 * @interface CollezioneCartaUtenteDTO
 */
export interface CollezioneCartaUtenteDTO {
    /**
     * 
     * @type {CartaDTO}
     * @memberof CollezioneCartaUtenteDTO
     */
    carta?: CartaDTO;
    /**
     * 
     * @type {number}
     * @memberof CollezioneCartaUtenteDTO
     */
    qtyCarta?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CollezioneCartaUtenteDTO
     */
    autenticata?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CollezioneCartaUtenteDTO
     */
    valoreCollezione?: number;
    /**
     * 
     * @type {Date}
     * @memberof CollezioneCartaUtenteDTO
     */
    ultimaModifica?: Date;
}

/**
 * Check if a given object implements the CollezioneCartaUtenteDTO interface.
 */
export function instanceOfCollezioneCartaUtenteDTO(value: object): value is CollezioneCartaUtenteDTO {
    return true;
}

export function CollezioneCartaUtenteDTOFromJSON(json: any): CollezioneCartaUtenteDTO {
    return CollezioneCartaUtenteDTOFromJSONTyped(json, false);
}

export function CollezioneCartaUtenteDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollezioneCartaUtenteDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'carta': json['carta'] == null ? undefined : CartaDTOFromJSON(json['carta']),
        'qtyCarta': json['qtyCarta'] == null ? undefined : json['qtyCarta'],
        'autenticata': json['autenticata'] == null ? undefined : json['autenticata'],
        'valoreCollezione': json['valoreCollezione'] == null ? undefined : json['valoreCollezione'],
        'ultimaModifica': json['ultimaModifica'] == null ? undefined : (new Date(json['ultimaModifica'])),
    };
}

export function CollezioneCartaUtenteDTOToJSON(value?: CollezioneCartaUtenteDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carta': CartaDTOToJSON(value['carta']),
        'qtyCarta': value['qtyCarta'],
        'autenticata': value['autenticata'],
        'valoreCollezione': value['valoreCollezione'],
        'ultimaModifica': value['ultimaModifica'] == null ? undefined : ((value['ultimaModifica']).toISOString()),
    };
}

