import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import CardList from '../components/Card/Lista/Card-list';
import FilterAndSort from '../components/Card/Filtri-ricerca/filtri-ricerca';
import '../styles/CardsPage.css';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import TCGHeader from "../components/HeaderImg/HeaderImg";
import {CircularProgress} from "@mui/material";
import {useDispatch, useSelector} from 'react-redux';
import {fetchCards, setCurrentPage} from "../store/carte/CarteSlice";
import {AppDispatch, RootState} from "../store/store";
import { motion } from 'framer-motion';
import {FaList, FaTh} from "react-icons/fa";


const theme = createTheme({
    components: {
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: '#3f51b5',
                    '&:hover': {
                        backgroundColor: '#303f9f',
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#1a237e',
                    },
                },
            },
        },
    },
});

const ListCard: React.FC = () => {
    const {categoryName} = useParams<{ categoryName: string }>();
    const dispatch = useDispatch<AppDispatch>();

    const cards = useSelector((state: RootState) => state.carte.cards);
    const totalPages = useSelector((state: RootState) => state.carte.totalPages);
    const currentPage = useSelector((state: RootState) => state.carte.currentPage);
    const error = useSelector((state: RootState) => state.carte.error);
    const isLoading = useSelector((state: RootState) => state.carte.isLoading);
    const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');

    const [filter, setFilter] = useState<any>({});

    useEffect(() => {
        const resetFilters = () => {
            setFilter({});
            dispatch(setCurrentPage(1));
        };

        if (categoryName) {
            resetFilters();
            dispatch(fetchCards({
                categoryName,
                newFilter: { criteria: {}, criteriaOp: {}, criteriaPokemon: {}, criteriaDragonballFs: {}, criteriaLorcana: {} },
                page: 1,
            }));
        }
    }, [categoryName, dispatch]);


    const handleFilter = (newFilter: any) => {
        setFilter(newFilter);
        dispatch(setCurrentPage(1));
        dispatch(fetchCards({
            categoryName,
            newFilter,
            page: 1,
            sort: newFilter.sort,
        }));
    };



    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        dispatch(setCurrentPage(page));
        dispatch(fetchCards({
            categoryName,
            newFilter: filter,
            page,
            sort: filter.sort, // Passa sort nel formato "property,direction"
        }));
    };


    return (
        <div className="cards-page">
            <div className="content">
                {isLoading ? (
                    <motion.div
                        className="loading-spinner"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <CircularProgress />
                    </motion.div>
                ) : (
                    <>
                        <div className="filters">
                            <FilterAndSort
                                onFilter={handleFilter}
                                isCollection={false}
                                tipoCard={categoryName}
                                initialFilters={{
                                    sort: filter.sort,
                                    name: filter.criteria?.name?.contains || '',
                                    sourceName: filter.criteria?.sourceName?.equals || '',
                                    rarity: filter.criteria?.rarity?.equals || '',
                                    colore: filter.criteria?.colore?.equals || '',
                                }}
                                cards={cards}
                            />
                        </div>

                        <div className="card-list-container">
                            <TCGHeader category={categoryName || 'default'} />

                            <div className="view-buttons">
                                <button
                                    className={`view-button ${viewMode === 'grid' ? 'selected' : ''}`}
                                    onClick={() => setViewMode('grid')}
                                >
                                    <FaTh style={{ marginRight: '8px' }} /> Vista Griglia
                                </button>
                                <button
                                    className={`view-button ${viewMode === 'list' ? 'selected' : ''}`}
                                    onClick={() => setViewMode('list')}
                                >
                                    <FaList style={{ marginRight: '8px' }} /> Vista Lista
                                </button>
                            </div>

                            <motion.div
                                className="card-list-container"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                            >
                                <CardList
                                    filter={filter}
                                    cards={cards}
                                    tipoCard={categoryName}
                                    viewMode={viewMode}
                                />
                                <ThemeProvider theme={theme}>
                                    <Stack spacing={2} alignItems="center" sx={{ marginTop: '20px' }}>
                                        <Pagination
                                            key={currentPage}
                                            count={totalPages}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            variant="outlined"
                                            shape="rounded"
                                            renderItem={(item) => <PaginationItem {...item} />}
                                        />
                                    </Stack>
                                </ThemeProvider>
                            </motion.div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ListCard;
