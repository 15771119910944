import React, {useEffect, useState} from 'react';
import {FaEdit, FaSave, FaStar, FaTrashAlt} from 'react-icons/fa';
import styles from './ListaCommenti.module.css';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {fetchComments} from '../../store/commenti/CommentsSlice';
import {AppDispatch, RootState} from "../../store/store";
import {
    handleCloseDialog,
    handleConfirmDelete,
    handleDeleteClick,
    handleEditClick,
    handleRatingChange
} from "../../store/commenti/commentHandlers";

const StarRating: React.FC<{ rating: number, onChange: (rating: number) => void }> = ({ rating, onChange }) => (
    <div className={styles.rating}>
        {[...Array(5)].map((_, i) => (
            <FaStar
                key={i}
                color={i < rating ? "#ffc107" : "#e4e5e9"}
                size={20}
                onClick={() => onChange(i + 1)}
            />
        ))}
    </div>
);

const ListaCommenti: React.FC<{ idCollection: string | undefined }> = ({ idCollection }) => {
    const dispatch = useDispatch<AppDispatch>();
    const comments = useSelector((state: RootState) => state.comments.comments);
    const [editingCommentId, setEditingCommentId] = useState<number | null>(null);
    const [editedCommentText, setEditedCommentText] = useState<string>('');
    const [editedRating, setEditedRating] = useState<number>(0);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [commentToDelete, setCommentToDelete] = useState<number | undefined>(undefined);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (idCollection) {
            dispatch(fetchComments({ idCollection, page }));
        }
    }, [dispatch, idCollection, page]);

    const savedUser = localStorage.getItem('user');
    const datiUtente = savedUser ? JSON.parse(savedUser) : null;


    const handleConfirm = () => {
        handleConfirmDelete(dispatch, commentToDelete, setOpenDialog).then(r => {});
    };

    if (!comments || comments.length === 0) {
        return <div className={styles.commentono}>Nessun commento disponibile.</div>;
    }

    return (
        <div className={styles.commentListContainer}>
            {comments.map((comment: any) => {
                const rating = parseInt(comment.gradoSoddisfazione!, 10);

                return (
                    <div key={comment.id} className={styles.commentItem}>
                        <div className={styles.commentHeader}>
                            <span className={styles.username}>Scritto da: {comment.usernameUtente}</span>
                            <StarRating rating={rating} onChange={(rating) => handleRatingChange(setEditedRating, rating)} />
                        </div>

                        {editingCommentId === comment.id ? (
                            <div>
                                <textarea
                                    className={styles.commentEditInput}
                                    value={editedCommentText}
                                    onChange={(e) => setEditedCommentText(e.target.value)}
                                />
                                <div className={styles.editRating}>
                                    <span className={styles.editRatingText}>Modifica Valutazione:</span>
                                    <StarRating rating={editedRating} onChange={(rating) => handleRatingChange(setEditedRating, rating)} />
                                </div>
                            </div>
                        ) : (
                            <p className={styles.commentText}>{comment.commento}</p>
                        )}

                        {datiUtente && datiUtente.username === comment.usernameUtente && (
                            <div className={styles.actionIcons}>
                                {editingCommentId === comment.id ? (
                                    <FaSave
                                        className={styles.icon}
                                        size={16}
                                        title="Salva"
                                    />
                                ) : (
                                    <>
                                        <FaEdit
                                            className={styles.icon}
                                            size={16}
                                            title="Modifica"
                                            onClick={() => handleEditClick(setEditingCommentId, setEditedCommentText, setEditedRating, comment.id, comment.commento, rating)}
                                        />
                                        <FaTrashAlt
                                            className={styles.icon}
                                            size={16}
                                            title="Elimina"
                                            onClick={() => handleDeleteClick(setCommentToDelete, setOpenDialog, comment.id)}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                );
            })}

            <Dialog open={openDialog} onClose={() => handleCloseDialog(setOpenDialog)}>
                <DialogTitle>Conferma Eliminazione</DialogTitle>
                <DialogContent>
                    Sei sicuro di voler rimuovere questo commento dalla collezione?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseDialog(setOpenDialog)} color="secondary">Annulla</Button>
                    <Button onClick={handleConfirm} color="primary">Conferma</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ListaCommenti;

