import React from 'react';
import styles from './CardList.module.css';
import CardOnePieceComponent from '../OnePiece/SingolaCartaOnePiece/cardOnePieceComponent';
import CardPokemonComponent from '../Pokemon/SingolaCartaPokemon/cardPokemonComponent';
import {CardListProps} from '../../../utility/props';

const CardList: React.FC<CardListProps> = ({ cards, tipoCard, viewMode }) => {
    if (viewMode === 'list') {
        return (
                    <table className={styles.cardTable}>
                        <thead>
                        <tr style={{backgroundColor: '#f4f4f4', borderBottom: '2px solid #ddd'}}>
                            <th style={{
                                textAlign: 'center',
                                padding: '10px',
                                fontWeight: 'bold',
                                color: '#333'
                            }}>#
                            </th>
                            <th style={{
                                textAlign: 'center',
                                padding: '10px',
                                fontWeight: 'bold',
                                color: '#333'
                            }}>Nome
                            </th>
                            <th style={{
                                textAlign: 'center',
                                padding: '10px',
                                fontWeight: 'bold',
                                color: '#333'
                            }}>Numero
                            </th>
                            <th style={{
                                textAlign: 'center',
                                padding: '10px',
                                fontWeight: 'bold',
                                color: '#333'
                            }}>Rarità
                            </th>
                            <th style={{
                                textAlign: 'center',
                                padding: '10px',
                                fontWeight: 'bold',
                                color: '#333'
                            }}>Azioni
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {cards.map((card, index) => (
                            <CardOnePieceComponent
                                key={index}
                                carta={card}
                                tipoCard={tipoCard}
                                viewMode="list"
                            />
                        ))}
                        </tbody>
                    </table>
        );
    }

    return (
        <div className={styles.cardList}>
            {cards.map((card, index) => (
                (tipoCard === 'one_piece' || tipoCard === 'dragonball' || tipoCard === 'lorcana') && (
                    <CardOnePieceComponent
                        key={index}
                        carta={card}
                        tipoCard={tipoCard}
                        viewMode="grid"
                    />
                )
            ))}
            {cards.map((card, index) => (
                tipoCard === 'pokemon' && (
                    <CardPokemonComponent
                        key={index}
                        name={card.name}
                        image={card.imageUrl}
                        rarita={card.rarity}
                        idCartaPokemon={card.idCartaPokemon}
                        supertype={card.supertype}
                        artist={card.artist}
                        evolvesFrom={card.evolvesFrom}
                        hp={card.hp}
                        series={card.series}
                        retreatCost={card.retreatCost}
                        types={card.types}
                        set={card.set}
                    />
                )
            ))}
        </div>
    );
};

export default CardList;
