/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommentoCollezioneDTO,
  CommentoSearchRequest,
  Problem,
} from '../models/index';
import {
    CommentoCollezioneDTOFromJSON,
    CommentoCollezioneDTOToJSON,
    CommentoSearchRequestFromJSON,
    CommentoSearchRequestToJSON,
    ProblemFromJSON,
    ProblemToJSON,
} from '../models/index';

export interface AddComment1Request {
    commentoCollezioneDTO: CommentoCollezioneDTO;
}

export interface DeleteCommentRequest {
    idCommento: number;
}

export interface GetCommentiByCartaRequest {
    commentoSearchRequest: CommentoSearchRequest;
}

export interface UpdateCommentRequest {
    commentoCollezioneDTO: CommentoCollezioneDTO;
}

/**
 * 
 */
export class CommentoCartaRestApi extends runtime.BaseAPI {

    /**
     * tutti i campi sono ncecessari, apparte il grado di soddisfazione
     * inserimento di un commento ad una carta
     */
    async addComment1Raw(requestParameters: AddComment1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommentoCollezioneDTO>> {
        if (requestParameters['commentoCollezioneDTO'] == null) {
            throw new runtime.RequiredError(
                'commentoCollezioneDTO',
                'Required parameter "commentoCollezioneDTO" was null or undefined when calling addComment1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/comment-collection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommentoCollezioneDTOToJSON(requestParameters['commentoCollezioneDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentoCollezioneDTOFromJSON(jsonValue));
    }

    /**
     * tutti i campi sono ncecessari, apparte il grado di soddisfazione
     * inserimento di un commento ad una carta
     */
    async addComment1(requestParameters: AddComment1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommentoCollezioneDTO> {
        const response = await this.addComment1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancella un commento in base al suo ID
     * Elimina un commento esistente
     */
    async deleteCommentRaw(requestParameters: DeleteCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['idCommento'] == null) {
            throw new runtime.RequiredError(
                'idCommento',
                'Required parameter "idCommento" was null or undefined when calling deleteComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/comment-collection/{idCommento}`.replace(`{${"idCommento"}}`, encodeURIComponent(String(requestParameters['idCommento']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancella un commento in base al suo ID
     * Elimina un commento esistente
     */
    async deleteComment(requestParameters: DeleteCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCommentRaw(requestParameters, initOverrides);
    }

    /**
     * cerca la lista di commenti di una carta
     */
    async getCommentiByCartaRaw(requestParameters: GetCommentiByCartaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommentoCollezioneDTO>> {
        if (requestParameters['commentoSearchRequest'] == null) {
            throw new runtime.RequiredError(
                'commentoSearchRequest',
                'Required parameter "commentoSearchRequest" was null or undefined when calling getCommentiByCarta().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/comment-collection/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommentoSearchRequestToJSON(requestParameters['commentoSearchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentoCollezioneDTOFromJSON(jsonValue));
    }

    /**
     * cerca la lista di commenti di una carta
     */
    async getCommentiByCarta(requestParameters: GetCommentiByCartaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommentoCollezioneDTO> {
        const response = await this.getCommentiByCartaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Aggiorna i dati di un commento esistente in base al suo ID
     * Aggiorna un commento esistente
     */
    async updateCommentRaw(requestParameters: UpdateCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommentoCollezioneDTO>> {
        if (requestParameters['commentoCollezioneDTO'] == null) {
            throw new runtime.RequiredError(
                'commentoCollezioneDTO',
                'Required parameter "commentoCollezioneDTO" was null or undefined when calling updateComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/comment-collection`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CommentoCollezioneDTOToJSON(requestParameters['commentoCollezioneDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentoCollezioneDTOFromJSON(jsonValue));
    }

    /**
     * Aggiorna i dati di un commento esistente in base al suo ID
     * Aggiorna un commento esistente
     */
    async updateComment(requestParameters: UpdateCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommentoCollezioneDTO> {
        const response = await this.updateCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
