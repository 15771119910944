import React from 'react';
import {IconButton, Grid, Typography, Tooltip, Paper} from '@mui/material';
import {FaEdit, FaEye, FaLock, FaTrash, FaUnlock} from 'react-icons/fa';
import {transformCardType} from '../../utility/utils';
import {useNavigate} from 'react-router-dom';
import {TabellaMiaCollezioneProps} from '../../utility/props';
import {handleViewClick, headers} from '../Card/Dettagli-carta-onepiece/ConstDetail';

const TabellaMiaCollezione: React.FC<TabellaMiaCollezioneProps> = ({rows, onEditClick, onDeleteClick}) => {
    const navigate = useNavigate();

    return (
        <Paper elevation={3} sx={{borderRadius: 3, maxWidth: '900px', margin: 'auto', padding: 3}}>
            <Grid container sx={{borderBottom: '2px solid #ddd', backgroundColor: '#f4f6f8', padding: '16px 0'}}>
                {headers.map(header => (
                    <Grid item xs={header.xs} key={header.id} sx={{textAlign: 'center', padding: 1}}>
                        <Typography variant="h6"
                                    sx={{fontWeight: 600, color: '#3E4A59', fontFamily: '"Poppins", sans-serif'}}>
                            {header.label}
                        </Typography>
                    </Grid>
                ))}
            </Grid>

            {rows.map((row, index) => (
                <Grid
                    container
                    key={index}
                    sx={{
                        padding: '16px 0',
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '1px solid #ddd',
                        backgroundColor: index % 2 === 0 ? '#f9fafb' : 'transparent',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                            backgroundColor: '#e1f5fe',
                        },
                    }}
                >
                    <Grid item xs={3} sx={{textAlign: 'center', fontFamily: '"Poppins", sans-serif'}}>
                        <Typography variant="body1" sx={{color: '#37474f', fontWeight: 500}}>
                            {row.nome}
                        </Typography>
                    </Grid>

                    <Grid item xs={3} sx={{textAlign: 'center'}}>
                        {row.isPrivate !== undefined ? (
                            row.isPrivate ? (
                                <Tooltip title="Privata">
                <span>
                    <FaLock style={{color: '#f44336', fontSize: '22px'}}/>
                </span>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Pubblica">
                <span>
                    <FaUnlock style={{color: '#3f51b5', fontSize: '22px'}}/>
                </span>
                                </Tooltip>
                            )
                        ) : (
                            <Typography variant="body2" sx={{color: '#9e9e9e'}}>
                                N/A
                            </Typography>
                        )}
                    </Grid>


                    <Grid item xs={3} sx={{textAlign: 'center'}}>
                        <Typography variant="body1" sx={{color: '#37474f', fontWeight: 500}}>
                            {transformCardType(row.tipoCard)}
                        </Typography>
                    </Grid>

                    <Grid item xs={3} sx={{textAlign: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', gap: '20px'}}>
                            <Tooltip title="Visualizza">
                                <IconButton onClick={() => handleViewClick(row.id, row.tipoCard, navigate)}
                                            sx={{color: '#3f51b5'}}>
                                    <FaEye/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Modifica">
                                <IconButton onClick={() => onEditClick(row.id!)} sx={{color: '#ff9800'}}>
                                    <FaEdit/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Elimina">
                                <IconButton onClick={() => onDeleteClick(row.id!, row.nome!)} sx={{color: '#f44336'}}>
                                    <FaTrash/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
            ))}
        </Paper>
    );
};

export default TabellaMiaCollezione;
