import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../Breadcrumb/Breadcrumb.module.css';
import { BreadcrumbProps } from "../../utility/props";

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
    return (
        <nav className={styles.breadcrumb}>
            {items.map((item, index) => (
                <React.Fragment key={index}>
                    {item.link ? (
                        <Link to={item.link} className={styles.breadcrumbItem}>{item.name}</Link>
                    ) : (
                        <span className={styles.breadcrumbCurrent}>{item.name}</span>
                    )}
                    {index < items.length - 1 && (
                        <span className={styles.breadcrumbSeparator}>&gt;</span>
                    )}
                </React.Fragment>
            ))}
        </nav>
    );
};

export default Breadcrumb;
