import React, {useEffect, useState} from 'react';
import Tooltip from '@mui/material/Tooltip';
import {FaEye, FaMinus, FaPlus, FaTrash} from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import styles from './CardCollection.module.css';
import {CollezioneRestApi, WishlistRestApi} from "../../../../api";
import {CardComponentProps} from "../../../../utility/props";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import DettagliCartaOnePiece from "../../Dettagli-carta-onepiece/dettagli-carta-onePiece";
import {transformCardType} from "../../../../utility/utils";

const api = new CollezioneRestApi();
const wishlistApi = new WishlistRestApi();

const CardOnePieceCollection: React.FC<CardComponentProps> = ({
                                                                  idCarta,
                                                                  collezioneId,
                                                                  name,
                                                                  image,
                                                                  espansione,
                                                                  colore,
                                                                  attributo,
                                                                  costo,
                                                                  potenza,
                                                                  nomeEspansione,
                                                                  appartenenza,
                                                                  effettto,
                                                                  rarita,
                                                                  alternativeArt,
                                                                  qty,
                                                                  showDeleteIcon,
                                                                  collectionType,
                                                                  prezzo,
                                                                  carta
                                                              }) => {
    const [quantity, setQuantity] = useState(qty);
    const [openDialog, setOpenDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setQuantity(qty);
    }, [qty]);

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = async () => {
        try {
            const utente = localStorage.getItem('user');
            if (!utente) {
                return;
            }
            const datiUtente = JSON.parse(utente);

            const requestBody = {
                idCarta: idCarta,
                idUser: datiUtente.id,
            };

            await wishlistApi.deleteWishlistByCardRaw(requestBody);
            toast.success("Carta eliminata con successo dalla Wishlist!");
            setOpenDialog(false);
        } catch (error) {
            console.error("Errore durante l'eliminazione della carta", error);
        }
    };

    const handleIncrease = async () => {
        const newQuantity = quantity + 1;
        await updateQuantity(newQuantity);
    };

    const handleDecrease = async () => {
        if (quantity > 0) {
            const newQuantity = quantity - 1;
            await updateQuantity(newQuantity);
        }
    };

    const updateQuantity = async (newQuantity: number) => {
        try {
            if (!collezioneId) {
                throw new Error("collezioneId non definito");
            }

            const requestBody = {
                collezioneId: collezioneId,
                cartaId: idCarta,
                body: newQuantity
            };

            await api.addCardCollectionRaw(requestBody);
            setQuantity(newQuantity);
        } catch (error) {
            console.error("Errore nell'aggiornamento della quantità", error);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className={styles.card}>
            {prezzo !== null && prezzo !== undefined && (
                <div className={styles.priceTag}>
                    € {typeof prezzo === 'number' ? prezzo : 'N/A'}
                </div>
            )}
            <div className={styles.cardImageContainer}>
                <img src={image} alt={name} className={styles.cardImage}/>
                <div className={styles.symbolsContainer}>
                    {showDeleteIcon ? (
                        <Tooltip title="Rimuovi Carta">
                            <div className={styles.symbol} onClick={handleClickOpenDialog}>
                                <FaTrash/>
                            </div>
                        </Tooltip>
                    ) : (
                        <>
                            {collectionType !== "Collezione Pubblica" && (
                                <>
                                    <Tooltip title="Quantità Attuale">
                                        <div className={`${styles.symbol} ${styles.add}`}>
                                            X {quantity}
                                        </div>
                                    </Tooltip>
                                    <Tooltip title="Aggiungi Quantità">
                                        <div className={styles.symbol} onClick={handleIncrease}>
                                            <FaPlus/>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title="Diminuisci Quantità">
                                        <div className={styles.symbol} onClick={handleDecrease}>
                                            <FaMinus/>
                                        </div>
                                    </Tooltip>
                                </>
                            )}
                        </>
                    )}
                    <Tooltip title="Visualizza">
                        <div className={styles.symbol} onClick={handleClickOpen}>
                            <FaEye/>
                        </div>
                    </Tooltip>
                </div>
            </div>
            <DettagliCartaOnePiece
                open={open}
                onClose={handleClose}
                carta={carta}
                tipo={transformCardType(carta?.tipoCard)}/>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                PaperProps={{style: {backgroundColor: '#f7f7f7', borderRadius: '8px', padding: '16px'}}}
            >
                <DialogTitle style={{
                    color: '#333',
                    fontWeight: 'bold',
                    fontSize: '25px',
                    fontFamily: 'Arial, sans-serif',
                    textAlign: 'center',
                }}>
                    ELIMINA {name.toUpperCase()}
                </DialogTitle>

                <DialogContent style={{
                    marginTop: '10px',
                    fontSize: '20px',
                    color: '#555',
                    fontFamily: 'Helvetica, sans-serif'
                }}>
                    Sei sicuro di voler rimuovere <strong>{name}</strong> (<em><strong>{espansione}</strong></em>) dalla
                    Wishlist?
                </DialogContent>

                <DialogActions style={{justifyContent: 'space-between', padding: '16px'}}>
                    <Button
                        onClick={handleCloseDialog}
                        color="secondary"
                        style={{
                            borderRadius: '20px',
                            padding: '8px 16px',
                            fontSize: '14px'
                        }}
                    >
                        Annulla
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        color="primary"
                        style={{
                            backgroundColor: '#ff4d4d',
                            borderRadius: '20px',
                            padding: '8px 16px',
                            color: 'white',
                            fontSize: '14px'
                        }}
                    >
                        Conferma
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CardOnePieceCollection;
