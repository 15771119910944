/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UtenteDTO } from './UtenteDTO';
import {
    UtenteDTOFromJSON,
    UtenteDTOFromJSONTyped,
    UtenteDTOToJSON,
} from './UtenteDTO';
import type { CartaDTO } from './CartaDTO';
import {
    CartaDTOFromJSON,
    CartaDTOFromJSONTyped,
    CartaDTOToJSON,
} from './CartaDTO';

/**
 * 
 * @export
 * @interface WishlistUtenteDTO
 */
export interface WishlistUtenteDTO {
    /**
     * 
     * @type {string}
     * @memberof WishlistUtenteDTO
     */
    id?: string;
    /**
     * 
     * @type {CartaDTO}
     * @memberof WishlistUtenteDTO
     */
    carta?: CartaDTO;
    /**
     * 
     * @type {UtenteDTO}
     * @memberof WishlistUtenteDTO
     */
    utente?: UtenteDTO;
    /**
     * 
     * @type {Date}
     * @memberof WishlistUtenteDTO
     */
    ultimaModifica?: Date;
}

/**
 * Check if a given object implements the WishlistUtenteDTO interface.
 */
export function instanceOfWishlistUtenteDTO(value: object): value is WishlistUtenteDTO {
    return true;
}

export function WishlistUtenteDTOFromJSON(json: any): WishlistUtenteDTO {
    return WishlistUtenteDTOFromJSONTyped(json, false);
}

export function WishlistUtenteDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WishlistUtenteDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'carta': json['carta'] == null ? undefined : CartaDTOFromJSON(json['carta']),
        'utente': json['utente'] == null ? undefined : UtenteDTOFromJSON(json['utente']),
        'ultimaModifica': json['ultimaModifica'] == null ? undefined : (new Date(json['ultimaModifica'])),
    };
}

export function WishlistUtenteDTOToJSON(value?: WishlistUtenteDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'carta': CartaDTOToJSON(value['carta']),
        'utente': UtenteDTOToJSON(value['utente']),
        'ultimaModifica': value['ultimaModifica'] == null ? undefined : ((value['ultimaModifica']).toISOString()),
    };
}

