import React, { useState } from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { CartaDTOTipoCardEnum, CollezioneRestApi } from "../../../api";
import styled from "@emotion/styled";
import { transformCardType } from "../../../utility/utils";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { handleCreateCollection } from "../../../store/collezioni/CollezioniHandlers";

const api = new CollezioneRestApi();

interface CreateCollectionProps {
    open: boolean;
    onClose: () => void;
    userId: string | undefined;
}

const StyledDialog = styled(Dialog)`
    & .MuiPaper-root {
        background: linear-gradient(135deg, #eceff1 0%, #cfd8dc 100%);
        border-radius: 20px;
        padding: 24px;
        max-width: 600px;
        transition: transform 0.3s ease-in-out;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 24px;
`;

const Title = styled(Typography)`
    text-align: center;
    font-size: 25px;
    color: #1976d2;
`;

const StyledIconButton = styled(IconButton)`
    color: #616161;
    position: absolute;
    right: 0;
    transition: color 0.3s;
    &:hover {
        color: #424242;
    }
`;

const CustomButton = styled(Button)`
    border-radius: 50px;
    padding: 12px 24px;
    background-color: #388e3c;
    font-weight: bold;
    color: white;
    &:hover {
        background-color: #2e7d32;
    }
`;

const CustomTextField = styled(TextField)`
    margin-bottom: 16px !important;
    & .MuiOutlinedInput-root {
        border-radius: 24px;
    }
`;

const CustomSelect = styled(Select)`
    margin-bottom: 16px !important;
    & .MuiOutlinedInput-root {
        border-radius: 24px;
    }
`;

const CreateCollection: React.FC<CreateCollectionProps> = ({ open, onClose, userId }) => {
    const [nomeCollezione, setNomeCollezione] = useState("");
    const [tipoCollezione, setTipoCollezione] = useState<CartaDTOTipoCardEnum>();
    const [isPrivata, setIsPrivata] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <Header>
                <Title variant="h6">Crea Una Nuova Collezione</Title>
                <StyledIconButton onClick={onClose}>
                    <FaTimes />
                </StyledIconButton>
            </Header>
            <DialogContent>
                <CustomTextField
                    label="Nome Collezione"
                    fullWidth
                    value={nomeCollezione}
                    onChange={(e) => setNomeCollezione(e.target.value)}
                />
                <FormControl fullWidth>
                    <InputLabel>Tipo Collezione</InputLabel>
                    <CustomSelect
                        value={tipoCollezione}
                        onChange={(e) => setTipoCollezione(e.target.value as CartaDTOTipoCardEnum)}
                    >
                        {Object.keys(CartaDTOTipoCardEnum).map((key) => (
                            <MenuItem key={key} value={CartaDTOTipoCardEnum[key as keyof typeof CartaDTOTipoCardEnum]}>
                                {transformCardType(CartaDTOTipoCardEnum[key as keyof typeof CartaDTOTipoCardEnum])}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isPrivata}
                            onChange={(e) => setIsPrivata(e.target.checked)}
                            color="primary"
                        />
                    }
                    label="Privata"
                />
            </DialogContent>
            <DialogActions style={{ justifyContent: "flex-end", paddingBottom: 24 }}>
                <CustomButton
                    onClick={() => {
                        handleCreateCollection(dispatch, nomeCollezione, isPrivata, tipoCollezione, userId)
                            .then(() => {
                                onClose();
                                setIsPrivata(false);
                                setTipoCollezione(undefined);
                                setNomeCollezione("");
                            })
                            .catch((error) => {
                                console.error("Errore:", error);
                            });
                    }}
                >
                    Crea Collezione
                </CustomButton>
            </DialogActions>
        </StyledDialog>
    );
};

export default CreateCollection;
