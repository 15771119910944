/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColorOpDTO
 */
export interface ColorOpDTO {
    /**
     * 
     * @type {number}
     * @memberof ColorOpDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ColorOpDTO
     */
    idColore?: number;
    /**
     * 
     * @type {string}
     * @memberof ColorOpDTO
     */
    nome?: string;
}

/**
 * Check if a given object implements the ColorOpDTO interface.
 */
export function instanceOfColorOpDTO(value: object): value is ColorOpDTO {
    return true;
}

export function ColorOpDTOFromJSON(json: any): ColorOpDTO {
    return ColorOpDTOFromJSONTyped(json, false);
}

export function ColorOpDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColorOpDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'idColore': json['idColore'] == null ? undefined : json['idColore'],
        'nome': json['nome'] == null ? undefined : json['nome'],
    };
}

export function ColorOpDTOToJSON(value?: ColorOpDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'idColore': value['idColore'],
        'nome': value['nome'],
    };
}

