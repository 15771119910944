/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IndirizzoDTO
 */
export interface IndirizzoDTO {
    /**
     * 
     * @type {number}
     * @memberof IndirizzoDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoDTO
     */
    via?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoDTO
     */
    civico?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoDTO
     */
    cap?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoDTO
     */
    citta?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoDTO
     */
    provincia?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoDTO
     */
    stato?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IndirizzoDTO
     */
    principale?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoDTO
     */
    utenteId?: string;
}

/**
 * Check if a given object implements the IndirizzoDTO interface.
 */
export function instanceOfIndirizzoDTO(value: object): value is IndirizzoDTO {
    return true;
}

export function IndirizzoDTOFromJSON(json: any): IndirizzoDTO {
    return IndirizzoDTOFromJSONTyped(json, false);
}

export function IndirizzoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndirizzoDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'via': json['via'] == null ? undefined : json['via'],
        'civico': json['civico'] == null ? undefined : json['civico'],
        'cap': json['cap'] == null ? undefined : json['cap'],
        'citta': json['citta'] == null ? undefined : json['citta'],
        'provincia': json['provincia'] == null ? undefined : json['provincia'],
        'stato': json['stato'] == null ? undefined : json['stato'],
        'principale': json['principale'] == null ? undefined : json['principale'],
        'utenteId': json['utenteId'] == null ? undefined : json['utenteId'],
    };
}

export function IndirizzoDTOToJSON(value?: IndirizzoDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'via': value['via'],
        'civico': value['civico'],
        'cap': value['cap'],
        'citta': value['citta'],
        'provincia': value['provincia'],
        'stato': value['stato'],
        'principale': value['principale'],
        'utenteId': value['utenteId'],
    };
}

