import React from 'react';
import styles from './ListaCollezioniPubbliche.module.css'; // Assicurati di importare il file CSS corretto
import UserCollection from "../Card/User-collection/UserCollection";
import { transformCardType } from "../../utility/utils";

interface CardListProps {
    filter: any;
    collections: any[];
}

const ListaCollezioniPubbliche: React.FC<CardListProps> = ({ filter, collections }) => {
    const cardListClass = styles.cardList;

    return (
        <div className={cardListClass}>
            {collections.map((collection, index) => (
                <UserCollection
                    key={index}
                    nomeCollezione={collection.nome}
                    tipoCollection={transformCardType(collection.tipoCard)}
                    nomeUtente={collection.utente.username}
                    id={collection.id}
                    imgCopertina={collection.imgCopertina}
                />
            ))}
        </div>
    );
};

export default ListaCollezioniPubbliche;
