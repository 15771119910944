/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TipoCardFilter } from './TipoCardFilter';
import {
    TipoCardFilterFromJSON,
    TipoCardFilterFromJSONTyped,
    TipoCardFilterToJSON,
} from './TipoCardFilter';
import type { UUIDFilter } from './UUIDFilter';
import {
    UUIDFilterFromJSON,
    UUIDFilterFromJSONTyped,
    UUIDFilterToJSON,
} from './UUIDFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';

/**
 * 
 * @export
 * @interface DragonballFusionWorldCriteria
 */
export interface DragonballFusionWorldCriteria {
    /**
     * 
     * @type {UUIDFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    id?: UUIDFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    artist?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    rarity?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    imageUrl?: StringFilter;
    /**
     * 
     * @type {TipoCardFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    tipoCard?: TipoCardFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    idCartaPokemon?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    sourceName?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    idCard?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    imageBack?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    tipologiaCarta?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    colore?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    comboPower?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    potenza?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    effetto?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    costo?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    costoSpecifico?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    nomeEspansione?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    features?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof DragonballFusionWorldCriteria
     */
    alternativeArt?: StringFilter;
}

/**
 * Check if a given object implements the DragonballFusionWorldCriteria interface.
 */
export function instanceOfDragonballFusionWorldCriteria(value: object): value is DragonballFusionWorldCriteria {
    return true;
}

export function DragonballFusionWorldCriteriaFromJSON(json: any): DragonballFusionWorldCriteria {
    return DragonballFusionWorldCriteriaFromJSONTyped(json, false);
}

export function DragonballFusionWorldCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DragonballFusionWorldCriteria {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : UUIDFilterFromJSON(json['id']),
        'name': json['name'] == null ? undefined : StringFilterFromJSON(json['name']),
        'artist': json['artist'] == null ? undefined : StringFilterFromJSON(json['artist']),
        'rarity': json['rarity'] == null ? undefined : StringFilterFromJSON(json['rarity']),
        'imageUrl': json['imageUrl'] == null ? undefined : StringFilterFromJSON(json['imageUrl']),
        'tipoCard': json['tipoCard'] == null ? undefined : TipoCardFilterFromJSON(json['tipoCard']),
        'idCartaPokemon': json['idCartaPokemon'] == null ? undefined : StringFilterFromJSON(json['idCartaPokemon']),
        'sourceName': json['sourceName'] == null ? undefined : StringFilterFromJSON(json['sourceName']),
        'idCard': json['idCard'] == null ? undefined : StringFilterFromJSON(json['idCard']),
        'imageBack': json['imageBack'] == null ? undefined : StringFilterFromJSON(json['imageBack']),
        'tipologiaCarta': json['tipologiaCarta'] == null ? undefined : StringFilterFromJSON(json['tipologiaCarta']),
        'colore': json['colore'] == null ? undefined : StringFilterFromJSON(json['colore']),
        'comboPower': json['comboPower'] == null ? undefined : StringFilterFromJSON(json['comboPower']),
        'potenza': json['potenza'] == null ? undefined : StringFilterFromJSON(json['potenza']),
        'effetto': json['effetto'] == null ? undefined : StringFilterFromJSON(json['effetto']),
        'costo': json['costo'] == null ? undefined : StringFilterFromJSON(json['costo']),
        'costoSpecifico': json['costoSpecifico'] == null ? undefined : StringFilterFromJSON(json['costoSpecifico']),
        'nomeEspansione': json['nomeEspansione'] == null ? undefined : StringFilterFromJSON(json['nomeEspansione']),
        'features': json['features'] == null ? undefined : StringFilterFromJSON(json['features']),
        'alternativeArt': json['alternativeArt'] == null ? undefined : StringFilterFromJSON(json['alternativeArt']),
    };
}

export function DragonballFusionWorldCriteriaToJSON(value?: DragonballFusionWorldCriteria | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': UUIDFilterToJSON(value['id']),
        'name': StringFilterToJSON(value['name']),
        'artist': StringFilterToJSON(value['artist']),
        'rarity': StringFilterToJSON(value['rarity']),
        'imageUrl': StringFilterToJSON(value['imageUrl']),
        'tipoCard': TipoCardFilterToJSON(value['tipoCard']),
        'idCartaPokemon': StringFilterToJSON(value['idCartaPokemon']),
        'sourceName': StringFilterToJSON(value['sourceName']),
        'idCard': StringFilterToJSON(value['idCard']),
        'imageBack': StringFilterToJSON(value['imageBack']),
        'tipologiaCarta': StringFilterToJSON(value['tipologiaCarta']),
        'colore': StringFilterToJSON(value['colore']),
        'comboPower': StringFilterToJSON(value['comboPower']),
        'potenza': StringFilterToJSON(value['potenza']),
        'effetto': StringFilterToJSON(value['effetto']),
        'costo': StringFilterToJSON(value['costo']),
        'costoSpecifico': StringFilterToJSON(value['costoSpecifico']),
        'nomeEspansione': StringFilterToJSON(value['nomeEspansione']),
        'features': StringFilterToJSON(value['features']),
        'alternativeArt': StringFilterToJSON(value['alternativeArt']),
    };
}

